import { configureStore } from '@reduxjs/toolkit';
import jobsReducer from '../features/jobs/jobsSlice';
import coverLetterReducer from '../features/coverletter/coverLetter'; // Import the coverLetter reducer

export const store = configureStore({
  reducer: {
    jobs_data_source: jobsReducer,
    coverLetter: coverLetterReducer, // Register the coverLetter reducer
  },
});
