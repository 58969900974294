import React, { useState,useRef } from "react";
import { BsRobot } from "react-icons/bs";
import { Input, Button, message } from "antd";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const inputRef = useRef(null); // Create a ref
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmitButton = () => {
    if (email === "") {
      message.error("Please fill the email id");
    }else if (!validateEmail(email)) {
      message.error("Please enter a valid email address");
    } else {
      axios
        .post("https://quotes-pi-sand.vercel.app/api/v1/addemail", {
          email: email,
        })
        .then(
          (response) => {
            console.log(response);
            message.success(
              `Thank you for subscribing! We will send updates to ${email}`
            );
            setEmail("");
          },
          (error) => {
            console.log(error);
            setEmail("");
          }
        );
    }
  };
  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <footer>
      <hr className="my-6 border-gray-200" />
      <div className="container mx-auto py-6 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
          <div className="mb-6 px-2 md:px-0 relative md:mr-20">
            {/* <p className="text-lg md:text-xl mb-1 ">
              Join our newsletter and receive the best job openings every week
              on your inbox.
            </p> */}
            <div className="mb-6 px-2 md:px-0 relative md:mr-20 mt-5 ">
              <input
                type="text"
                className="w-full md:h-12 h-12 md:pr-20 md:pl-10  md:py-8 shadow rounded  border border-white hover:border-[#153cf5] hover:border-1  md:text-2xl text-lg pl-3 font-semibold  hover:shadow transition-all duration-600 hover:-translate-y-.1 p-0.5"
                placeholder="Enter your email"
                onChange={handleChange}
                value={email}
                ref={inputRef}
              />

              <button
                className="absolute h-8 md:h-10 px-3 right-5 md:right-3 top-1/2 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-4  font-bold text-lg rounded"
                style={{
                  boxShadow:
                    "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
                }}
                onClick={onSubmitButton}
              >
                SUBSCRIBE
              </button>
            </div>
            <p className="text-lg md:text-xl mb-6 px-2 md:px-0">
            Join our Product Mindset newsletter community along with 25,000+ other awesome Product Managers!
            </p>
          </div>

          {/* <div className="flex items-center justify-center md:justify-start">
            <div>
              <h3 className="text-lg font-bold mb-4 text-[#153cf5]">Contact</h3>
              <ul className="space-y-2">
                <li>123 Main Street, City</li>
                <li>info@example.com</li>
                <li>(123) 456-7890</li>
              </ul>
            </div>
          </div> */}
          <div>
            <div className="col-span-2 md:col-span-1 text-center md:text-left">
              <h2 className="text-2xl font-bold text-[#153cf5]">
                Product Mindset
              </h2>
              <p className="text-black text-lg">
                At ProductMindset.io, we're committed to harnessing the latest AI technologies to empower Product Managers worldwide.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-4 mt-4 text-[#153cf5] text-center md:text-left">
                Follow Us
              </h3>
              <ul className="flex items-center  justify-center md:justify-start space-x-4">
                <li>
                  <a
                    href="https://www.facebook.com/productmindsets36/"
                    target="_blank"
                    className="text-gray-400 hover:text-white"
                  >
                    <img src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec9826823eec548b3182923_facebook.svg"></img>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Product_mindset"
                    target="_blank"
                    className="text-gray-400 hover:text-white"
                  >
                    <img src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec98269518a6e6118d567d1_twitter.svg"></img>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/product_mindset/"
                    target="_blank"
                    className="text-gray-400 hover:text-white"
                  >
                    <img src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec982692fa1e9d0c1a8db49_instagram.svg"></img>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/productmindset"
                    target="_blank"
                    className="text-gray-400 hover:text-white"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/1024px-LinkedIn_icon.svg.png"
                      className="
                  w-7 h-7 rounded"
                    ></img>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr className="my-6 border-gray-200" />
        <p className="text-center text-lg text-gray-600">
          © 2023 Product Mindset. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
