import React from "react";
import TextField from "../../../../helper/textField/textField";
import { Text, CustomGrid } from "../../../../helper/helper";
import FormButton from "./FormButton";
function SocilMedia({ form, setForm }) {
  const handleSocialMedia = (e, index) => {
    const newSocialMedia = [...form.socialMedia];
    newSocialMedia[index][e.target.name] = e.target.value.replace(
      "https://",
      ""
    );
    setForm({ ...form, socialMedia: newSocialMedia });
  };

  const addSocialMedia = () => {
    setForm({
      ...form,
      socialMedia: [...form.socialMedia, { socialMedia: "", link: "" }],
    });
  };

  const removeSocialMedia = (index) => {
    const newSocialMedia = [...form.socialMedia];
    newSocialMedia[index] = newSocialMedia[newSocialMedia.length - 1];
    newSocialMedia.pop();
    setForm({ ...form, socialMedia: newSocialMedia });
  };
  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">Social Media</Text>
      </div>
      <CustomGrid size={3}>
        {form.socialMedia.map((socialMedia, index) => (
          <CustomGrid size={1}>
            <TextField
              type="text"
              placeholder="Social Media"
              name="socialMedia"
              value={socialMedia.socialMedia}
              onChange={(e) => handleSocialMedia(e, index)}
            />
            <TextField
              type="text"
              placeholder="Link"
              name="link"
              value={socialMedia.link}
              onChange={(e) => handleSocialMedia(e, index)}
            />
          </CustomGrid>
        ))}
      </CustomGrid>
      {/* <FormButton
        size={form.socialMedia.length}
        add={addSocialMedia}
        remove={removeSocialMedia}
      /> */}
    </div>
  );
}

export default SocilMedia;
