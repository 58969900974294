import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";

import { ClerkProvider } from "@clerk/clerk-react";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;


root.render(
  <ClerkProvider publishableKey={clerkPubKey}>
    <React.StrictMode>
      <ThemeProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </ClerkProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
