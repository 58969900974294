import React, { useState, useEffect } from "react";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
// const steps = [
//   { id: "Step 1", name: "Basic Info", href: "#", status: "complete" },
//   { id: "Step 2", name: "Resume", href: "#", status: "complete" },
//   { id: "Step 3", name: "Preference", href: "#", status: "incomplete" },
//   { id: "Step 4", name: "Culture", href: "#", status: "incomplete" },
// ];

export default function FormSteps({ steps, setSteps }) {
  const { user } = useClerk();
  //   const [steps, setSteps] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://web-backend-user-profile-v2.vercel.app/api/v1/users/steps?username=${user.primaryEmailAddress.emailAddress}`
  //       );
  //       setSteps(response.data);
  //       // console.log("form data", response.data);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  return (
    <></>
    // <nav aria-label="Progress" className="mb-10 border p-2 ">
    //   <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
    //     {steps.map((step) => (
    //       <li key={step.name} className="md:flex-1">
    //         {step.status === "complete" ? (
    //           <a
    //             href={step.href}
    //             className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
    //           >
    //             <span className="text-sm font-medium text-green-600 group-hover:text-green-800">
    //               {step.id}
    //             </span>
    //             <span className="text-sm font-medium">{step.name}</span>
    //           </a>
    //         ) : step.status === "incomplete" ? (
    //           <a
    //             href={step.href}
    //             className="flex flex-col border-l-4 border-red-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
    //             aria-current="step"
    //           >
    //             <span className="text-sm font-medium text-red-600">
    //               {step.id}
    //             </span>
    //             <span className="text-sm font-medium">{step.name}</span>
    //           </a>
    //         ) : (
    //           <a
    //             href={step.href}
    //             className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
    //           >
    //             <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
    //               {step.id}
    //             </span>
    //             <span className="text-sm font-medium">{step.name}</span>
    //           </a>
    //         )}
    //       </li>
    //     ))}
    //   </ol>
    // </nav>
  );
}
