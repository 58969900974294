import React, { useState, useEffect } from "react";
import FileSelector from "./resume_component/FileSelector";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import { message } from "antd";
function Resume({ steps, setSteps }) {
  const { user } = useClerk();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [selectedFileFullName, setSelectedFileFullName] = useState("");

  // console.log("user",user.primaryEmailAddress.emailAddress)

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://web-backend-user-profile-v2.onrender.com/api/v1/users/resume?username=${user.primaryEmailAddress.emailAddress}`
      );

      if (response.data.pdfFile) {
      
        setSelectedFileFullName(response.data.pdfFile);
        setSelectedFile({
          name: user.username + "_resume",
        });
      }
      // console.log(response.data)
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const handleSubmitButton = () => {
    const requestData = new FormData();
    requestData.append("file", selectedFile);
    requestData.append("username", user.primaryEmailAddress.emailAddress);
    setIsLoadingSave(true);
    fetch(
      "https://web-backend-user-profile-v2.onrender.com/api/v1/saveUserResume",
      {
        method: "POST",
        body: requestData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        message.success("Resume uploaded successfully!");
        fetchData();
        setIsLoadingSave(false);
        const updatedStep = {
          id: "Step 2",
          name: "Resume",
          href: "#",
          status: "complete",
        };

        setSteps((prevSteps) => {
          // Find the index of the step with the same id as the updatedStep
          const index = prevSteps.findIndex((step) => step.id === updatedStep.id);
        
          // If the step with the same id is found, update it; otherwise, add the updatedStep as a new step
          if (index !== -1) {
            return prevSteps.map((step, idx) => (idx === index ? updatedStep : step));
          } else {
            return [...prevSteps, updatedStep];
          }
        });
      })
      .catch((error) => {
        message.error("Failed to upload resume.");
        setIsLoadingSave(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="border-b border-gray-900/10 pb-5" key="resume">
      <div className="space-y-12 ">
        <div className="grid grid-cols-1 gap-x-8 gap-y-5 md:grid-cols-3">
          <div className="mt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Upload your recent resume or CV <span className="text-red-500">*</span>
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Upload your most up-to-date resume File types: PDF
            </p>
          </div>
          <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-6">
              <div class="flex items-center justify-center w-full">
                <FileSelector
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                ></FileSelector>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 ">
          {selectedFileFullName !== "" && (
            <a href={selectedFileFullName} target="_blank"  rel="noreferrer">
              <button
                type="submit"
                className="rounded-md bg-white border border-indigo-600 px-6 py-2 text-sm font-semibold text-indigo-600 hover:bg-white-600 hover:text-text-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                // onClick={handleSubmitButton}
              >
                {/* {isLoadingSave ? "Saving..." : "Save"} */}
                View
              </button>
            </a>
          )}

          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmitButton}
          >
            {isLoadingSave ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Resume;
