import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Spin, Result, Button } from "antd";
import NavBar from "../../navBar/containers/NavBar";
import ViewJobCard from "./ViewJobCard";

import { Typography } from "@material-tailwind/react";
function ViewJobDetails() {
  const [loading, setLoading] = useState(true);
  const [jobContent, setJobContent] = useState(null);
  const { company_name, job_id } = useParams();
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://jobs-backend-web-v2.vercel.app/api/v1/getJobDetails`;
        const response = await axios.get(url, {
          params: {
            company_name,
            job_id,
          },
        });

        if (response.status === 200) {
          setJobContent(response.data);
        } else {
          console.error("Error fetching job details:", response.data);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [company_name, job_id]);

  const handleBack = () => {
    history.push("/jobs");
  };

  return (
    <div className="bg-[#f7f7fb]">
      <div className="mx-auto lg:max-w-7xl ">
        {loading ? (
          <div className="flex justify-center">
          <NavBar></NavBar>
            <Spin size="large" style={{ marginTop: "25%" }} />
          </div>
        ) : jobContent ? (
          <div>
            <NavBar></NavBar>
            <div className="mt-20 w-full">
              <ViewJobCard jobContent={jobContent}></ViewJobCard>
            </div>
          </div>
        ) : (
          <Result
            style={{ marginTop: "10%" }}
            status="404"
            title="Page Not Found"
            subTitle="Sorry, the requested job page does not exist."
            extra={
              <Button type="primary" href="/jobs">
                Back to Home
              </Button>
            }
          />
        )}
      </div>
    </div>
  );
}

export default ViewJobDetails;
