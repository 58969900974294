import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useClerk } from "@clerk/clerk-react";
import { Spin } from "antd";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedJobs } from "../../../../features/jobs/jobsSlice";


function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}


export default function JobsModal({ open, setOpen, jobId, companyName }) {
  const dispatch = useDispatch();
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const [applyLoading, setApplyLoading] = useState(false);

  const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
  const appliedJobs = useSelector(
    (state) => state.jobs_data_source.appliedJobs
  );
  const { user } = useClerk();

  useEffect(() => {
    const fetchData = async () => {
      var company_name = companyName;
      var job_id = jobId;
      try {
        const url = `https://jobs-backend-web-v2.vercel.app/api/v1/getJobDetails`;
        const response = await axios.get(url, {
          params: {
            company_name,
            job_id,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        });

        if (response.status === 200) {
          setJobDetails(response.data);
        } else {
          console.error("Error fetching job details:", response.data);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyName, jobId]);

  // get bookmarked jobs
  useEffect(() => {
    axios
      .get(
        `https://users-backend-web-v2.vercel.app/api/v1/getBookMarkJob?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setBookmarkedJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //bookmark the job
  const handleBookmark = (jobId) => {
    const requestData = {
      username: user.primaryEmailAddress.emailAddress,
      jobid: jobId,
    };

    axios
      .post(
        "https://users-backend-web-v2.vercel.app/api/v1/bookMarkJobs",
        requestData
      )
      .then((response) => {
        if (!bookmarkedJobs.includes(jobId)) {
          message.success(`job bookmarked successfully!`);
        } else {
          message.success(`job unbookmarked successfully!`);
        }
        const updatedBookmarkedJobs = bookmarkedJobs.includes(jobId) // Use "jobId" instead of "job_id"
          ? bookmarkedJobs.filter((id) => id !== jobId)
          : [...bookmarkedJobs, jobId];
        setBookmarkedJobs(updatedBookmarkedJobs);
      })
      .catch((error) => {
        message.error(`Something went wrong while saving the job`);
      });
  };

  //apply to a job
  const handleApplyJobs = (jobId) => {
    setApplyLoading(true);
    const requestData = {
      username: user.primaryEmailAddress.emailAddress,
      jobid: jobId,
    };

    axios
      .post(
        "https://users-backend-web-v2.vercel.app/api/v1/applyJob",
        requestData
      )
      .then((response) => {
        message.success(`moved for final step....!`);
        const updatedAppliedJobs = [...appliedJobs, jobId];
        dispatch(setAppliedJobs(updatedAppliedJobs));
        setApplyLoading(false);
        window.open(jobDetails?.job_url, "_blank");
      })
      .catch((error) => {
        message.error(`Something went wrong while applying to job`);
        setApplyLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(
        `https://users-backend-web-v2.vercel.app/api/v1/getAppliedJobs?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setAppliedJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 mt-5">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-7/10 max-w-4xl mt-10">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-2 sm:px-6 border-b">
                      <div className="flex items-start justify-between ">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Job Details
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <div
                        className="flex items-center justify-center h-full"
                        style={{ width: "1000px" }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6 mt-10">
                          <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-12">
                            <div>
                              <div className="-m-1 flex">
                                <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                  <img
                                    className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48 object-contain p-2"
                                    src={jobDetails.company_logo}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-lg font-bold text-gray-900 sm:text-2xl">
                                    {jobDetails.job_title}
                                  </h3>
                                  <span className="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                                    <span className="sr-only">Online</span>
                                  </span>
                                </div>
                                <p className="text-sm text-gray-500">
                                  {jobDetails.label}
                                </p>
                              </div>
                              <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                                <button
                                  type="button"
                                  className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                                  onClick={() => handleApplyJobs(jobId)}
                                >
                                  {applyLoading
                                    ? "Redirecting..."
                                    : appliedJobs.includes(jobId)
                                    ? "Already Applied"
                                    : "Apply"}
                                </button>

                                <button
                                  type="button"
                                  className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                  onClick={() => handleBookmark(jobId)}
                                >
                                  {bookmarkedJobs.includes(jobId)
                                    ? "✅ Saved"
                                    : "Save"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Location
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {jobDetails.job_location}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Website
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {jobDetails.company_webpage_link}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Job Posted Date
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              {formatDate(jobDetails.job_posted_date)}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Job Description
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: jobDetails.job_description_html,
                                  }}
                                ></div>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
