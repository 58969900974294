import React, { useEffect } from "react";
import { useClerk } from "@clerk/clerk-react";
import Routes from "./routes/routes";
import GoToTopButton from "./GoToTopButton";

function App() {
  const { user } = useClerk();
  useEffect(() => {
    // Fetch the data when the component mounts
    async function fetchData() {
      try {
        const response = await fetch(
          "https://user-jobs.onrender.com/api/v1/getUserJobs"
        );
        if (response.ok) {
          const data = await response.json();
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch the data when the component mounts
    async function fetchData() {
      try {
        const response = await fetch(
          `https://web-backend-user-profile-v2.onrender.com/api/v1/users/steps?username=${user.primaryEmailAddress.emailAddress}`
        );
        if (response.ok) {
          const data = await response.json();
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Routes />
      <GoToTopButton></GoToTopButton>
    </div>
  );
}

export default App;
