import React from "react";
import { useSelector } from "react-redux";
const Template1 = () => {
  const coverLetterData = useSelector(
    (state) => state.coverLetter.coverLetterData
  );
  return (
    <p className="content" style={{ whiteSpace: "pre-line" }}>
      {coverLetterData.closingParagraph}
    </p>
  );
};

export default Template1;
