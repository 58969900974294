import { DragDropContext } from "react-beautiful-dnd";
import WorkExperience from "../../resume_components/WorkExperience";
import BasicInfo from "../../resume_components/BasicInfo";
import Education from "../../resume_components/Education";
import Summary from "../../resume_components/Summary";
import Skills from "../../resume_components/Skills";
import Language from "../../resume_components/Language";
import Certification from "../../resume_components/Certification";
import Project from "../../resume_components/Project";

const Template1 = ({ form, setForm }) => {
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    if (source.droppableId === "work-experience") {
      const newWorkExperience = [...form.workExperience];
      const [removed] = newWorkExperience.splice(source.index, 1);
      newWorkExperience.splice(destination.index, 0, removed);
      setForm({ ...form, workExperience: newWorkExperience });
    }

    if (source.droppableId.includes("WORK_EXPERIENCE_KEY_ACHIEVEMENT")) {
      const newWorkExperience = [...form.workExperience];
      const workExperienceIndex = parseInt(source.droppableId.split("-")[1]);
      const keyAchievements =
        newWorkExperience[workExperienceIndex].keyAchievements.split("\n");
      const [removed] = keyAchievements.splice(source.index, 1);
      keyAchievements.splice(destination.index, 0, removed);
      newWorkExperience[workExperienceIndex].keyAchievements =
        keyAchievements.join("\n");
      setForm({ ...form, workExperience: newWorkExperience });
    }

    if (source.droppableId === "skills") {
      const newSkills = [...form.skills];
      const [removed] = newSkills.splice(source.index, 1);
      newSkills.splice(destination.index, 0, removed);
      setForm({ ...form, skills: newSkills });
    }

    if (source.droppableId.includes("projects")) {
      const newProjects = [...form.projects];
      const [removed] = newProjects.splice(source.index, 1);
      newProjects.splice(destination.index, 0, removed);
      setForm({ ...form, projects: newProjects });
    }

    if (source.droppableId.includes("PROJECTS_KEY_ACHIEVEMENT")) {
      const newProjects = [...form.projects];
      const projectIndex = parseInt(source.droppableId.split("-")[1]);
      const keyAchievements =
        newProjects[projectIndex].keyAchievements.split("\n");
      const [removed] = keyAchievements.splice(source.index, 1);
      keyAchievements.splice(destination.index, 0, removed);
      newProjects[projectIndex].keyAchievements = keyAchievements.join("\n");
      setForm({ ...form, projects: newProjects });
    }
  };
  return (
    <div className="sticky top-0 p-3">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="w-210">
          <BasicInfo form={form} />
          <hr className="border-dashed my-5" />

          <div className="grid md:grid-cols-3 gap-6 pb-10">
            <div className="md:col-span-1 space-y-2">
              <Summary form={form}></Summary>
              <Education form={form}></Education>
              {Object.keys(form).length && form.skills.map((skill, index) => (
                <div key={`SKILLS-${index}`} className="mb-1">
                  <Skills title={skill.title} skills={skill.skills} />
                </div>
              ))}
              <Language title="Languages" languages={form.languages} />
              <Certification
                title="Certifications"
                certifications={form.certifications}
              />
            </div>
            <div className="md:col-span-2 space-y-2">
              <WorkExperience form={form}></WorkExperience>
              <Project form={form}></Project>
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

export default Template1;
