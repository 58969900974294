import React from "react";

const categoryImages = {
  HIRING:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff60851a742b367cf49_Bag.svg",
  MINDSET:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff7ef9cdce701c52b9cc_SketchLogo.svg",
  "MENTAL MODEL":
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604d22491a63f0fc18512cfb_SlackLogo.svg",
  ENGINEERING:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff815b1b307f51df8a76_Swatches.svg",
  INTERVIEW:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff8260c2c6ceeafb87b_Buildings.svg",
  "PRODUCT MANAGEMENT":
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff76813f9481e3583f9e_Lightbulb.svg",
  BOOKS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff64cfde0a9ae36aef1_Book.svg",
  BLOGS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604d224a16ac69748dc9c51e_TwitchLogo.svg",
  NEWSLETTER:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff7c4cfde04b5136aebe_RocketLaunch.svg",
  "GOOD READS":
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff74cfde0351336aef2_Bookmark.svg",
  FRAMEWORK:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff77249985616a11e289_MapTrifold.svg",
  TOOLS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff7ad52be02d264c54fc_PencilCircle.svg",
  COURSE:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff6a9921cdfc6b4fa3c_ArchiveBox.svg",
  GUIDE:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff8772b9ab389c53081a_WifiMedium.svg",
  DESIGN:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff860c81322901384cc7_Watch.svg",
  "FAILED STORIES":
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff864ab0c81c22e581c4_WarningOctagon.svg",
  TEMPLATE:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff815b1b307f51df8a76_Swatches.svg",
  DEFAULT: "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff60851a742b367cf49_Bag.svg"
};

function Card({ document }) {
  return (
    <div className="transform transition-transform duration-200 hover:-translate-y-1 hover:shadow-lg">
      <a
        href={document.URL}
        className="card template w-inline-block flex flex-col items-start shadow-md"
        target="_blank"
        onClick={() => {
          console.log("click");
        }}
      >
        <div className="flex items-center justify-center w-full h-40 bg-blue-100">
          <img
            src={categoryImages[document.CATEGORY] || categoryImages.DEFAULT}
            alt="Morning Brainstorming"
            className="card-icon"
            width="57"
          />
        </div>
        <div className="p-6">
          <div className="card-tag px-2 py-1 rounded-2xl mb-2 text-sm font-semibold  inline-block truncate">
            {document.CATEGORY}
          </div>

          <h4 className="text-xl font-semibold mb-2 line-clamp-1 overflow-hidden">
            {document.TITLE}
          </h4>
          <p className="paragraph s no-margin text-gray-700 line-clamp-2 overflow-hidden">
            {document.DESCRIPTION}
          </p>
        </div>
      </a>
    </div>
  );
}

export default Card;
