import React, { useState } from "react";
import { Radio } from "antd";
function Next_Five_Years({ formFields, setFormFields }) {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Over the next five years, what career track do you want to follow?
          </h2>
        </div>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-full">
            <div className="mt-2">
              <Radio.Group
                onChange={(e) => {
                  const { value } = e.target;
                  setFormFields({
                    ...formFields,
                    next_five_years: value,
                  });
                }}
                value={formFields.next_five_years}
              >
                <Radio value="Individual contributor">
                  Individual contributor
                </Radio>
                <Radio value="Manager">Manager</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Next_Five_Years;
