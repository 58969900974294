import { useState, useEffect } from "react";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import Main_Component from "./preference_component/Main_Component";
import { message } from "antd";
function Preference({ steps, setSteps }) {
  const { user } = useClerk();
  const [formFields, setFormFields] = useState({});
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const handleSubmitButton = async () => {
    const mandatoryFields = [
      "where_are_you_in_job_search",
      "require_sponsorship_for_us",
      "legally_authorized_to_work_in_us",
      "job_type_interested",
      "locations_interested",
    ];
    const missingFields = mandatoryFields.filter((field) => !formFields[field]);

    if (missingFields.length > 0) {
      const errorMessage = `Please fill the required fields`;
      message.error(errorMessage);
      return;
    }
    try {
      setIsLoadingSave(true);
      const response = await axios.post(
        "https://web-backend-user-profile-v2.onrender.com/api/v1/saveUserPreference",
        {
          username: user.primaryEmailAddress.emailAddress,
          where_are_you_in_job_search: formFields.where_are_you_in_job_search,
          require_sponsorship_for_us: formFields.require_sponsorship_for_us,
          legally_authorized_to_work_in_us:
            formFields.legally_authorized_to_work_in_us,
          job_type_interested: formFields.job_type_interested,
          locations_interested: formFields.locations_interested,
          open_to_work_remotely: formFields.open_to_work_remotely,
          remote_work_preference: formFields.remote_work_preference,
          work_at_companies_of_these_sizes: selectedMethods,
        }
      );

      setIsLoadingSave(false);
      message.success(`${user.username} preference info saved successfully!`);
      const updatedStep = {
        id: "Step 3",
        name: "Preference",
        href: "#",
        status: "complete",
      };

      setSteps((prevSteps) => {
        // Find the index of the step with the same id as the updatedStep
        const index = prevSteps.findIndex((step) => step.id === updatedStep.id);
      
        // If the step with the same id is found, update it; otherwise, add the updatedStep as a new step
        if (index !== -1) {
          return prevSteps.map((step, idx) => (idx === index ? updatedStep : step));
        } else {
          return [...prevSteps, updatedStep];
        }
      });
    } catch (error) {
      setIsLoadingSave(false);
      message.error("Failed to save preference info.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://web-backend-user-profile-v2.onrender.com/api/v1/users/preference?username=${user.primaryEmailAddress.emailAddress}`
        );
        setFormFields(response.data);
        setSelectedMethods(response.data.work_at_companies_of_these_sizes);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <Main_Component
        setFormFields={setFormFields}
        formFields={formFields}
        selectedMethods={selectedMethods}
        setSelectedMethods={setSelectedMethods}
      ></Main_Component>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmitButton}
        >
          {isLoadingSave ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default Preference;
