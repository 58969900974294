import React, { useState } from "react";
import RenderIf from "../../../../utilities/RenderIf";

import FormSteps from "../forms/FormSteps";
import ProfileTabs from "./ProfileTabs";
import BasicInfo from "../forms/BasicInfo";
import Resume from "../forms/Resume";
import Preference from "../forms/Preference";
import Culture from "../forms/Culture";
import CoverLetter from "../forms/CoverLetter";
import Overview from "../forms/Overview";
import {
  StarIcon,
  LifebuoyIcon,
  InformationCircleIcon,
  FolderArrowDownIcon,
  GlobeAsiaAustraliaIcon,
} from "@heroicons/react/20/solid";

const tabs = [
  { name: "Overview", href: "/user/profile", icon: LifebuoyIcon },
  { name: "BasicInfo", href: "/user/profile", icon: InformationCircleIcon },
  { name: "Resume", href: "/user/profile", icon: FolderArrowDownIcon },
  { name: "Preference", href: "/user/profile", icon: StarIcon },
  { name: "Culture", href: "/user/profile", icon: GlobeAsiaAustraliaIcon },
];
function Profile() {
  const [currentTab, setCurrentTab] = useState("Overview");
  const [steps, setSteps] = useState([]);
  return (
    <div>
      <FormSteps steps={steps} setSteps={setSteps}></FormSteps>
      <ProfileTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabs={tabs}
      ></ProfileTabs>
      <RenderIf condition={currentTab === "Overview"}>
        <Overview></Overview>
      </RenderIf>
      <RenderIf condition={currentTab === "BasicInfo"}>
        <BasicInfo steps={steps} setSteps={setSteps}></BasicInfo>
      </RenderIf>
      <RenderIf condition={currentTab === "Resume"}>
        <div className="bg-white p-2 md:p-5">
          <Resume steps={steps} setSteps={setSteps}></Resume>
          <CoverLetter></CoverLetter>
        </div>
      </RenderIf>
      <RenderIf condition={currentTab === "Preference"}>
        <Preference steps={steps} setSteps={setSteps}></Preference>
      </RenderIf>
      <RenderIf condition={currentTab === "Culture"}>
        <Culture steps={steps} setSteps={setSteps}></Culture>
      </RenderIf>
    </div>
  );
}

export default Profile;
