import React from "react";
import CustomNavbar from "../components/CustomNavbar";

function NavBar() {
  return (
    <div>
      <CustomNavbar></CustomNavbar>
    </div>
  );
}

export default NavBar;
