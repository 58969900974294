import { useState, useEffect } from "react";
import { Pagination, Spin } from "antd";
import { useSelector } from "react-redux";

import { useClerk } from "@clerk/clerk-react";
import JobsModal from "../../DashBoard/modal/JobsModal";
import axios from "axios";
import { message } from "antd";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export default function JobCard({ loading, userJobList }) {
  const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
  const { user } = useClerk();

  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  // const userJobList = useSelector(
  //   (state) => state.jobs_data_source.filterUserJobs
  // );
  const appliedJobs = useSelector(
    (state) => state.jobs_data_source.appliedJobs
  );
  const totalJobs = userJobList.length; // Total number of jobs

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [open, setOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  const handleModalOpen = (job) => {
    setJobId(job?.job_id);
    setCompanyName(job.company_name);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleBookmark = (jobId) => {
    const requestData = {
      username: user.primaryEmailAddress.emailAddress,
      jobid: jobId,
    };

    axios
      .post(
        "https://users-backend-web-v2.vercel.app/api/v1/bookMarkJobs",
        requestData
      )
      .then((response) => {
        if (!bookmarkedJobs.includes(jobId)) {
          message.success(`job bookmarked successfully!`);
        } else {
          message.success(`job unbookmarked successfully!`);
        }
        const updatedBookmarkedJobs = bookmarkedJobs.includes(jobId) // Use "jobId" instead of "job_id"
          ? bookmarkedJobs.filter((id) => id !== jobId)
          : [...bookmarkedJobs, jobId];
        setBookmarkedJobs(updatedBookmarkedJobs);
      })
      .catch((error) => {
        message.error(`Something went wrong while saving the job`);
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(
        `https://users-backend-web-v2.vercel.app/api/v1/getBookMarkJob?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setBookmarkedJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const renderCards = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const visibleJobs = userJobList.slice(startIndex, endIndex);

    return visibleJobs.map((job, index) => (
      <div
        className="bg-white shadow hover:border-[#153cf5] transition-all duration-600 border-gray-600  hover:-translate-y-1 sm:rounded-lg mt-3"
        key={index}
        style={{
          boxShadow:
            "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
        }}
      >
        <div className="px-4 py-5  sm:p-6">
          <div className="flex justify-between ">
            <div className="md:flex items-start gap-x-3">
              <p className="text-xl font-semibold leading-6 text-gray-900 ">
                {job.job_title}
              </p>

              <span className="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-500/20 font-semibold capitalize mx-1 my-3 md:my-0 md:mx-0">
                {job.job_status}
              </span>
              {(job.job_url.includes("lever") ||
                job.job_url.includes("greenhouse")) && (
                <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                  Easy job
                </span>
              )}

              {job.visa_sponsored ? (
                <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-800 ring-1 ring-inset ring-blue-400/30 font-semibold mx-1 my-3 md:my-0 md:mx-0">
                  Visa Sponsored
                </span>
              ):""}

              {appliedJobs.includes(job?.job_id) && (
                <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 font-semibold mx-1 my-3 md:my-0 md:mx-0">
                  Applied
                </span>
              )}
            </div>
            <div
              onClick={() => handleBookmark(job?.job_id)}
              className="mt-1 md:mt-0"
            >
              {bookmarkedJobs.includes(job?.job_id) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#763ff9"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#763ff9"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                  />
                </svg>
              )}
            </div>
          </div>
          <div className="mt-5 bg-gray-50 pb-3">
            <div className="rounded-md  px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <div className="sm:flex sm:items-start">
                <img
                  src={job.company_logo}
                  className="w-14 h-14 object-contain"
                  alt={job.company_name}
                ></img>
                <div className="mt-3 sm:ml-4 sm:mt-0">
                  <div className="text-lg font-medium text-gray-900 capitalize">
                    {job.company_name}
                  </div>
                  <div className="mt-1 text-md text-gray-600 sm:flex sm:items-center">
                    <div>Full Time</div>
                    <span
                      className="hidden sm:mx-2 sm:inline"
                      aria-hidden="true"
                    >
                      &middot;
                    </span>
                    <div className="mt-1 sm:mt-0">{job.job_location}</div>
                    <span
                      className="hidden sm:mx-2 sm:inline"
                      aria-hidden="true"
                    >
                      &middot;
                    </span>
                    <div className="mt-1 sm:mt-0">
                      {formatDate(job.job_posted_date)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                <button
                  type="button"
                  className="rounded-md bg-[#153cf5] px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => handleModalOpen(job)}
                >
                  View
                </button>
              </div>
            </div>
            <div className="mt-1 rounded-md  px-6 sm:flex sm:items-start sm:justify-between text-md text-gray-900 sm:flex sm:items-center">
              <h4>{job.job_summary}</h4>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      {open && (
        <JobsModal
          jobId={jobId}
          companyName={companyName}
          open={open}
          setOpen={setOpen}
          onClose={handleModalClose}
        />
      )}
      {loading ? (
        <div className="flex justify-center items-center mt-20">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="mt-5">{renderCards()}</div>
          <div className="mt-4 flex justify-center">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalJobs}
              onChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
}
