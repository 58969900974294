import React from "react";
import TextField from "../../../../helper/textField/textField";
import { Text, CustomGrid } from "../../../../helper/helper";
import FormButton from "./FormButton";
function Certification({ form, setForm }) {
  const skillType = "certifications";
  const title = "Certifications";

  const handleSkills = (e, index, skillType) => {
    const newSkills = [...form[skillType]];
    newSkills[index] = e.target.value;
    setForm({ ...form, [skillType]: newSkills });
  };

  const addSkill = () => {
    setForm({ ...form, [skillType]: [...form[skillType], ""] });
  };

  const removeSkill = (index) => {
    const newSkills = [...form[skillType]];
    newSkills.splice(-1, 1);
    setForm({ ...form, [skillType]: newSkills });
  };
  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">Certification</Text>
      </div>
      <CustomGrid size={3}>
        {form[skillType].map((skill, index) => (
          <>
            <TextField
              type="text"
              placeholder={title}
              name={title}
              className="w-full other-input"
              value={skill}
              onChange={(e) => handleSkills(e, index, skillType)}
            />
          </>
        ))}
      </CustomGrid>
      <FormButton
        size={form[skillType].length}
        add={addSkill}
        remove={removeSkill}
      />
    </div>
  );
}

export default Certification;
