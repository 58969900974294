import { useState, useEffect } from "react";
import { InformationCircleIcon, BookmarkIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useClerk } from "@clerk/clerk-react";
import ContentTypeTab from "./ContentTypeTab";
import JobCard from "../jobspage/JobCard";
import { Spin } from "antd";

const tabs = [
  { name: "Saved Jobs", href: "/user/report", icon: BookmarkIcon },
  { name: "Applied Jobs", href: "/user/report", icon: InformationCircleIcon },
];

function MainComponent() {
  const { user } = useClerk();
  const [currentTabContent, setCurrentTabContent] = useState("Saved Jobs");
  const [loading, setLoading] = useState(true);
  const [userJobs, setUserJobs] = useState([]);
  const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    axios
      .get(`https://user-jobs.onrender.com/api/v1/getUserJobs`)
      .then((response) => {
        setLoading(false);
        setUserJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  // get bookmarked jobs
  useEffect(() => {
    axios
      .get(
        `https://users-backend-web-v2.vercel.app/api/v1/getBookMarkJob?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setBookmarkedJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Filter userJobs to include only jobs with IDs present in bookmarkedJobs
  const filteredUserJobs = userJobs.filter((job) =>
    bookmarkedJobs.includes(job.job_id)
  );

  useEffect(() => {
    axios
      .get(
        `https://users-backend-web-v2.vercel.app/api/v1/getAppliedJobs?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setAppliedJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filteredUserAppliedJobs = userJobs.filter((job) =>
    appliedJobs.includes(job.job_id)
  );



  return (
    <div>
      <ContentTypeTab
        currentTab={currentTabContent}
        setCurrentTab={setCurrentTabContent}
        tabs={tabs}
      ></ContentTypeTab>
      {loading ? (
        // Show loading spinner while data is loading
        <div className="text-center mt-8 ">
          <Spin size="large" />
        </div>
      ) : (
        // Once data is loaded, display JobCard with filteredUserJobs
        <JobCard
          filterJobs={
            currentTabContent === "Saved Jobs"
              ? filteredUserJobs
              : filteredUserAppliedJobs
          }
        ></JobCard>
      )}
    </div>
  );
}

export default MainComponent;
