import React from "react";
import TextField from "../../../../helper/textField/textField";
import { Text, CustomGrid } from "../../../../helper/helper";
import { Divider } from "antd";
import FormButton from "./FormButton";
function Education({ form, setForm }) {
  const handleEducation = (e, index) => {
    const newEducation = [...form.education];
    newEducation[index][e.target.name] = e.target.value;
    setForm({ ...form, education: newEducation });
  };

  const addEducation = () => {
    setForm({
      ...form,
      education: [
        ...form.education,
        { school: "", degree: "", startYear: "", endYear: "" },
      ],
    });
  };

  const removeEducation = (index) => {
    const newEducation = [...form.education];
    newEducation[index] = newEducation[newEducation.length - 1];
    newEducation.pop();
    setForm({ ...form, education: newEducation });
  };
  return (
    <>
      {form.education.length && (
        <div
          style={{
            background: "white",
            padding: "2%",
            boxShadow:
              "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
          }}
          className="border"
        >
          <div style={{ marginBottom: "2%" }}>
            <Text type="sm">Education</Text>
          </div>
          <CustomGrid size={1}>
            {form.education.map((education, index) => (
              <>
                <CustomGrid size={3}>
                  <TextField
                    type="text"
                    placeholder="School"
                    name="school"
                    value={education.school}
                    onChange={(e) => handleEducation(e, index)}
                  />
                  <TextField
                    type="text"
                    placeholder="Degree"
                    name="degree"
                    value={education.degree}
                    onChange={(e) => handleEducation(e, index)}
                  />
                  <CustomGrid size={2}>
                    <TextField
                      type="date"
                      placeholder="Start Year"
                      name="startYear"
                      value={education.startYear}
                      onChange={(e) => handleEducation(e, index)}
                    />
                    <TextField
                      type="date"
                      placeholder="End Year"
                      name="endYear"
                      value={education.endYear}
                      onChange={(e) => handleEducation(e, index)}
                    />
                  </CustomGrid>
                </CustomGrid>
                {form.education.length > 1 &&
                  index !== form.education.length - 1 && (
                    <Divider className="border-black border-t-1" dashed />
                  )}
              </>
            ))}
          </CustomGrid>

          <FormButton
            size={form.education.length}
            add={addEducation}
            remove={removeEducation}
          />
        </div>
      )}
    </>
  );
}

export default Education;
