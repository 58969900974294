import React, { useState } from "react";
import CustomTabs from "../jobposttabs/CustomTabs";
import LeftSideForm from "./LeftSideForm";
import RightSideForm from "./RightSideForm";

const tabs = [
  { name: "Free job", content: "" },
  { name: "Paid job", content: "" },
];

function PostJobMain() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="mx-auto  max-w-7xl px-6 mt-20 lg:px-8">
      <div className="mx-auto max-w-4xl text-center animate-fade-in">
        <p className="text-4xl font-semibold tracking-tight sm:text-5xl">
          <span className="text-[#153cf5] underline">Post Your</span>  Job
          Opening
        </p>
      </div>
      <p className="mx-auto mt-10 max-w-2xl text-center text-lg leading-8 text-gray-600 animate-fade-in">
        Ready to find the perfect Product Manager for your team? Fill out the
        details of your job opening below, and we'll help you connect with top
        talent in the field.
      </p>
      <div className="mt-8">
        <CustomTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        ></CustomTabs>
      </div>
      <div className="md:flex pb-10">
        {/* Left side */}
        <div className="w-full md:w-2/5 bg-white ">
          <LeftSideForm></LeftSideForm>
        </div>
        {/* Right side */}
        <div className="w-full md:w-3/5 bg-white">
          <RightSideForm selectedTab={activeTab.name}></RightSideForm>
        </div>
      </div>
    </div>
  );
}

export default PostJobMain;
