import React from "react";

import NavBar from "../../navBar/containers/NavBar";
import HeroSection from "../components/HeroSection";
import Feature1 from "../components/Feature1";
import Feature2 from "../components/Feature2";
import Feature3 from "../components/Feature3";

import Footer from "../components/Footer";
function Home() {
  return (
    <div className="bg-[#fafbff]">
      <NavBar></NavBar>
      <HeroSection></HeroSection>
      <Feature1></Feature1>
      <Feature2></Feature2>
      <Feature3></Feature3>
      <Footer></Footer>
    </div>
  );
}

export default Home;
