import React from "react";
import MainDashBoard from "../components/MainDashBoard";
function MainDashBordContainer() {
  return (
    <div>
      <MainDashBoard></MainDashBoard>
    </div>
  );
}

export default MainDashBordContainer;
