import { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const ExperienceSelect = ({ setFormFields, formFields }) => {
  const [selectedExperience, setSelectedExperience] = useState("");

  const experienceOptions = [
    { value: "0-2", label: "0-2" },
    { value: "2-5", label: "2-5" },
    { value: "5-10", label: "5-10" },
    { value: "10+", label: "10+" },
  ];

  return (
    <Select
      id="experience"
      name="experience"
      className="w-full"
      placeholder="Select Experience"
      value={formFields.experience}
      onChange={(value) => {
        setSelectedExperience(value);
        setFormFields({
          ...formFields,
          experience: value,
        });
      }}
    >
      <Option value="" disabled>
        Select Experience
      </Option>
      {experienceOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default ExperienceSelect;
