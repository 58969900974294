import { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Spin } from 'antd';

const { Option } = Select;

const CitySelect = ({ country, state, onSelectCity,setFormFields,
  formFields }) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        setLoading(true);
        setCities([])
        const response = await axios.post(
          'https://countriesnow.space/api/v0.1/countries/state/cities',
          {
            country,
            state,
          }
        );
        const { data } = response.data;
        setCities(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cities:', error);
        setLoading(false);
      }
    };

    if (country && state) {
      fetchCities();
    }
  }, [country, state]);

  

  return (
    <Select
      id="city"
      name="city"
      className="w-full"
      value={formFields.city}
      
      loading={loading}
      showSearch
      placeholder="Select City"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }

      onChange={(value) => {
        setSelectedCity(value);
        setFormFields({
          ...formFields,
          city: value,
        });
      }}
    >
      <Option value="" disabled>
        Select City
      </Option>
      {cities.map((city) => (
        <Option key={city} value={city}>
          {city}
        </Option>
      ))}
    </Select>
  );
};

export default CitySelect;
