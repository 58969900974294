import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
function FreeJobSection() {
  return (
    <div className="bg-white mb-10">
      <main>
        {/* Pricing section */}
        <div className="mx-auto  max-w-7xl px-6 mt-20 lg:px-8 animate-fade-in">
          <div className="mx-auto max-w-4xl text-center">
            <p className="text-2xl font-semibold tracking-tight ">
              Want to try out our Job board first?
            </p>
            <p className="text-2xl font-semibold tracking-tight py-2">
              Post a free job today
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Ready to take your product management team to the next level? Post
            your job listing now and connect with top-tier talent who will help
            you drive innovation and success in your organization. Your next
            great hire is just a click away! 🔥💼🌟
          </p>

          <li className="flex flex-col md:flex-row gap-y-2 md:gap-x-3 mx-auto mt-10 max-w-4xl text-center text-md leading-8 text-gray-600 justify-center items-center">
            <div className="w-7 h-7 md:w-7 h-7 flex items-center justify-center rounded-full bg-gray-200">
              <CheckIcon
                className="h-5 w-5 text-gray-600 font-semibold"
                aria-hidden="true"
              />
            </div>
            <span className="font-semibold">5-10 jobs/month</span>
            <div className="w-7 h-7 md:w-7 h-7 flex items-center justify-center rounded-full bg-gray-200">
              <CheckIcon
                className="h-5 w-5 text-gray-600 font-semibold"
                aria-hidden="true"
              />
            </div>
            <span className="font-semibold">Published in 7 days</span>
            <div className="w-7 h-7 md:w-7 h-7 flex items-center justify-center rounded-full bg-gray-200">
              <CheckIcon
                className="h-5 w-5 text-gray-600 font-semibold"
                aria-hidden="true"
              />
            </div>
            <span className="font-semibold">Slow approval</span>
            <div className="w-7 h-7 md:w-7 h-7 flex items-center justify-center rounded-full bg-gray-200">
              <CheckIcon
                className="h-5 w-5 text-gray-600 font-semibold"
                aria-hidden="true"
              />
            </div>
            <span className="font-semibold">Company verification required</span>
          </li>

          <div class="flex justify-center mt-10">
            <a
              href="/postjob"
              class="px-10 py-5 border border-solid border-[1px] border-[#e4e4ed] rounded-full bg-blue-600 transition-all duration-300 transform hover: text-white hover:-translate-y-1 font-semibold text-lg "
            >
              Post free job
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default FreeJobSection;
