import React from "react";

import HeroSection from "../components/HeroSection";

import Combiner from "./Combiner";
import NavBar from "../../navBar/containers/NavBar";
function JobsListing() {
  return (
    <div className="mt-20 pt-1.5 " >
      <NavBar></NavBar>
      <div style={{ background: "#f9faff" }} className="animate-fade-in">
        <HeroSection ></HeroSection>
      </div>

      <Combiner></Combiner>
    </div>
  );
}

export default JobsListing;
