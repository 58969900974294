import React, { useState } from "react";

const Skills = ({ title, skills }) => {
  return (
    skills.length > 0 && (
      <>
        <h2
          className="section-title mb-1 border-b-2 border-gray-300 "
          style={{ paddingBottom: "10px" }}
          contentEditable
          suppressContentEditableWarning
        >
          {title}
        </h2>
        <p className="sub-content">{skills.join(", ")}</p>
      </>
    )
  );
};

export default Skills;
