import React, { useState } from "react";
import PostJobForm from "../jobform/PostJobForm";
import { notification } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

function RightSideForm({ selectedTab }) {
  const [jobData, setJobData] = useState({
    full_name: "",
    email_address: "",
    job_title: "",
    job_department: "",
    job_type: "",
    job_location: "",
    job_salary: "",
    show_salary: false,
    job_description: "",
    company_name: "",
    company_website: "",
    company_industry: "",
    facebook_profile: "",
    twitter_profile: "",
    instagram_profile: "",
    linkedin_profile: "",
    company_logo: "",
    company_cover: "",
    company_description: "",
    order_id: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = () => {
    const requiredFields = [
      "full_name",
      "email_address",
      "job_title",
      "job_department",
      "job_type",
      "job_location",
      "job_description",
      "company_name",
      "company_website",
      "company_industry",
    ];
    

    const errors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!jobData[field]) {
        errors[field] = "This field is required";
        hasErrors = true;
      }
    });

    setFormErrors(errors);

    if (hasErrors) {
      notification.error({
        message: "Form Submission Error",
        description: "Please fill in all required fields.",
      });
    } else {
      // If no errors, send the data to the backend
      axios
        .post("https://web-postjob-backend.vercel.app/api/v1/postjob", jobData)
        .then((response) => {
          // Handle success, show success notification
          notification.success({
            message: "Form Submitted Successfully",
            description: "We appreciate your interest, and we look forward to connecting with you.",
            // ❤️
          });

          // Optionally, reset the form or perform other actions
          setJobData({
            full_name: "",
            email_address: "",
            job_title: "",
            job_department: "",
            job_type: "",
            job_location: "",
            job_salary: "",
            show_salary: false,
            job_description: "",
            company_name: "",
            company_website: "",
            company_industry: "",
            facebook_profile: "",
            twitter_profile: "",
            instagram_profile: "",
            linkedin_profile: "",
            company_logo: "",
            company_cover: "",
            company_description: "",
            order_id: '',
          });
        })
        .catch((error) => {
          // Handle errors, show error notification
          console.error("Error submitting form:", error);
          notification.error({
            message: "Form Submission Error",
            description:
              "There was an error submitting your form. Please try again later.",
          });
        });
    }
  };

  return (
    <div>
      <PostJobForm
        selectedTab={selectedTab}
        jobData={jobData}
        setJobData={setJobData}
        formErrors={formErrors}
      />
      <div className="flex justify-left mt-10">
        <button
          className="px-10 py-5 border border-solid border-[1px] border-[#e4e4ed] rounded-full bg-blue-600 transition-all duration-300 transform text-white hover:-translate-y-1 font-semibold text-lg"
          onClick={handleSubmit}
        >
          Submit for approval
        </button>
      </div>
    </div>
  );
}

export default RightSideForm;
