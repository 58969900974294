import React from "react";
import Profile from "../components/Profile";
function ProfileContainer() {
  return (
    <div>
      <Profile></Profile>
    </div>
  );
}

export default ProfileContainer;
