import React, { useEffect, useState } from "react";
import axios from "axios";
import { Select } from "antd";

const { Option } = Select;

export default function CountrySelect({ setFormFields, formFields }) {
  const [countries, setCountries] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://countriesnow.space/api/v0.1/countries/flag/images"
        );
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleCountryChange = (value) => {
    setSelectedCountries(value);
    console.log(value);
  };

  return (
    <Select
      id="country"
      name="country"
      autoComplete="country-name"
      value={formFields.locations_interested}
      onChange={(value) => {
        setFormFields({
          ...formFields,
          locations_interested: value,
        });
      }}
      onSearch={handleSearch}
      className="w-full"
      showSearch
      mode="multiple"
      placeholder="Select Country"
      optionLabelProp="children"
      filterOption={false}
      filterSort={(optionA, optionB) =>
        optionA.children
          ?.toString()
          .toLowerCase()
          .localeCompare(optionB.children?.toString().toLowerCase())
      }
    >
      {filteredCountries.map((country) => (
        <Option key={country.iso2} value={country.name}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                backgroundImage: `url(${country.flag})`,
                backgroundSize: "contain",
                backgroundPosition: "left center",
                backgroundRepeat: "no-repeat",
                width: "16px",
                height: "12px",
                marginRight: "8px",
              }}
            />
            <span>{country.name}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
}
