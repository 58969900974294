import React, { useState, useEffect } from "react";
import OverViewContent from "./overview/OverViewContent";
import axios from "axios";
import { useClerk } from "@clerk/clerk-react";
import { Spin } from "antd";

function Overview() {
  const { user } = useClerk();
  const [summaryInfo, setSummaryInfo] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://web-backend-user-profile-v2.onrender.com/api/v1/users/summary?username=${user.primaryEmailAddress.emailAddress}`
      );

      setSummaryInfo(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mt-3 ">
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <OverViewContent summaryInfo={summaryInfo}></OverViewContent>
      )}
    </div>
  );
}

export default Overview;
