import React from "react";
import { Link } from "react-router-dom";
function PostJobCard() {
  return (
    <div className="bg-white rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg hover:-translate-y-1 border mb-4 lg:px-10">
      <div className="mb-4">
        <img
          src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60c7ce2fc1dfba6b0c653e97_icon-job-post-job-board-x-template.svg"
          alt="Logo"
          className="w-25 h-25 mr-4 object-contain rounded-lg"
        />
        <div className="flex-1">
          {/* Second Row */}
          <h2 className="text-3xl font-semibold mb-2 mt-4">Post a job today</h2>
          {/* Third Row */}
          <p className="text-gray-600 text-xl mt-6">
            Post your job opening here and reach our network of top Product
            Management talent.
          </p>
        </div>
      </div>
      {/* Fourth Row */}
      <div className="md:flex justify-center">
        <Link to="/postjob" className="md:w-full">
          <button
            className="w-full md:w-full mt-10 h-7 md:h-14  md:right-10 md:mt-10 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-8  font-bold text-lg rounded"
            style={{
              boxShadow:
                "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
            }}
          >
            POST A JOB
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PostJobCard;
