import React from "react";

function LeftSideForm() {
  return (
    <div className="p-8 xl:p-10 pt-4 pb-4 text-gray-600 ">
      <div className="mt-3">
        <div className="w-20 h-20 mb-3">
          <img
            src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60ca2461a2ab22bb749f85e2_icon-1-post-job-job-board-x-template.svg"
            className="object-cover rounded-xl "
          ></img>
        </div>
        <div className="flex items-center justify-between gap-x-4 mt-3">
          <h2 className="text-gray-600 text-2xl font-semibold leading-8">
            1. Job poster information
          </h2>
        </div>
        <p className="text-gray-600 mt-4 text-sm leading-6 ">
          Please provide your contact information so we can reach you.
        </p>
      </div>
      <div className="mt-20">
        <div className="w-20 h-20 mb-3">
          <img
            src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60ca2461658abe8577ce601c_icon-2-post-job-job-board-x-template.svg"
            className="object-cover rounded-xl "
          ></img>
        </div>
        <div className="flex items-center justify-between gap-x-4 mt-3">
          <h2 className="text-gray-600 text-2xl font-semibold leading-8">
            2. Job information
          </h2>
        </div>
        <p className="text-gray-600 mt-4 text-sm leading-6 ">
          Share the specifics of the job you're posting.
        </p>
      </div>
      <div className="mt-20">
        <div className="w-20 h-20 mb-3">
          <img
            src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60ca2461d9a62897715838ba_icon-3-post-job-job-board-x-template.svg"
            className="object-cover rounded-xl "
          ></img>
        </div>
        <div className="flex items-center justify-between gap-x-4 mt-3">
          <h2 className="text-gray-600 text-2xl font-semibold leading-8">
            3. Company information
          </h2>
        </div>
        <p className="text-gray-600 mt-4 text-sm leading-6 ">
          Tell us about your company.
        </p>
      </div>
    </div>
  );
}

export default LeftSideForm;
