import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  CiLocationOn,
  CiClock2,
  CiDollar,
  CiCalendarDate,
  CiBank,
} from "react-icons/ci";
import { useHistory } from "react-router-dom";
function Card({ job }) {
  const companyLogo = job && job.company_logo;
  const jobTitle = job && job.job_title;
  const companyName = job && job.company_name;
  const jobLocation = job && job.job_location;
  const jobStatus = job && job.job_status;
  const jobPostedDate = job && job.job_posted_date;
  const jobUrl = job && job.job_url;
  const jobSalary = job && job.salary ? job.salary : "Competitive";

  const jobRouteUrl = `/easyjob/${job.company_name}/${job.job_id}`;
  const history = useHistory();

  const [hovered, setHovered] = useState(false);
  const handleApplyEasyJobs = () => {
    // history.push(jobRouteUrl);
    window.open(jobRouteUrl, "_blank");
  };

  return (
    <div
      className="flex flex-col md:flex-row gap-4 ml-2 mr-3 md:items-center bg-white p-2 mt-3 rounded-lg transition-all duration-600 hover:-translate-y-1 border hover:border-[#0B42D5] border-1.5 text-gray-600 cursor-pointer pt-4 pb-4"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleApplyEasyJobs}
    >
      <div className="ml-3 md:flex gap-10">
        <div className="flex w-20 h-20  md:max-h-[62px] mr-[22px] border-solid border-[1px] border-[#e4e4ed] rounded-[8px] justify-center items-center">
          <img
            src={companyLogo}
            alt="Company Logo"
            style={{
              objectFit: "fit",
              padding: "4%",
            }}
          />
        </div>

        <div className="flex-initial w-64 mt-3">
          <h3
            className={`font-semibold ${
              hovered ? "text-blue-600" : ""
            } line-clamp-1 overflow-hidden whitespace-normal
         text-sm text-black `}
          >
            {jobTitle}
          </h3>
          <div className="flex gap-1 items-center ">
            <div className="text-blue-600 capitalize underline ">
              {companyName}
            </div>
            <svg
              className={`text-blue-600 w-4 h-4 transition-all duration-600 transform rotate-45 mt-1 ${
                hovered ? "translate-x-1 -translate-y-1" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </div>
        </div>
        <div className="flex-initial w-46 mt-3">
          <div className="md:flex gap-10">
            <div className="text-gray w-32 mt-4 md:mt-0">
              {" "}
              {/* Fixed width */}
              <div className="flex items-center gap-2 text-gray">
                <img
                  src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60c7dc5172557266c1162fc4_icon-1-job-categories-job-board-x-template.svg"
                  alt="Location Icon - Job Board X Webflow Template"
                />
                <div className="text-sm">Location</div>
              </div>
              <div className="font-semibold text-sm text-black mt-1 md:ml-0 md:mt-2">
                {jobLocation.split(/[/,]/)[0]}
              </div>
            </div>

            {/* <div className="text-gray w-32 mt-4 md:mt-0">
              <div className="flex items-center gap-2 text-gray">
                <img
                  src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60c7dc51b6792171f081ab50_icon-2-job-categories-job-board-x-template.svg"
                  alt="Location Icon - Job Board X Webflow Template"
                />
                <div className="text-sm">Experience</div>
              </div>
              <div className="font-semibold text-sm text-black mt-1 md:ml-0 md:mt-2">
                {category}
              </div>
            </div> */}

            <div className="text-gray w-32 mt-4 md:mt-0">
              {" "}
              {/* Fixed width */}
              <div className="flex items-center gap-2 text-gray">
                <img
                  src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60c7dc51d7c3af8320618b87_icon-5-job-categories-job-board-x-template.svg"
                  alt="Location Icon - Job Board X Webflow Template"
                />
                <div className="text-sm">Salary</div>
              </div>
              <div className="font-semibold text-sm text-black mt-1 md:ml-0 md:mt-2">
                {jobSalary.split("/")[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
