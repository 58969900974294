import { useState } from "react";
import { Dialog, RadioGroup } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";

const pricing = {
  frequencies: [
    { value: "monthly", label: "Monthly", priceSuffix: "/month" },
    { value: "annually", label: "Annually", priceSuffix: "/year" },
  ],
  tiers: [
    {
      name: "1 Job credits",
      id: "tier-freelancer",
      href: "pricing",
      image:
        "https://assets.website-files.com/60c77302fcfa2bdb6e595f76/60cbe089fb631680475635ae_icon-1-pricing-job-board-x-template.png",
      price: { monthly: "$1", annually: "$144" },
      description: "Product Mindset’s Basic Plan $1/month",
      features: [
        "1 Job Posting/month",
        "Job listing in PM Newsletter",
        "Basic analytics at the end of 30 days",
        "48-hour support response time",
      ],
      mostPopular: false,
      link: "https://productmindset.gumroad.com/l/PMBASIC",
    },
    {
      name: "5 Job credits",
      id: "tier-startup",
      href: "pricing",
      image:
        "https://assets.website-files.com/60c77302fcfa2bdb6e595f76/60cbe081390ab3c28e93bbcb_icon-2-pricing-job-board-x-template.png",
      price: { monthly: "$8", annually: "$288" },
      description: "Product Mindset’s Pro Plan $8/month",
      features: [
        "5 Job Posting/month",
        "2 x Job listing in PM Newsletter",
        "Advanced analytics",
        "24-hour support response time",
      ],
      mostPopular: false,
      link: "https://productmindset.gumroad.com/l/vfndxj",
    },
    {
      name: "Unlimited Job credits",
      id: "tier-enterprise",
      href: "pricing",
      image:
        "https://assets.website-files.com/60c77302fcfa2bdb6e595f76/60cbe077f017e042cf62cfa8_icon-3-pricing-job-board-x-template.png",
      price: { monthly: "$28", annually: "$576" },
      description: "Product Mindset’s Pro+ Plan $28/month",
      features: [
        "Unlimited Posting/month",
        "4 x Job listing in PM Newsletter",
        "Advanced analytics",
        "12-hour support response time",
      ],
      mostPopular: true,
      link: "https://productmindset.gumroad.com/l/jiipx",
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);

  return (
    <div className="bg-white mb-10">
      <main>
        {/* Pricing section */}
        <div className="mx-auto  max-w-7xl px-6 mt-20 lg:px-8">
          <div className="mx-auto max-w-4xl text-center animate-fade-in">
            <p className="text-4xl font-semibold tracking-tight sm:text-5xl">
              Featured jobs on Product Mindset
            </p>
          </div>
          <p className="mx-auto mt-10 max-w-2xl text-center text-lg leading-8 text-gray-600 animate-fade-in">
            Find your dream Product Manager with our targeted job listings, and
            connect with top talent effortlessly!
          </p>

          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 cursor-pointer">
            {pricing.tiers.map((tier) => (
              <a href={tier.link} target="_blank">
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-blue-600 hover:border-white"
                      : "text-gray-600 hover:border-[#0B42D5]",
                    "border rounded-3xl p-8 xl:p-10 transition-all duration-600 hover:-translate-y-1 cursor-pointer pt-4 pb-4"
                  )}
                >
                  <div className="w-20 h-20 mb-3">
                    <img
                      src={tier.image}
                      className="object-cover rounded-xl "
                    ></img>
                  </div>
                  <div className="flex items-center justify-between gap-x-4 mt-3">
                    <h2
                      id={tier.id}
                      className={classNames(
                        tier.mostPopular ? "text-white" : " text-gray-600",
                        "text-2xl font-semibold leading-8"
                      )}
                    >
                      {tier.name}
                    </h2>
                  </div>
                  <p
                    className={classNames(
                      tier.mostPopular ? "text-white" : " text-gray-600",
                      "mt-4 text-sm leading-6 "
                    )}
                  >
                    {tier.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span
                      className={classNames(
                        tier.mostPopular ? "text-white" : " text-gray-600",
                        "text-4xl font-bold tracking-tight "
                      )}
                    >
                      {tier.price[frequency.value]}
                    </span>
                    <span
                      className={classNames(
                        tier.mostPopular ? "text-white" : " text-gray-600",
                        "text-4xl font-bold tracking-tight "
                      )}
                    >
                      {frequency.priceSuffix}
                    </span>
                  </p>
                  <p
                    // href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "bg-white shadow-sm focus-visible:outline-indigo-500 mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 text-black"
                        : "bg-blue-600 shadow-sm  mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 text-white",
                      ""
                    )}
                  >
                    Buy plan
                  </p>
                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <div
                          className={classNames(
                            tier.mostPopular ? "bg-white" : "bg-blue-100",
                            "rounded-full p-1"
                          )}
                        >
                          <CheckIcon
                            className="h-5 w-5 text-blue-600 font-semibold"
                            aria-hidden="true"
                          />
                        </div>
                        <span
                          className={classNames(
                            tier.mostPopular ? "text-white" : "text-gray-600",
                            "font-semibold"
                          )}
                        >
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </a>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
