import { useState, useEffect } from "react";

import ListCard from "./ListCard";
import ChartCard from "./ChartCard";
function FouthMainCard() {
  
  return (
    <div className="md:flex md:gap-6">
      {/* First Column */}
      {/* <div className=" md:w-2/6 bg-white shadow-lg">
        <p className="px-3 py-3 text-xl font-semibold border-b">Circle Chart</p>

        <ChartCard></ChartCard>
      </div> */}

      {/* Second Column */}
      {/* <div className="md:flex md:flex-col md:w-4/6 bg-white shadow-lg ">
        <p className="px-3 py-3 text-xl font-semibold border-b">Projects</p>
        <ListCard></ListCard>
        <ListCard></ListCard>
        <ListCard></ListCard>
        <ListCard></ListCard>
        <ListCard></ListCard>
      </div> */}
    </div>
  );
}

export default FouthMainCard;
