import React from "react";
import TextField from "../../../../helper/textField/textField";
import { Text, CustomGrid } from "../../../../helper/helper";
function BasicInfo({ form, handleChange }) {
  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">Personal Information</Text>
      </div>
      <CustomGrid size={3}>
        <TextField
          type="text"
          placeholder="Full Name"
          name="name"
          value={form.name}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
        <TextField
          type="text"
          placeholder="Job Title"
          name="position"
          value={form.position}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
        <TextField
          type="text"
          placeholder="Contact Information"
          name="contactInformation"
          value={form.contactInformation}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
          minLength="10"
          maxLength="15"
        />
        <TextField
          type="email"
          placeholder="Email"
          name="email"
          value={form.email}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
        <TextField
          type="text"
          placeholder="Address"
          name="address"
          value={form.address}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
      </CustomGrid>
    </div>
  );
}

export default BasicInfo;
