import React from "react";
import Describe from "./Describe";
import Next_Five_Years from "./Next_Five_Years";
function Mian_Culture_Component({ formFields, setFormFields }) {
  return (
    <div>
      <div className="space-y-12 py-8 p-3 bg-white">
        <Describe
          setFormFields={setFormFields}
          formFields={formFields}
        ></Describe>
        <Next_Five_Years
          setFormFields={setFormFields}
          formFields={formFields}
        ></Next_Five_Years>
      </div>
    </div>
  );
}

export default Mian_Culture_Component;
