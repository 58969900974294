import React from "react";

function RightContent({ job }) {
  const jobUrl = job && job.job_url;
  const handleApplyEasyJobs = () => {
    window.open(`${jobUrl}`, "_blank");
  };
  return (
    <div className="bg-white rounded-2xl p-8 shadow-md transition-all duration-300 hover:shadow-lg hover:-translate-y-1 border mb-4 lg:px-5 mt-5">
      <div className="mb-4">
        <div className="flex-1">
          {/* Second Row */}
          <h2 className="text-2xl font-semibold mb-2 mt-4">
            Ready to apply for this job opening?
          </h2>
          {/* Third Row */}
          <p className="text-gray-600 text-xl">
            Please let the company know that you found this position on this Job
            Board as a way to support us, so we can keep posting cool jobs.
          </p>
        </div>
      </div>
      {/* Fourth Row */}
      <div className="md:flex justify-center">
        <button
          className="w-full md:w-full mt-10 h-7 md:h-14  md:right-10 md:mt-10 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-8  font-bold text-lg rounded"
          style={{
            boxShadow:
              "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
          }}
          onClick={handleApplyEasyJobs}
        >
          APPLY NOW
        </button>
      </div>
    </div>
  );
}

export default RightContent;
