import React, { useState, useEffect } from "react";
import CardList from "../first_row/CardList";
import MainCard from "../second_row/MainCard";
import MainCardList from "../third_row/MainCardList";
import FouthMainCard from "../fouth_row/FouthMainCard";
import axios from "axios";
import { useClerk } from "@clerk/clerk-react";
import { Spin } from "antd"; // Assuming you're using Ant Design for the loading spinner

function MainDashBoard() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const { user } = useClerk();

  useEffect(() => {
    axios
      .get(
        `https://web-back-dashbaord-apis.vercel.app/api/v1/getUserBasedAnalytics?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setUserData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <div
          className="flex justify-center items-center"
          style={{ minHeight: "200px" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <CardList userData={userData} />
          <div className="py-4">
            <MainCard userData={userData} />
          </div>
          <div className="py-4">
            <MainCardList userData={userData} />
          </div>
          <FouthMainCard />
        </>
      )}
    </div>
  );
}

export default MainDashBoard;
