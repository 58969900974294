import React, { useState } from "react";
import { Checkbox, Input } from "antd";
import { Link } from "react-router-dom";
import "./form.css";
function PostJobForm({ selectedTab, jobData, setJobData }) {
  const handleJobForm = (e) => {
    setJobData({ ...jobData, [e.target.name]: e.target.value });
  };

  return (
    <div className="card post-job-form-card bg-white border border-solid border-[1px] border-[#e4e4ed] rounded-[20px] shadow-md transform translate-x-0 translate-y-0 p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="name"
            className="font-semibold text-md font-semibold text-md text-gray-600"
          >
            Full name <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="full_name"
            value={jobData.full_name}
            placeholder="What’s your name?"
            id="name"
            onChange={handleJobForm}
          />
        </div>
        <div className="grid">
          <label for="email" className="font-semibold text-md text-gray-600">
            Email Address <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 mt-4"
            maxlength="256"
            name="email_address"
            value={jobData.email_address}
            placeholder="What’s your email?"
            id="email"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <hr className="border-t border-gray-300 my-6 mx-4" />

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-5 mt-5">
        <div className="grid">
          <label for="title" className="font-semibold text-md text-gray-600">
            Job title <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4 w-full"
            maxlength="256"
            name="job_title"
            value={jobData.job_title}
            placeholder="Enter job title"
            id="title"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="department"
            className="font-semibold text-md text-gray-600"
          >
            Job department <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="job_department"
            value={jobData.job_department}
            placeholder="Ex. Development"
            id="department"
            onChange={handleJobForm}
          />
        </div>
        <div className="grid">
          <label for="job_type" className="font-semibold text-md text-gray-600">
            Job type <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 mt-4"
            maxlength="256"
            name="job_type"
            value={jobData.job_type}
            placeholder="Ex. Remote"
            id="job_type"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="job_location"
            className="font-semibold text-md text-gray-600"
          >
            Job location <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="job_location"
            value={jobData.job_location}
            placeholder="San Francisco"
            id="job_location"
            onChange={handleJobForm}
          />
        </div>
        <div className="grid">
          <label
            for="job_salary"
            className="font-semibold text-md text-gray-600"
          >
            Job salary
          </label>
          <input
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 mt-4"
            maxlength="256"
            name="job_salary"
            value={jobData.job_salary}
            placeholder="Annual salary in USD"
            id="job_salary"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-5 mt-5">
        <div className="flex items-center gap-2">
          <Checkbox
            name="show_salary"
            className="custom-checkbox"
            onChange={(e) => {
              setJobData({...jobData,[e.target.name]:e.target.checked})
            }}
            checked={jobData.show_salary}
          />
          <p className="font-semibold text-md text-gray-600">Show salary?</p>
        </div>
        <p className="mx-8 text-lg -mt-2 leading-1 text-gray-600">
          If you prefer, the job post salary can also be kept private.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-5 mt-3">
        <div className="grid gap-4">
          <label
            htmlFor="job_description"
            className="font-semibold text-md text-gray-600"
          >
            Job description <span className="text-red-600">*</span>
          </label>
          <Input.TextArea
            rows={6} // Set the number of rows you want to display
            className="input w-input border border-gray-300 border-[1px] border-[#e4e4ed] rounded-xl bg-white focus:border-indigo-500 focus:ring-indigo-500"
            maxLength="256"
            name="job_description"
            value={jobData.job_description}
            placeholder="Write your job description here. Please be as detailed as possible and include requirements, responsibilities, benefits, and any other relevant information."
            id="job_description"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <hr className="border-t border-gray-300 my-6 mx-4" />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="company_name"
            className="font-semibold text-md font-semibold text-md text-gray-600"
          >
            Company name <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="company_name"
            value={jobData.company_name}
            placeholder="What’s is the company name?"
            id="company_name"
            onChange={handleJobForm}
          />
        </div>
        <div className="grid">
          <label
            for="company_website"
            className="font-semibold text-md text-gray-600"
          >
            Company website <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 mt-4"
            maxlength="256"
            name="company_website"
            value={jobData.company_website}
            placeholder="https://www.company.com"
            id="company_website"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="company_industry"
            className="font-semibold text-md font-semibold text-md text-gray-600"
          >
            Company industry <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="company_industry"
            value={jobData.company_industry}
            placeholder="What’s is your company industry?"
            id="company_industry"
            onChange={handleJobForm}
          />
        </div>
        <div className="grid">
          <label
            for="facebook_page"
            className="font-semibold text-md text-gray-600"
          >
            Facebook Page
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 mt-4"
            maxlength="256"
            name="facebook_profile"
            value={jobData.facebook_profile}
            placeholder="https://facebook.com"
            id="facebook_page"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="company_twiter_profile"
            className="font-semibold text-md font-semibold text-md text-gray-600"
          >
            Twitter profile
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="twitter_profile"
            value={jobData.twitter_profile}
            placeholder="https://twitter.com"
            id="company_twiter_profile"
            onChange={handleJobForm}
          />
        </div>
        <div className="grid">
          <label
            for="instagram_page"
            className="font-semibold text-md text-gray-600"
          >
            Instagram profile
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 mt-4"
            maxlength="256"
            name="instagram_profile"
            value={jobData.instagram_profile}
            placeholder="https://instagram.com"
            id="instagram_page"
            onChange={handleJobForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 mt-5">
        <div className="grid">
          <label
            for="company_linkedin_page"
            className="font-semibold text-md font-semibold text-md text-gray-600"
          >
            Linkedin page
          </label>
          <input
            type="text"
            className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4"
            maxlength="256"
            name="linkedin_profile"
            value={jobData.linkedin_profile}
            placeholder="https://linkedin.com/"
            id="company_linkedin_page"
            onChange={handleJobForm}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-5 mt-3">
        <div className="grid gap-4">
          <label
            htmlFor="company_description"
            className="font-semibold text-md text-gray-600"
          >
            Company description <span className="text-red-600">*</span>
          </label>
          <Input.TextArea
            rows={6} // Set the number of rows you want to display
            className="input w-input border border-gray-300 border-[1px] border-[#e4e4ed] rounded-xl bg-white focus:border-indigo-500 focus:ring-indigo-500"
            maxLength="256"
            name="company_description"
            value={jobData.company_description}
            placeholder="Write your company description here. Please describe what your company does, how long it had been operating, and any other relevant information. Please write up to 1200 characters."
            id="company_description"
            onChange={handleJobForm}
          />
        </div>
      </div>

      {selectedTab === "Paid job" && (
        <>
          <hr className="border-t border-gray-300 my-6 mx-4" />

          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-5 mt-3">
            <p className="text-md  text-gray-600 animate-fade-in">
              If you already purchased a featured job credit,{" "}
              <span className="font-semibold">
                please insert your order ID below.
              </span>
            </p>
            <p className="text-md  text-gray-600 animate-fade-in">
              If you didn't, you can go to{" "}
              <Link
                to="/pricing"
                // target="_blank"
                className="text-blue-500 border-b-2 border-blue-500"
              >
                featured jobs page
              </Link>{" "}
              and purchase it.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-5 mt-5">
            <div className="grid">
              <label
                for="order_id"
                className="font-semibold text-md text-gray-600"
              >
                Order ID
              </label>
              <input
                type="text"
                className="input w-input py-5 border border-gray-300 border-[1px] border-[#e4e4ed] rounded-full bg-white focus:border-indigo-500 focus:ring-indigo-500 p-4 -ml-2 mt-4 w-full"
                maxlength="256"
                name="order_id"
                value={jobData.order_id}
                placeholder="insert your order ID"
                id="order_id"
                onChange={handleJobForm}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PostJobForm;
