import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import CardContainer from "./CardContainer";

const categoryImages = {
  HIRING:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff60851a742b367cf49_Bag.svg",
  MINDSET:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff7ef9cdce701c52b9cc_SketchLogo.svg",
  MENTAL_MODEL:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604d22491a63f0fc18512cfb_SlackLogo.svg",
  ENGINEERING:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff815b1b307f51df8a76_Swatches.svg",
  INTERVIEW:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff8260c2c6ceeafb87b_Buildings.svg",
  PRODUCT_MANAGEMENT:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff76813f9481e3583f9e_Lightbulb.svg",
  BOOKS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff64cfde0a9ae36aef1_Book.svg",
  BLOGS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604d224a16ac69748dc9c51e_TwitchLogo.svg",
  NEWSLETTER:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff7c4cfde04b5136aebe_RocketLaunch.svg",
  GOOD_READS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff74cfde0351336aef2_Bookmark.svg",
  FRAMEWORK:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff77249985616a11e289_MapTrifold.svg",
  TOOLS:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff7ad52be02d264c54fc_PencilCircle.svg",
  COURSE:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bfff6a9921cdfc6b4fa3c_ArchiveBox.svg",
  GUIDE:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff8772b9ab389c53081a_WifiMedium.svg",
  DESIGN:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff860c81322901384cc7_Watch.svg",
  FAILED_STORIES:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff864ab0c81c22e581c4_WarningOctagon.svg",
  TEMPLATE:
    "https://assets.website-files.com/604824e3878f143d5dba04b2/604bff815b1b307f51df8a76_Swatches.svg",
};

const filters = [
  {
    id: "category",
    name: "Category",
    options: [
      { value: "all", label: "All Categories" },
      ...Object.keys(categoryImages).map((category) => ({
        value: category,
        label: category,
      })),
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DocumentMain() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [filterArray, setFilterArray] = useState(["all"]); // Initialize with "all" selected
  const [filteredArray, setFilteredArray] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(
          "https://web-backend-documents.vercel.app/api/v1/getdocuments"
        );
        setDocuments(response.data);
        setFilteredArray(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching documents:", error);
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  useEffect(() => {
    // Filter documents based on selected filters
    const filtered = documents.filter((doc) => {
      if (filterArray.includes("all")) {
        return true;
      }
      return filterArray.includes(doc.CATEGORY); // Use doc.CATEGORY here
    });
    setFilteredArray(filtered);
  }, [documents, filterArray]);

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;

    if (selectedFilter === "all") {
      setFilterArray(["all"]); // Selecting "All" should only include "all" in the filter array
    } else {
      setFilterArray((prevFilterArray) => {
        if (prevFilterArray.includes("all")) {
          // If "All" is already selected, remove it and select the new category
          return [selectedFilter];
        } else {
          // Toggle the selected category in or out of the filterArray
          if (prevFilterArray.includes(selectedFilter)) {
            return prevFilterArray.filter(
              (filter) => filter !== selectedFilter
            );
          } else {
            return [...prevFilterArray, selectedFilter];
          }
        }
      });
    }
  };

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4">
                    {filters.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.name}
                        className="border-t border-gray-200 pb-4 pt-4"
                      >
                        {({ open }) => (
                          <fieldset>
                            <legend className="w-full px-2">
                              <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                <span className="text-sm font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex h-7 items-center">
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? "-rotate-180" : "rotate-0",
                                      "h-5 w-5 transform"
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </legend>
                            <Disclosure.Panel className="px-4 pb-2 pt-4">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`${section.id}-${optionIdx}-mobile`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onChange={handleFilterChange}
                                      checked={filterArray.includes(
                                        option.value
                                      )}
                                    />
                                    <label
                                      htmlFor={`${section.id}-${optionIdx}-mobile`}
                                      className="ml-3 text-sm text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </fieldset>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="bg-white p-5">
          <div className="border-b border-gray-200 pb-10">
            <h1 className="text-lg md:text-4xl font-semibold tracking-tight text-gray-900">
              Product Mindset’s Reading List
            </h1>
            <p className="mt-4 text-sm md:text-base text-gray-400 font-semibold">
              All useful links are organized in one place.
            </p>
          </div>

          <div className="pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="text-sm font-medium text-gray-700">
                  Filters
                </span>
                <PlusIcon
                  className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              </button>

              <div className="hidden lg:block">
                <form className="space-y-10 divide-y divide-gray-200">
                  {filters.map((section, sectionIdx) => (
                    <div
                      key={section.name}
                      className={sectionIdx === 0 ? null : "pt-10"}
                    >
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-900">
                          {section.name}
                        </legend>
                        <div className="space-y-3 pt-6">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                defaultValue={option.value}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onChange={handleFilterChange}
                                checked={filterArray.includes(option.value)}
                              />
                              <label
                                htmlFor={`${section.id}-${optionIdx}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                  ))}
                </form>
              </div>
            </aside>

            {/* Product grid */}
            <div className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
              <CardContainer
                documents={filteredArray}
                setDocuments={setDocuments}
                loading={loading}
              ></CardContainer>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
