import { PaperClipIcon } from "@heroicons/react/20/solid";

export default function OverViewContent({ summaryInfo }) {
  const resumeName = "Resume_" + summaryInfo.role;
  const coverLetterName = "CoverLetter_" + summaryInfo.role;

  return (
    <div className="border p-3 rounded-lg bg-white">
      <div className="px-4 sm:px-0 m-4">
        <h2 className="text-base font-semibold leading-7 text-gray-900 ">What recruiters will see</h2>
        {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
      </div>
      <div className="md:ml-40 md:mr-40 mt-10 border md:p-4 border-gray-300 rounded-lg">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Full name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {summaryInfo.name}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Application for
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {summaryInfo.role}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {summaryInfo.username}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Experience
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {summaryInfo.experience !== null &&
                summaryInfo.experience !== "" && (
                  <>{summaryInfo.experience} Years</>
                )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {summaryInfo.country}
              {summaryInfo.state && `, ${summaryInfo.state}`}
              {summaryInfo.city && `, ${summaryInfo.city}`}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              About
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {summaryInfo.about}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Attachments
            </dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
               
                className="divide-y divide-gray-100 rounded-md border border-gray-200"
              >
                {summaryInfo.pdfFile && (
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {resumeName}
                        </span>
                        {/* <span className="flex-shrink-0 text-gray-400">
                        
                      </span> */}
                      </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href={summaryInfo.pdfFile} // Assuming summaryInfo.pdfFile contains the file URL
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                )}
                {summaryInfo.cover_letter_pdfFile && (
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {coverLetterName}
                        </span>
                        {/* <span className="flex-shrink-0 text-gray-400">4.5mb</span> */}
                      </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href={summaryInfo.cover_letter_pdfFile}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
