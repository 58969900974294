import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import ProgressBar from "progressbar.js";
const SpeedometerProgressBar = ({ progress }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const bar = new ProgressBar.SemiCircle(container, {
      strokeWidth: 10,
      color: "#ffb731",
      trailColor: "#eee",
      trailWidth: 10,
      easing: "easeInOut",
      duration: 1400,
      svgStyle: null,
      text: {
        value: "",
        alignToBottom: true,
      },
      from: { color: "#ffb731" },
      to: { color: "#ffb731" },
      step: (state, bar) => {
        bar.path.setAttribute("stroke", state.color);
        var value = Math.round(bar.value() * 100);
        if (value === 0) {
          bar.setText(`0%`);
        } else {
          bar.setText(`${value}%`);
        }

        bar.text.style.color = "black";
      },
    });

    bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
    bar.text.style.fontSize = "2rem";

    bar.animate(progress / 100);

    return () => {
      // Cleanup
      bar.destroy();
    };
  }, [progress]);

  return <div id="container" ref={containerRef}></div>;
};

function MainCard({ userData }) {
  const [profileComplete, setProfileComplete] = useState(0);
  const { user } = useClerk();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://web-backend-user-profile-v2.onrender.com/api/v1/users/steps?username=${user.primaryEmailAddress.emailAddress}`
        );
        setProfileComplete(response.data.totalPercentage);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="md:flex md:gap-6">
      {/* First Column */}
      <div className="md:flex md:gap-6 md:w-1/2 ">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full mb-3 md:mb-0">
          <Link to="/user/interview">
            <div className="w-12 h-12 flex justify-center items-center bg-[#ebe2fe] rounded-xl">
              <img
                src="https://assets.website-files.com/6057ab51530cb39d3fdac75d/605882d37ee33fcb3f67627f_hard-drive.svg"
                alt="Company Logo"
                className="object-contain p-2"
              />
            </div>

            <div className="flex flex-col justify-between mt-4">
              <h2 className="text-xl font-semibold">Interview Questions</h2>
              <p className="text-gray-600 mt-3">Access our Interview Preparation Questions</p>
            </div>
          </Link>
        </div>

        {/* Second Division */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full mb-3 md:mb-0">
          <Link to="/user/report">
            <div className="w-12 h-12 flex justify-center items-center bg-[#ebe2fe] rounded-xl">
              <img
                src="https://assets.website-files.com/6057ab51530cb39d3fdac75d/605882d30a1fe8e9228d47ed_folder.svg"
                alt="Company Logo"
                className="object-contain p-2"
              />
            </div>

            <div className="md:flex flex-col justify-between mt-8">
              <h2 className="text-xl font-semibold">Saved Jobs</h2>
              <div className="flex justify-between">
                <p className="text-gray-600 mt-2">
                  {userData?.saved_jobs} jobs
                </p>
                <p className="text-gray-600 mt-2">
                  
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* Second Column */}
      <div className="md:flex md:w-1/2 bg-white shadow-lg rounded-lg p-6 gap-4 ">
        <div className="md:w-1/2">
          <div className="w-full cursor-pointer">
            <Link to='/user/profile'>
              <div className="w-12 h-12 flex justify-center items-center bg-[#d9faf2] rounded-xl">
                <img
                  src="https://assets.website-files.com/6057ab51530cb39d3fdac75d/6058831413ede764f64a258d_pie-chart.svg"
                  alt="Company Logo"
                  className="object-contain p-2"
                />
              </div>

              <div className="flex flex-col justify-between mt-4">
                <h2 className="text-xl font-semibold">Profile Complete %</h2>
                <p className="text-gray-600 mt-2">
                  Enhance Recommendations: By Completing Profile.
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 p-2 flex justify-center items-center">
          <SpeedometerProgressBar progress={profileComplete} />
        </div>
      </div>
    </div>
  );
}

export default MainCard;
