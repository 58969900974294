import React from 'react'
import CompanyJobCard from '../components/CompanyJobCard'
function DashBoardContainer() {
  return (
      <div>
          <CompanyJobCard></CompanyJobCard>
    </div>
  )
}

export default DashBoardContainer