import React, { useState,useEffect } from "react";

const tabs = [
  { name: "Free job", content: "" },
  { name: "Paid job", content: "" }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomTabs({activeTab,setActiveTab}) {
  // const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    setActiveTab(tabs[0])
  }, []);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border focus:border-indigo-500 focus:ring-indigo-500 p-2"
          value={activeTab.name}
          onChange={(e) =>
            setActiveTab(tabs.find((tab) => tab.name === e.target.value))
          }
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex justify-center" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                // href="/"
                className={classNames(
                  tab === activeTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                )}
                aria-current={tab === activeTab ? "page" : undefined}
                onClick={() => setActiveTab(tab)}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
        <div className="py-5">{activeTab.content}</div>
      </div>
    </div>
  );
}
