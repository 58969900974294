import React from "react";
import LeftContent from "../jobs_cards/LeftContent";
import RightContent from "../side_cards/RightContent";
import { Link } from "react-router-dom";

function Feature1() {
  return (
    <div className="bg-[#f7f7fb]">
      <div className="mx-auto lg:max-w-7xl ">
        <div className="md:flex justify-between px-10 py-10 md:px-10 md:py-10 md:pt-40">
          <h1 className="text-2xl md:text-4xl font-bold">
            Latest{" "}
            <span className="text-[#153cf5] underline">Product Jobs</span>
          </h1>
          <Link to='/postjob' className='transition-all duration-600 '>
            <button
              className="w-full md:w-auto mt-10 h-9 md:h-14  md:right-10 md:mt-5 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-8  font-bold text-lg rounded"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
            >
              POST A JOB
            </button>
          </Link>
        </div>

        <div className="md:flex pb-10">
          <div className="w-full md:w-5/6">
            <LeftContent></LeftContent>
          </div>
          <div className="w-full md:w-2/6 ">
            <RightContent></RightContent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature1;
