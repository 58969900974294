import React from "react";
import JobsCompanyCard from "../components/JobsCard";
import SignUpCard from "../components/SignUpCard";
import PostJobCard from "../postjob/PostJobCard";
function Combiner() {
  return (
    <div >
      {/* className="bg-gray-50 " */}
      <div className="flex flex-col  md:flex-row-reverse md:mb-6 p-1 mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-15">
        <div className="md:w-2/6 w-full mt-6 md:mt-10 md:ml-2 sm:order-2 mr-1 md:order-1 lg:px-10">
          <PostJobCard></PostJobCard>
          {/* <SignUpCard></SignUpCard> */}
        </div>
        <div className="md:w-4/6 w-full sm:order-1 md:order-2 ">
          <JobsCompanyCard></JobsCompanyCard>
        </div>
      </div>
    </div>
  );
}

export default Combiner;
