import React from 'react'
import MianCoverLetterComponent from '../components/MianCoverLetterComponent'
function MainCoverLetterContainer() {
  return (
      <div>
          <MianCoverLetterComponent></MianCoverLetterComponent>
    </div>
  )
}

export default MainCoverLetterContainer