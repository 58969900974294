import React, { useState } from "react";
import JobsModal from "../../DashBoard/modal/JobsModal";
import { Result, Button, Pagination } from "antd";

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export default function JobCard({ filterJobs }) {
  const [open, setOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const handleModalOpen = (job) => {
    setJobId(job?.job_id);
    setCompanyName(job.company_name);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;
  const displayedJobs = filterJobs.slice(startIdx, endIdx);

  return (
    <div className="p-2">
      {open && (
        <JobsModal
          jobId={jobId}
          companyName={companyName}
          open={open}
          setOpen={setOpen}
          onClose={handleModalClose}
        />
      )}
      <div className="mt-8 flow-root p-2">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-12">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 ">
            {filterJobs.length === 0 ? (
              <Result
                status="info"
                title="No data found"
                subTitle="No jobs match the selected criteria."
              />
            ) : (
              <div>
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Company Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Job Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Posted Date
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {displayedJobs.map((job, index) => (
                      <tr key={index} className="border">
                        <td className="whitespace-nowrap py-5 text-sm sm:pl-0 w-1/6">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0 ml-4">
                              <img
                                src={job.company_logo}
                                className="w-14 h-14 object-contain"
                                alt={job.company_name}
                              ></img>
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900 capitalize">
                                {job.company_name}
                              </div>
                              <div className="mt-1 text-gray-500">
                                {job.label}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 w-1/6">
                          <div className="text-gray-900">{job.job_title}</div>
                          <div className="mt-1 text-gray-500">
                            {job.job_location}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 w-1/6">
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize">
                            {job.job_status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 w-1/6">
                          {formatDate(job.job_posted_date)}
                        </td>
                        <td className="relative whitespace-nowrap py-5  text-left text-sm font-medium sm:pr-0 md:mr-10 px-6 w-1/6">
                          <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                            <button
                              type="button"
                              className="rounded-md bg-[#153cf5] px-4 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => handleModalOpen(job)}
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-4 flex justify-center">
                  <Pagination
                    current={currentPage}
                    total={filterJobs.length}
                    pageSize={pageSize}
                    onChange={onPageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
