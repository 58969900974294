import React, { useState, useRef } from "react";
import { BsRobot } from "react-icons/bs";
import { Input, Button, message } from "antd";
import axios from "axios";

const Feature2 = ({ posts }) => {
  const [email, setEmail] = useState("");
  const inputRef = useRef(null); // Create a ref
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmitButton = () => {
    if (email === "") {
      message.error("Please fill the email id");
    } else if (!validateEmail(email)) {
      message.error("Please enter a valid email address");
    } else {
      axios
        .post("https://quotes-pi-sand.vercel.app/api/v1/addemail", {
          email: email,
        })
        .then(
          (response) => {
            console.log(response);
            message.success(
              `Thank you for subscribing! We will send updates to ${email}`
            );
            setEmail("");
          },
          (error) => {
            console.log(error);
            setEmail("");
          }
        );
    }
  };
  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div className="relative bg-[#153cf5] text-white mx-auto lg:max-w-7xl lg:px-8">
      <img
        src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec74faaf972fa289eb5a6ab_banner-bg.svg"
        className="absolute top-0 left-0 w-full h-full object-cover"
        alt="Background"
      />
      <div className="md:flex justify-center items-center pb-10 md:gap-6">
        <div className="w-full px-5 py-10 md:w-3/6 text-left md:px-10 md:py-20 md:mt-10">
          <h1 className="text-2xl md:text-5xl font-semibold mb-4">
            Find your next great opportunity!
          </h1>
          <p className="text-lg md:text-2xl mb-6">
            Join our newsletter and receive the best job openings every week on
            your inbox.
          </p>
        </div>
        <div className="w-full md:w-3/6 relative">
          <div className="mb-6 px-2 md:px-0 relative md:mr-20 mt-10 ">
            <input
              type="text"
              className="w-full md:h-12 h-12 md:pr-20 md:pl-10  md:py-8 shadow rounded text-black border border-white hover:border-[#153cf5] hover:border-1  md:text-2xl text-lg pl-3 font-semibold  hover:shadow transition-all duration-600 hover:-translate-y-.1 p-0.5"
              placeholder="Enter your email"
              onChange={handleChange}
              value={email}
              ref={inputRef}
            />

            <button
              className="absolute h-8 md:h-10 px-3 right-5 md:right-3 top-1/2 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-4  font-bold text-lg rounded"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
              onClick={onSubmitButton}
            >
              SUBSCRIBE
            </button>
          </div>
          <p className="text-lg md:text-xl mb-6 px-2 md:px-0">
            Be part of a thriving community of over 25,000+ Product Managers who
            trust ProductMindset.io to advance their careers and stay ahead in
            the field.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Feature2;
