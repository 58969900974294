import { useState, useEffect } from "react";
import { Select } from "antd";
import axios from "axios";

const { Option } = Select;

const StateSelect = ({
  selectedCountry,
  setSelectedState,
  selectedState,
  isCountryLoading,
  setFormFields,
  formFields,
}) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        setLoading(true);
        setStates([]);
        const response = await axios.post(
          "https://countriesnow.space/api/v0.1/countries/states",
          {
            country: selectedCountry,
          }
        );
        const { data } = response.data;
        const { states } = data;
        setStates(states);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCountry) {
      fetchStates();
    }
  }, [selectedCountry]);

  return (
    <Select
      id="state"
      name="state"
      className="w-full"
      value={formFields.state}
      loading={loading || isCountryLoading}
      showSearch
      placeholder="Select State"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(value) => {
        setSelectedState(value);
        setFormFields({
          ...formFields,
          state: value,
        });
      }}
    >
      <Option value="" disabled>
        Select State
      </Option>
      {states &&
        states.map((state) => (
          <Option key={state.state_code} value={state.name}>
            {state.name}
          </Option>
        ))}
    </Select>
  );
};

export default StateSelect;
