import React from "react";
import CompanyCard from "../components/CompanyCard";
function Combiner() {
  return (
    <div className="mt-10 ">
      <CompanyCard></CompanyCard>
    </div>
  );
}

export default Combiner;
