import React, { useEffect, useState } from "react";
import { Pagination, Spin, Input } from "antd";
import QuestionCard from "./QuestionCard";
import QuestionSolution from "./QuestionSolution";
import axios from "axios";

function InterviewMainComponent() {
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState("2001"); // State to store the selected question id
  const [loading, setLoading] = useState(true); // State for loading spinner
  const [searchText, setSearchText] = useState(""); // State to store the search text
  const pageSize = 7; // Number of items per page

  useEffect(() => {
    // Fetch questions data from the API
    axios
      .get(
        "https://web-backend-documents.vercel.app/api/v1/getinterviewquestions"
      )
      .then((response) => {
        setQuestions(response.data);
        setLoading(false); // Set loading to false when data is loaded
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false on error
      });
  }, []);

  // Calculate the range of questions to display on the current page
  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;

  // Filter questions based on the search text
  const filteredQuestions = questions.filter(
    (question) =>
      question.Company.toLowerCase().includes(searchText.toLowerCase()) ||
      question.Type.toLowerCase().includes(searchText.toLowerCase()) ||
      question.Question.toLowerCase().includes(searchText.toLowerCase())
  );

  const displayedQuestions = filteredQuestions.slice(startIdx, endIdx);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setSelectedQuestionIndex(page); // Reset selected question when changing the page
  };

  // const selectedQuestion = questions[selectedQuestionIndex];
  const selectedQuestion = questions.find(
    (question) => question.Question_ID === selectedQuestionIndex
  );
  const selectedQuestionSolution = selectedQuestion ? selectedQuestion : null;

  return (
    <>
      <div className="text-2xl font-bold text-left text-gray-600 mb-8">
        Product Manager Interview Questions
      </div>

      {loading ? (
        <div className="text-center mt-4">
          <Spin size="large" />
        </div>
      ) : (
        <div className="md:flex shadow-lg gap-10 ">
          <div className="md:w-2/5 pl-2 pt-2 pb-2 bg-white border">
            <div className="flex items-center gap-3 pr-1 border-b py-1">
              <Input
                placeholder="Search Questions"
                className="w-full pr-5"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {displayedQuestions.map((question, index) => (
              <QuestionCard
                key={index}
                question={question}
                setSelectedQuestionIndex={setSelectedQuestionIndex}
                isSelected={question.Question_ID === selectedQuestionIndex}
                index={index}
              />
            ))}
            <div className="mt-4 flex justify-end">
              <Pagination
                size="small"
                current={currentPage}
                total={filteredQuestions.length}
                pageSize={pageSize}
                onChange={onPageChange}
                showSizeChanger={false}
                showLessItems={true}
                pageSizeOptions={["1"]}
              />
            </div>
          </div>
          <div
            className="md:w-3/5 p-8 bg-white border"
            style={{ maxHeight: "900px", overflowY: "auto" }}
          >
            {selectedQuestionSolution && (
              <QuestionSolution
                selectedQuestionSolution={selectedQuestionSolution}
              ></QuestionSolution>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default InterviewMainComponent;
