import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "data_source",
  initialState: {
    jobWithCategory: [],
    companyList: [],
    filteredCompanyList: [],
    userJobList: [],
    jobsCategorys: [],
    jobsLocations: [],
    filterUserJobs: [],
    appliedJobs:[]
  },
  reducers: {
    setJobWithCategory(state, action) {
      state.jobWithCategory = action.payload;
    },
    setCompanyList(state, action) {
      state.companyList = action.payload;
    },
    setCompanyFilter(state, action) {
      state.filteredCompanyList = action.payload;
    },
    setUserJobList(state, action) {
      state.userJobList = action.payload;
    },
    setJobsCategorys(state, action) {
      state.jobsCategorys = action.payload;
    },
    setJobsLocations(state, action) {
      state.jobsLocations = action.payload;
    },
    setFilterUserJobs(state, action) {
      state.filterUserJobs = action.payload;
    },
    setAppliedJobs(state, action) {
      state.appliedJobs = action.payload;
    },
  },
});

export const {
  setJobWithCategory,
  setCompanyList,
  setCompanyFilter,
  setUserJobList,
  setJobsCategorys,
  setJobsLocations,
  setFilterUserJobs,
  setAppliedJobs
} = counterSlice.actions;
export default counterSlice.reducer;
