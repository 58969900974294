import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DateRange = ({ startYear, endYear }) => {
  const startDate = new Date(startYear);
  const endDate = new Date(endYear);

  // Get year and month names
  const startYearName = startDate.getFullYear();
  const endYearName = endDate.getFullYear();
  const startMonthName = startDate.toLocaleString("default", {
    month: "short",
  });
  const endMonthName = endDate.toLocaleString("default", { month: "short" });

  return (
    <p className="content">
      {`${startMonthName} ${startYearName} - ${endMonthName} ${endYearName}`}
    </p>
  );
};


const WorkExperience = ({ form }) => {
  return (
    <>
      {form.workExperience.length > 0 && (
        <Droppable droppableId="work-experience" type="WORK_EXPERIENCE">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <h2
                className="section-title mb-1 border-b-2 border-gray-300 editable"
                contentEditable
                suppressContentEditableWarning
                style={{ paddingBottom: "10px" }}
              >
                Work Experience
              </h2>
              {form.workExperience.map((item, index) => (
                <Draggable
                  key={`${item.company}-${index}`}
                  draggableId={`WORK_EXPERIENCE-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`mb-1 ${
                        snapshot.isDragging &&
                        "outline-dashed outline-2 outline-gray-400 bg-white"
                      }`}
                    >
                      <p className="content i-bold">{item.company}</p>
                      <p className="content">{item.position}</p>
                      <DateRange
                        startYear={item.startYear}
                        endYear={item.endYear}
                        id={`work-experience-start-end-date`}
                      />
                      <p className="content">{item.description}</p>
                      <Droppable
                        droppableId={`WORK_EXPERIENCE_KEY_ACHIEVEMENT-${index}`}
                        type="WORK_EXPERIENCE_KEY_ACHIEVEMENT"
                      >
                        {(provided) => (
                          <ul
                            className="list-disc ul-padding content"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {typeof item.keyAchievements === "string" &&
                              item.keyAchievements
                                .split("\n")
                                .map((achievement, subIndex) => (
                                  <Draggable
                                    key={`${item.company}-${index}-${subIndex}`}
                                    draggableId={`WORK_EXPERIENCE_KEY_ACHIEVEMENT-${index}-${subIndex}`}
                                    index={subIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`
                                            hover:outline-dashed hover:outline-2 hover:outline-gray-400
                                            ${
                                              snapshot.isDragging &&
                                              "outline-dashed outline-2 outline-gray-400 bg-white"
                                          }`}
                                          key={index} style={{ listStyle: "none" }}
                                      >
                                        ➤ {achievement}
                                      </li>
                                    )}
                                  </Draggable>
                                ))}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </>
  );
};

export default WorkExperience;
