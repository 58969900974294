import React from "react";
import TextField from "../../../../helper/textField/textField";
import TextArea from "../../../../helper/textArea/textarea";
import { Text, CustomGrid } from "../../../../helper/helper";
import { Divider } from "antd";
import FormButton from "./FormButton";

function WorkExperience({ form, setForm }) {
  const handleWorkExperience = (e, index) => {
    const newworkExperience = [...form.workExperience];
    newworkExperience[index][e.target.name] = e.target.value;
    setForm({ ...form, workExperience: newworkExperience });
  };

  const addWorkExperience = () => {
    setForm({
      ...form,
      workExperience: [
        ...form.workExperience,
        {
          company: "",
          position: "",
          description: "",
          keyAchievements: "",
          startYear: "",
          endYear: "",
        },
      ],
    });
  };

  const removeWorkExperience = (index) => {
    const newworkExperience = [...form.workExperience];
    newworkExperience[index] = newworkExperience[newworkExperience.length - 1];
    newworkExperience.pop();
    setForm({ ...form, workExperience: newworkExperience });
  };

  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">WorkExperience</Text>
      </div>
      <CustomGrid size={1}>
        {form.workExperience.map((workExperience, index) => (
          <>
            <CustomGrid size={3}>
              <TextField
                type="text"
                placeholder="Company"
                name="company"
                value={workExperience.company}
                onChange={(e) => handleWorkExperience(e, index)}
              />
              <TextField
                type="text"
                placeholder="Job Title, Location"
                name="position"
                className="w-full other-input"
                value={workExperience.position}
                onChange={(e) => handleWorkExperience(e, index)}
              />
              <CustomGrid size={2}>
                <TextField
                  type="date"
                  placeholder="Start Year"
                  name="startYear"
                  className="other-input"
                  value={workExperience.startYear}
                  onChange={(e) => handleWorkExperience(e, index)}
                />
                <TextField
                  type="date"
                  placeholder="End Year"
                  name="endYear"
                  className="other-input"
                  value={workExperience.endYear}
                  onChange={(e) => handleWorkExperience(e, index)}
                />
              </CustomGrid>
            </CustomGrid>
            <TextArea
              placeholder="Description"
              name="description"
              value={workExperience.description}
              onChange={({ target }) => {
                const newworkExperience = [...form.workExperience];
                newworkExperience[index][target.name] = target.value;
                setForm({ ...form, workExperience: newworkExperience });
              }}
            />
            <TextArea
              type="text"
              placeholder="Key Achievements Line By Line"
              name="keyAchievements"
              className="w-full other-input h-40"
              value={workExperience.keyAchievements}
              onChange={({ target }) => {
                const newworkExperience = [...form.workExperience];
                newworkExperience[index][target.name] = target.value;
                setForm({ ...form, workExperience: newworkExperience });
              }}
            />
            {form.workExperience.length > 1 &&
              index !== form.workExperience.length - 1 && (
                <Divider className="border-black border-t-1" dashed />
              )}
          </>
        ))}
      </CustomGrid>
      <FormButton
        size={form.workExperience.length}
        add={addWorkExperience}
        remove={removeWorkExperience}
      />
    </div>
  );
}

export default WorkExperience;
