import React from "react";
import TextField from "../../../../helper/textField/textField";
import TextArea from "../../../../helper/textArea/textarea";
import { Text, CustomGrid } from "../../../../helper/helper";
import { Divider } from "antd";
import FormButton from "./FormButton";
function Project({ form, setForm }) {
  const handleProjects = (e, index) => {
    const newProjects = [...form.projects];
    newProjects[index][e.target.name] = e.target.value;
    setForm({ ...form, projects: newProjects });
  };

  const addProjects = () => {
    setForm({
      ...form,
      projects: [
        ...form.projects,
        {
          title: "",
          link: "",
          description: "",
          keyAchievements: "",
          startYear: "",
          endYear: "",
        },
      ],
    });
  };

  const removeProjects = (index) => {
    const newProjects = [...form.projects];
    newProjects[index] = newProjects[newProjects.length - 1];
    newProjects.pop();
    setForm({ ...form, projects: newProjects });
  };
  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">Projects</Text>
      </div>
      <CustomGrid size={1}>
        {form.projects.map((project, index) => (
          <>
            <CustomGrid size={3}>
              <TextField
                type="text"
                placeholder="Project Name"
                name="title"
                value={project.title}
                onChange={(e) => handleProjects(e, index)}
              />
              <TextField
                type="text"
                placeholder="Link"
                name="link"
                value={project.link}
                onChange={(e) => handleProjects(e, index)}
              />
              <CustomGrid size={2}>
                <TextField
                  type="date"
                  placeholder="Start Year"
                  name="startYear"
                  value={project.startYear}
                  onChange={(e) => handleProjects(e, index)}
                />
                <TextField
                  type="date"
                  placeholder="End Year"
                  name="endYear"
                  value={project.endYear}
                  onChange={(e) => handleProjects(e, index)}
                />
              </CustomGrid>
            </CustomGrid>

            <TextArea
              placeholder="Description"
              name="description"
              value={project.description}
              maxLength="250"
              onChange={({ target }) => {
                const newProjects = [...form.projects];
                newProjects[index][target.name] = target.value;
                setForm({ ...form, projects: newProjects });
              }}
            />
            <TextArea
              placeholder="Key Achievements"
              name="keyAchievements"
              value={project.keyAchievements}
              onChange={({ target }) => {
                const newProjects = [...form.projects];
                newProjects[index][target.name] = target.value;
                setForm({ ...form, projects: newProjects });
              }}
            />
          </>
        ))}
      </CustomGrid>
      <FormButton
        size={form.projects.length}
        add={addProjects}
        remove={removeProjects}
      />
    </div>
  );
}

export default Project;
