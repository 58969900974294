import React, { useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useHistory } from "react-router-dom";
function SearchButton() {
  const user = useUser();
  const history = useHistory();



  const handleSearch = () => {
    if (user.isSignedIn) {
      // If the user is signed in, redirect to the user's home page
      history.push("/user/home");
    } else {
      // If the user is not signed in, redirect to the sign-in page
      history.push("/sign-in");
    }
  };
  return (
    <div className="py-10">
      <div className="ml-4 text-lg md:ml-3 md:text-xl font-semibold text-gray-600">
        Search Jobs
      </div>
      <div className="mb-6 relative px-2 md:px-2 mt-3 ">
        <div className="absolute left-6 top-1/2 transform -translate-y-1/2">
          <img
            src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec6eaba458a8beafe960a21_search-icon.svg"
            alt="Search Icon"
            className="h-6 w-6"
          />
        </div>
        <input
          type="text"
          className="w-full md:h-12 h-12 pl-12 md:pl-15 md:pr-20  md:py-8  shadow rounded-xl  border border-white hover:border-[#153cf5] hover:border-1  text-2xl font-semibold  hover:shadow transition-all duration-600 hover:-translate-y-.1 p-0.5"
          placeholder="Search for jobs"
          onChange={handleSearch}
        />
      </div>
    </div>
  );
}

export default SearchButton;
