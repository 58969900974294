import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LeftContent from "./left/LeftContent";
import RightContent from "./right/RightContent";
function ViewJobCard({jobContent}) {
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  return (
    <div className="pt-8">
      <div
        className={`px-6 flex gap-2 items-center transition-opacity transition-transform duration-300  ${
          hovered ? "text-gray-800" : "text-blue-600"
        }`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => {
          history.push("/jobs");
        }}
      >
        <svg
          className={` w-5 h-5 transition-all  duration-600 transform -rotate-90 mt-1 ${
            hovered ? "-translate-x-2" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          />
        </svg>
        <span className="text-lg mt-0.5">Back to jobs</span>
      </div>
      <div className="md:px-8 py-5 animate-fade-in">
        <div className="md:flex pb-10 md:gap-6">
          <div className="w-full md:w-5/6">
            <LeftContent job={jobContent}></LeftContent> 
          </div>
          <div className="w-full md:w-2/6 hidden md:block">
            <RightContent job={jobContent}></RightContent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewJobCard;


