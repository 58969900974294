import React, { useState, useEffect } from "react";
import Cover_Letter_File_Selector from "./resume_component/Cover_Letter_File_Selector";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import { message } from "antd";

function CoverLetter() {
  const { user } = useClerk();
  const [selectedFileCoverLetter, setSelectedFileCoverLetter] = useState(null);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [selectedFileFullName, setSelectedFileFullName] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://web-backend-user-profile-v2.onrender.com/api/v1/users/coverletter?username=${user.primaryEmailAddress.emailAddress}`
      );

      if (response.data.pdfFile) {
        setSelectedFileFullName(response.data.pdfFile);
        setSelectedFileCoverLetter({
          name: user.username+"_cover_letter"
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleSubmitButton = () => {
    const requestData = new FormData();
    requestData.append("file", selectedFileCoverLetter);
    requestData.append("username", user.primaryEmailAddress.emailAddress);
    setIsLoadingSave(true);
    fetch("https://web-backend-user-profile-v2.onrender.com/api/v1/saveUserCoverLetter", {
      method: "POST",
      body: requestData,
    })
      .then((response) => response.json())
      .then((data) => {
        message.success("Cover Letter uploaded successfully!");
        fetchData();
        setIsLoadingSave(false);
      })
      .catch((error) => {
        message.error("Failed to upload cover letter");
        console.log("cover lette",error)
        setIsLoadingSave(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="border-b border-gray-900/10 pb-5" key="cover-letter">
      <div className="space-y-12 mt-10 ">
        <div className="grid grid-cols-1 gap-x-8 gap-y-5 md:grid-cols-3">
          <div className="mt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Upload your cover letter
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Upload your cover letter File types: PDF
            </p>
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-6">
              <div className="flex items-center justify-center w-full">
                <Cover_Letter_File_Selector
                  selectedFileCoverLetter={selectedFileCoverLetter}
                  setSelectedFileCoverLetter={setSelectedFileCoverLetter}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 ">
          {selectedFileFullName !== "" && (
            <a href={selectedFileFullName} target="_blank">
              <button
                type="submit"
                className="rounded-md bg-white border border-indigo-600 px-6 py-2 text-sm font-semibold text-indigo-600 hover:bg-white-600 hover:text-text-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                // onClick={handleSubmitButton}
              >
                {/* {isLoadingSave ? "Saving..." : "Save"} */}
                View
              </button>
            </a>
          )}

          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmitButton}
          >
            {isLoadingSave ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CoverLetter;
