import React, { useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useHistory } from "react-router-dom";
export default function HeroSection() {
  const [hovered, setHovered] = useState(false);
  const user = useUser();
  const history = useHistory();

  const handleMouseMove = (e) => {
    const image = e.target;
    const rect = image.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const translateX = (x - rect.width / 2) * 0.05;
    const translateY = (y - rect.height / 2) * 0.05;

    image.style.transform = `translate(${translateX}px, ${translateY}px)`;
  };
  const handleMouseLeave = (e) => {
    const image = e.target;
    image.style.transform = "translate(0px, 0px)";
  };

  const handleSearch = () => {
    if (user.isSignedIn) {
      // If the user is signed in, redirect to the user's home page
      history.push("/user/home");
    } else {
      // If the user is not signed in, redirect to the sign-in page
      history.push("/sign-in");
    }
  };

  return (
    <div>
      <div
        className="-left-40 hidden top-10 md:block  mx-auto   lg:max-w-7xl lg:px-8 "
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5eceedac48ac2e65dfce42b9_circle-background-05-home-jobs-template.svg"
          className={`absolute md:-ml-3 z-10 md:top-40 md:left-60 ${
            hovered ? "filter brightness-100" : ""
          } w-10 max-h-10 object-contain transition-transform duration-300 ease-in-out`}
          alt="Background"
        />
        <div className="">
          <img
            src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5eceedac48ac2e65dfce42b9_circle-background-05-home-jobs-template.svg"
            className={`absolute mt-3 top-20 -left-14 ${
              hovered ? "filter brightness-100" : ""
            } w-80 max-h-100 object-contain transition-transform duration-300 ease-in-out`}
            alt="Background"
          />
        </div>

        <div className="mt-2">
          <img
            src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5eceedac48ac2e65dfce42b9_circle-background-05-home-jobs-template.svg"
            className={`absolute z-10 md:top-80 md:-left-10 ${
              hovered ? "filter brightness-100" : ""
            } w-60 max-h-40 mt-3 object-contain transition-transform duration-300 ease-in-out`}
            alt="Background"
          />
        </div>
        <img
          src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5eceedac48ac2e65dfce42b9_circle-background-05-home-jobs-template.svg"
          className={`absolute z-10 md:top-80 md:right-2 ${
            hovered ? "filter brightness-100" : ""
          } w-70 max-h-80 object-contain transition-transform duration-300 ease-in-out `}
          alt="Background"
        />
      </div>
      <div className="bg-[#fafbff] py-10  md:flex items-center justify-center text-center mt-20 animate-fade-in">
        <div className="mt-10 mx-3 md:mx-10 mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-8">
          <div className="md:mx-20 text-black text-3xl md:text-5xl font-bold mb-4 leading-8">
            <p className="mb-4">
              <span className="text-[#153cf5] underline">
                Ultimate Platform
              </span>{" "}
              for Product
            </p>{" "}
            Management Success.
          </div>

          {/* Second Row: Description */}
          <div className="md:mx-20 text-black text-lg mb-6 mt-10">
            <div>
              Are you a Product Manager looking to supercharge your career
            </div>
            <div>
              and gain a competitive edge?
            </div>
            {/* <div>
              is your one-stop destination for all things product management.
            </div> */}
          </div>

          {/* Third Row: Search Bar */}
          <div className="mb-6 relative md:mx-45 md:ml-20 md:mr-20 mt-10 ">
            <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
              <img
                src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec6eaba458a8beafe960a21_search-icon.svg"
                alt="Search Icon"
                className="h-6 w-6"
              />
            </div>
            <input
              type="text"
              className="w-full md:h-12 h-12 pl-12 md:pl-14 md:pr-20  md:py-10 shadow rounded  border border-white hover:border-[#153cf5] hover:border-1  text-2xl font-semibold  hover:shadow transition-all duration-600 hover:-translate-y-.1 p-0.5"
              placeholder="Search for jobs"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
              onChange={handleSearch}
            />

            <button
              className="hidden md:block absolute h-7 md:h-12 px-3 right-3 top-1/2 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-6  font-bold text-lg rounded"
              // style={{
              //   boxShadow:
              //     "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              // }}
            >
              SEARCH JOB
            </button>
          </div>

          {/* Fourth Row: Company Logos */}
          <div className="flex items-center justify-center mt-7">
            <div className="md:flex items-center justify-between gap-7">
              <img
                src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec7175d7e0c401a3e668a1d_facebook-logo.svg"
                alt="facebook"
                className="w-30 h-30 object-contain mt-2"
              />
              <img
                src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec7175d68c9b0a57ed94925_google-logo.svg"
                alt="google"
                className="w-30 h-30 object-contain mt-5"
              />
              <img
                src="https://assets.website-files.com/5ec5d86528da2f24250d634c/601e13bc333df3521cce5b6a_youtube-logo-jobs-webflow-template.svg"
                alt="youtube"
                className="w-30 h-30 object-contain mt-5"
              />
              <img
                src="https://assets.website-files.com/5ec5d86528da2f24250d634c/601e13bc774d5a00bcbb0baf_linkedin-logo-jobs-webflow-template.svg"
                alt="linkedin"
                className="w-30 h-30 object-contain mt-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
