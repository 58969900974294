import React from "react";
import ResumeBuilder from "../component/ResumeBuilder";
function ResumeBuilderContainer() {
  return (
    <div>
      <ResumeBuilder  ></ResumeBuilder>
    </div>
  );
}

export default ResumeBuilderContainer;
