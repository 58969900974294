import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin } from "antd";
import { useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import Card from "./Card";

function LeftContent() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const user = useUser();

  useEffect(() => {
    // Fetch data using Axios
    axios
      .get("https://jobs-backend-web-v2.vercel.app/api/v1/getjobs")
      .then((response) => {
        setData(response.data); // Store data in state
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="md:px-10 px-5">
        {loading ? ( // Show Spin loader while data is being fetched
          <div className="flex justify-center items-center h-full">
            {" "}
            <Spin size="large" />
          </div>
        ) : (
          // Render the top 10 records using the Card component
          data.slice(0, 10).map((job) => <Card key={job.id} job={job} />)
        )}
      </div>
      {!loading && (
        <div className="ml-20">
          <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
            <button
              className="h-9 md:h-16 px-4 md:left-10 md:mt-20 mt-10 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-8  font-bold text-lg rounded"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
            >
              BROWSE ALL JOBS
            </button>
          </Link>
        </div>
      )}
    </>
  );
}

export default LeftContent;
