import React from "react";
import HeaderCard from "./HeaderCard";
function LeftContent({ job }) {
  const jobUrl = job && job.job_url;
  const handleApplyEasyJobs = () => {
    window.open(`${jobUrl}`, "_blank");
  };
  return (
    <div
      className="bg-white  rounded-2xl transition-all duration-600  border  text-gray-600 cursor-pointer p-3 md:p-1 mt-5"
      style={{
        boxShadow:
          "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
      }}
    >
      <HeaderCard job={job}></HeaderCard>
      <hr className="my-6 border-t border-gray-300 w-full" />


      <h3
        className={`font-semibold ${"text-black "} line-clamp-1 overflow-hidden whitespace-normal
       md:text-3xl text-xl mt-3 md:mt-0 md:px-10 md:py-5`}
      >
        Job description
      </h3>
      <div
        className="text-sm mb-2 mt-2 md:mx-10 mx-2 text-xl leading-10 text-gray-600"
        dangerouslySetInnerHTML={{
          __html: job?.job_description_html,
        }}
      />

      <button
        className="w-full md:w-auto h-9 md:h-14 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-10 font-bold text-lg rounded mt-5 md:mt-10 md:ml-9"
        style={{
          boxShadow:
            "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
        }}
          onClick={handleApplyEasyJobs}
      >
        APPLY NOW
      </button>
    </div>
  );
}

export default LeftContent;
