import React, { useState } from "react";
import RenderIf from "../../../utilities/RenderIf";
import CompanyTabs from "./CompanyTabs";
import CompanyJobs from "../companyjobs/CompanyJobs";
import MobileCompanyCard from "./MobileCompanyCard";
function CompanyCard({ companyData }) {
  const companyName =
    companyData &&
    companyData.company_name.charAt(0).toUpperCase() +
      companyData.company_name.slice(1);
  const companyLogo = companyData && companyData.company_logo;
  const companySize = companyData && companyData.company_size;
  const companyType = companyData && companyData.primary_industry;
  const companyWebsite = companyData && companyData.company_webpage_link;

  const companyDetails = companyData && companyData.about_description_html;

  const handleApplyEasyJobs = () => {
    window.open(companyWebsite, "_blank");
  };

  const [hovered, setHovered] = useState(false);
  const [currentTab, setCurrentTab] = useState("Company Jobs");
  return (
    <div
      className="md:flex-row gap-4 md:m-5 md:items-center bg-white rounded-lg transition-all duration-600 rounded-xl border border-1.5 text-gray-600"
      style={{
        boxShadow:
          "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
      }}
    >
      {/* First Row */}
      <div className="w-full h-40 bg-blue-600 rounded-tl-xl rounded-tr-xl"></div>

      {/* In Dekstop Screen Company View */}
      {window.innerWidth > 768 && (
        <div className="md:flex md:items-center mt-6 -ml-5 md:m-2">
          <div className="w-40 h-50 border bg-white p-1 md:max-h-[140px] md:max-w-[140px] relative flex-shrink-0 md:-mt-14 ml-16 rounded-3xl">
            <img
              src={companyLogo}
              alt="Company Logo"
              className="w-full h-full object-contain rounded-3xl p-1"
            />
          </div>
          <div className="ml-20 md:ml-8 ">
            <span className="text-3xl text-black font-semibold">
              {companyName}
            </span>
            <div className="md:flex gap-4 mt-3" style={{ marginLeft: "-5px" }}>
              {/* First Icon and Label */}
              <div className="flex items-center text-gray-500 ">
                <div className="w-6 h-6">
                  <img
                    src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60c7dc51c1dfba2485657961_icon-3-job-categories-job-board-x-template.svg"
                    alt="location logo"
                    className="w-full h-full object-contain p-1"
                  />
                </div>
                <div>{companyType}</div>
              </div>

              <div className="flex items-center text-gray-500 ">
                <div className="w-6 h-6">
                  <img
                    src="https://assets.website-files.com/60c77302fcfa2b84ab595f64/60c92f6aa77b760b050020b7_icon-2-company-about-job-board-x-template.svg"
                    alt="company size logo"
                    className="w-full h-full object-contain rounded-3xl p-1"
                  />
                </div>
                <div>{companySize}</div>
              </div>

              <div
                className="flex ml-1  gap-1 items-center md:ml-60 md:pl-20 cursor-pointer"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleApplyEasyJobs}
              >
                <div className="text-blue-600 capitalize underline font-semibold">
                  View Website
                </div>
                <svg
                  className={`text-blue-600 w-4 h-4 transition-all duration-600 transform rotate-45 mt-1 ${
                    hovered ? "translate-x-1 -translate-y-1" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 10l7-7m0 0l7 7m-7-7v18"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* In Mobile Screen Company View */}
      {window.innerWidth < 768 && (
        <div className="p-2">
          <MobileCompanyCard companyData={companyData}></MobileCompanyCard>
        </div>
      )}

      {/* componets fot tabs and company jobs */}
      <div className="px-2 md:px-20 py-5">
        <div className="mt-10 pb-10">
          <CompanyTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          ></CompanyTabs>
        </div>
        <div>
          <RenderIf condition={currentTab === "Company Jobs"}>
            <CompanyJobs companyData={companyData}></CompanyJobs>
          </RenderIf>
          <RenderIf condition={currentTab === "About The Company"}>
            <div
              className="bg-white rounded-lg transition-all duration-600 rounded-xl border border-1.5 text-gray-600 p-5"
              dangerouslySetInnerHTML={{ __html: companyDetails }}
            />
          </RenderIf>
        </div>
      </div>
    </div>
  );
}

export default CompanyCard;
