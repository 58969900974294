import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Page,
  Document,
  View,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import html2canvas from "html2canvas";

import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/20/solid";

import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import { message } from "antd";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setCoverLetterData } from "../../../../features/coverletter/coverLetter";
import Templeate1 from "../templates/template1/Template1";
import Templeate2 from "../templates/template2/Template2";
import {
  CustomButton,
  CustomModalFooterHangging,
} from "../../../../helper/helper";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
    // padding: 3,
  },
});

const tabs = [
  { name: "Template1", href: "", icon: ClipboardDocumentCheckIcon },
  { name: "Template2", href: "", icon: ClipboardDocumentListIcon },
];

function MianCoverLetterComponent() {
  const dispatch = useDispatch();
  const coverLetterData = useSelector(
    (state) => state.coverLetter.coverLetterData
  );

  const { user } = useClerk();
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Template1");
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGenerateAndDownloadPDF = async () => {
    const canvas = await html2canvas(
      document.querySelector("#pdf-coverletter-content"),
      {
        scale: 2,
      }
    );
    const imgData = canvas.toDataURL("image/png");

    const pdfBlob = await pdf(
      <Document>
        <Page>
          <View style={styles.page}>
            <Image src={imgData} />
          </View>
        </Page>
      </Document>
    ).toBlob();
    // setPdfData(URL.createObjectURL(pdfBlob));

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = "output.pdf";
    downloadLink.click();
  };

  return (
    <div>
      <div className="flex items-start justify-between ">
        <p className="text-xl md:text-3xl font-bold resume-header mt-5">
          Cover Letter Builder
        </p>

        <div className="flex items-center justify-end gap-x-4 mb-12 lg:mb-12">
          <CustomButton onClick={togglePreview} className="primary">
            {showPreview ? "Edit Info" : "Preview"}
          </CustomButton>
        </div>
      </div>

      {Object.keys(coverLetterData).length ? (
        <>
          {showPreview ? (
            <>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <button
                        key={tab.name}
                        to={tab.href}
                        className={`${
                          tab.name === selectedTab
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        } group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium`}
                        aria-current={
                          tab.name === selectedTab ? "page" : undefined
                        }
                        onClick={() => handleTabClick(tab.name)}
                      >
                        <tab.icon
                          className={`${
                            tab.name === selectedTab
                              ? "text-indigo-500"
                              : "text-gray-400 group-hover:text-gray-500"
                          } -ml-0.5 mr-2 h-5 w-5`}
                          aria-hidden="true"
                        />
                        <span>{tab.name}</span>
                      </button>
                    ))}
                  </nav>
                </div>
              </div>

              <div className="mt-12">
                <div
                  id="pdf-content"
                  className="bg-white shadow-md rounded-md p-4 mb-20 border mx-auto"
                  ref={componentRef}
                >
                  {selectedTab === "Template1" ? (
                    <Templeate1 />
                  ) : (
                    <Templeate2 />
                  )}
                </div>
              </div>
            </>
          ) : (
            coverLetterData.closingParagraph
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

export default MianCoverLetterComponent;

{
  /* <MainForm form={form} setForm={setForm} /> */
}
