import React from "react";
import MainComponent from "../components/MainComponent";
function MainContainer() {
  return (
    <div>
      <MainComponent></MainComponent>
    </div>
  );
}

export default MainContainer;
