import React from "react";
import { Input } from "antd";
import "./textfield.css";
import _ from "lodash";
import { Text } from "../helper";

const TextField = (props) => {
  return (
    <div>
      {props.label && (
        <div>
          <Text>{props.label}</Text>
        </div>
      )}
      <Input {...props} className="custom-textfield" size="large" />
    </div>
  );
};

export default TextField;
