import React from "react";
import TextField from "../../../../helper/textField/textField";

import { Text, CustomGrid } from "../../../../helper/helper";
import { Divider } from "antd";
import FormButton from "./FormButton";

function Skill({ length, index, title, form, setForm }) {
  const handleSkill = (e, index, title) => {
    const newSkills = [
      ...form.skills.find((skillType) => skillType.title === title).skills,
    ];
    newSkills[index] = e.target.value;
    setForm((prevData) => ({
      ...prevData,
      skills: prevData.skills.map((skill) =>
        skill.title === title ? { ...skill, skills: newSkills } : skill
      ),
    }));
  };

  const addSkill = (title) => {
    setForm((prevData) => {
      const skillType = prevData.skills.find(
        (skillType) => skillType.title === title
      );
      const newSkills = [...skillType.skills, ""];
      const updatedSkills = prevData.skills.map((skill) =>
        skill.title === title ? { ...skill, skills: newSkills } : skill
      );
      return {
        ...prevData,
        skills: updatedSkills,
      };
    });
  };

  const removeSkill = (title, index) => {
    setForm((prevData) => {
      const skillType = prevData.skills.find(
        (skillType) => skillType.title === title
      );
      const newSkills = [...skillType.skills];
      newSkills.pop();
      const updatedSkills = prevData.skills.map((skill) =>
        skill.title === title ? { ...skill, skills: newSkills } : skill
      );
      return {
        ...prevData,
        skills: updatedSkills,
      };
    });
  };

  const skillType = form.skills.find((skillType) => skillType.title === title);
  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">{title}</Text>
      </div>
      <CustomGrid size={3}>
        {skillType.skills.map((skill, index) => (
          <div key={index} className="f-col">
            <TextField
              type="text"
              placeholder={title}
              name={title}
              value={skill}
              onChange={(e) => handleSkill(e, index, title)}
            />
          </div>
        ))}
      </CustomGrid>
      <FormButton
        size={skillType.skills.length}
        add={() => addSkill(title)}
        remove={() => removeSkill(title)}
      />
      {/* {length > 1 && index !== length - 1 && (
        <Divider className="border-black border-t-1" dashed />
      )} */}
    </div>
  );
}

export default Skill;
