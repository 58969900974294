import React, { useState, useEffect } from "react";
import Mian_Culture_Component from "./culture_component/Mian_Culture_Component";
import axios from "axios";
import { useClerk } from "@clerk/clerk-react";
import { message } from "antd";
function Culture({ steps, setSteps }) {
  const { user } = useClerk();
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [formFields, setFormFields] = useState({});
  const handleSubmitButton = async () => {
    const mandatoryFields = ["about_description"];
    const missingFields = mandatoryFields.filter((field) => !formFields[field]);

    if (missingFields.length > 0) {
      const errorMessage = `Please fill the required fields`;
      message.error(errorMessage);
      return;
    }
    try {
      setIsLoadingSave(true);
      const response = await axios.post(
        "https://web-backend-user-profile-v2.onrender.com/api/v1/saveUserCulture",
        {
          username: user.primaryEmailAddress.emailAddress,
          about_description: formFields.about_description,
          next_five_years: formFields.next_five_years,
        }
      );

      setIsLoadingSave(false);
      message.success(`${user.username} culture info saved successfully!`);
      const updatedStep = {
        id: "Step 4",
        name: "Culture",
        href: "#",
        status: "complete",
      };

      setSteps((prevSteps) => {
        // Find the index of the step with the same id as the updatedStep
        const index = prevSteps.findIndex((step) => step.id === updatedStep.id);
      
        // If the step with the same id is found, update it; otherwise, add the updatedStep as a new step
        if (index !== -1) {
          return prevSteps.map((step, idx) => (idx === index ? updatedStep : step));
        } else {
          return [...prevSteps, updatedStep];
        }
      });
    } catch (error) {
      setIsLoadingSave(false);
      message.error("Failed to save culture info.");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://web-backend-user-profile-v2.onrender.com/api/v1/users/cultureinfo?username=${user.primaryEmailAddress.emailAddress}`
        );
        setFormFields(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Mian_Culture_Component
        setFormFields={setFormFields}
        formFields={formFields}
      ></Mian_Culture_Component>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmitButton}
        >
          {isLoadingSave ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default Culture;
