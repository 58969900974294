import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { CustomButton } from "../../../../helper/helper";
const FormButton = ({ size, remove, add }) => {
  return (
    <div className="flex flex-col md:flex-row md:justify-end gap-2">
      {size > 0 && (
        <CustomButton className="secondary" onClick={remove}>
          Remove
        </CustomButton>
      )}

      <CustomButton className="primary2" onClick={add}>
        Add
      </CustomButton>
    </div>
  );
};

export default FormButton;
