const Certification = ({ title, certifications }) => {
  return (
    certifications.length > 0 && (
      <div>
        <h2
          className="section-title mb-1 border-b-2 border-gray-300"
          style={{ paddingBottom: "10px" }}
        >
          {title}
        </h2>
        <ul className="sub-content">
          {certifications.map((certification, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              ➤ {certification}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default Certification;
