import React from "react";
import { Link } from "react-router-dom";

function Card({ image, label, value, color,link }) {
  return (
    <Link to={link}>
      <div className="bg-white shadow-lg rounded-lg flex px-4 gap-6 py-4 cursor-pointer">
        <div
          className={`flex w-12 h-12  justify-center items-center bg-[${color}] rounded-xl `}
        >
          <img src={image} alt="Company Logo" className="object-contain p-2 " />
        </div>

        <div className="flex flex-col">
          <h2 className="text-xs font-semibold text-gray-600">{label}</h2>

          <div className="flex gap-2 items-center justify-center">
            <p className="text-gray-900 text-2xl font-semibold">{value}</p>
            <div className="h-5 px-2 mt-[3px] font-semibold flex  text-[#01dda5] rounded-full bg-white border-[1.5px] border-[#01dda533] text-xs">
              +5%
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Card;
