import React, { useState, useEffect } from "react";
import { Input, Select, Switch, Button } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { CheckCircleIcon, clearFilters } from "@heroicons/react/20/solid";
import JobCard from "./JobCard";

const { Option } = Select;

export default function JobSearch() {
  const [userJobList, setUserJobList] = useState([]);
  const [filterUserJobs, setFilterUserJobs] = useState([]);
  const [jobsCategorys, setJobsCategorys] = useState([]);
  const [jobsLocations, setJobsLocations] = useState([]);
  const [remoteJobsOnly, setRemoteJobsOnly] = useState(false);
  const [visaSponsored, setVisasponsored] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://user-jobs.onrender.com/api/v1/getUserJobs`)
      .then((response) => {
        setLoading(false);
        setUserJobList(response.data);
        setFilterUserJobs(response.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://user-jobs.onrender.com/api/v1/getJobsCategory`)
      .then((response) => {
        setCategoryLoading(false);
        setJobsCategorys(response.data);
      })
      .catch((error) => {
        setCategoryLoading(false);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://user-jobs.onrender.com/api/v1/getJobsLocation`)
      .then((response) => {
        setLocationLoading(false);
        setJobsLocations(response.data);
      })
      .catch((error) => {
        setLocationLoading(false);
        console.error(error);
      });
  }, []);

  const jobMatchesSearch = (job) => {
    const searchTerm = search.toLowerCase();
    return (
      job["company_name"]?.toLowerCase()?.includes(searchTerm) ||
      job["job_title"]?.toLowerCase()?.includes(searchTerm) ||
      job["job_location"]?.toString()?.toLowerCase()?.includes(searchTerm) ||
      job["salary"]?.toLowerCase()?.includes(searchTerm) ||
      job["country"]?.toLowerCase()?.includes(searchTerm) ||
      job["state"]?.toLowerCase()?.includes(searchTerm) ||
      job["job_status"]?.toLowerCase()?.includes(searchTerm)
    );
  };

  const handleRemoteJobsOnlyChange = (checked) => {
    setRemoteJobsOnly(checked);
  };
  const handleVisaSponcered = (checked) => {
    setVisasponsored(checked);
  };

  useEffect(() => {
    const filteredData = userJobList.filter((job) => {
      const categoryMatch =
        !selectedCategory || selectedCategory === job["job_category"];
      const locationMatch =
        selectedLocation.length === 0 ||
        (job.job_location && selectedLocation.includes(job.job_location));

      const remoteLocationMatch =
        remoteJobsOnly && job.job_location?.toLowerCase().includes("remote");

      // Check the value of visaSponsored directly when the switch is enabled
      const visaSponsoredMatch = visaSponsored && job.visa_sponsored;

      // Apply location filter based on remoteJobsOnly condition
      const applyLocationFilter = remoteJobsOnly
        ? remoteLocationMatch
        : locationMatch;
      const visaFilter = visaSponsored ? visaSponsoredMatch : userJobList;

      return (
        categoryMatch &&
        applyLocationFilter &&
        visaFilter &&
        jobMatchesSearch(job)
      );
    });

    setFilterUserJobs(filteredData);
  }, [
    userJobList,
    selectedCategory,
    selectedLocation,
    search,
    remoteJobsOnly,
    visaSponsored,
  ]);

  const handleTitleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions);
  };

  const handleLocationChange = (selectedOptions) => {
    setSelectedLocation(selectedOptions);
  };

  const clearFilters = () => {
    setSearch("");
    setSelectedCategory("");
    setSelectedLocation([]);
    setRemoteJobsOnly(false);
    setVisasponsored(false);
  };

  return (
    <>
      <div className="p-3 border bg-white md:mb-10">
        <div className="flex flex-col md:flex-row gap-3 px-4 py-3">
          <div className="md:w-1/2">
            <Input
              size="large"
              className="w-full"
              placeholder="Senior Product Manager"
              prefix={<SearchOutlined style={{ fontSize: "22px" }} />}
              onChange={handleTitleInputChange}
              value={search}
            />
          </div>
          <div className="md:w-1/2">
            <Select
              mode="multiple"
              maxTagCount={1}
              size="large"
              style={{ width: "100%" }}
              placeholder="Select Location...."
              loading={locationLoading}
              onChange={handleLocationChange}
              value={selectedLocation}
            >
              <Option key="default" value="" label="Select Location" disabled>
                <div className="non-selectable-option">Select Location</div>
              </Option>
              {jobsLocations.map((location) => (
                <Option key={`filter-${location.value}`} value={location.value}>
                  {location.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ">
          {/* 1st Column - Select Box */}
          <div className="w-full md:w-1/2 p-4 ">
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Select Experience..."
              loading={categoryLoading}
              onChange={handleCategoryChange}
              value={selectedCategory}
            >
              <Option key="default" value="" label="Select Experience" disabled>
                <div className="non-selectable-option">Select Experience</div>
              </Option>
              {jobsCategorys.map((category) => (
                <Option key={`filter-${category.value}`} value={category.value}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </div>

          {/* 2nd Column - Multi Select */}
          <div className="flex flex-col md:flex-row w-full md:w-1/2 md:p-4 md:items-center gap-3 ml-5 md:ml-0">
            <div>
              <Switch
                className="bg-gray-400 md:mr-2 mr-4"
                onChange={handleRemoteJobsOnlyChange}
                checked={remoteJobsOnly}
              ></Switch>
              Remote jobs only
            </div>
            <div>
              <Switch
                className="bg-gray-400 md:mr-2 mr-4"
                onChange={handleVisaSponcered}
                checked={visaSponsored}
              ></Switch>
              Visa Sponsored
            </div>
            <button
              type="button"
              className="inline-flex items-center gap-x-1 rounded-xs bg-[#e7ebf6] px-2 py-1 mr-10 md:mr-0 text-sm text-[#171e35] md:px-2.5 md:py-1.5 "
              onClick={clearFilters}
            >
              <CloseCircleOutlined
                className="-ml-0.5 h-4 w-4 md:h-5 md:w-5 pt-1"
                aria-hidden="true"
              />
              <span className="truncate">Clear Filters</span>
            </button>
          </div>

          {/* 3rd Column - Text */}
        </div>
      </div>
      <JobCard loading={loading} userJobList={filterUserJobs}></JobCard>
    </>
  );
}
