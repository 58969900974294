import React, { useState } from "react";

function Card({ job }) {
  const [hovered, setHovered] = useState(false);
  const companyLogo = job && job.company_logo;
  const companyName = job && job.company_name;
  const jobTitle = job && job.job_category;
  const jobLocation = job && job.job_location;
  const jobPostedDate = job && job.job_posted_date;
  const jobSalary = job && job.salary ? job.salary : "COMPETITIVE";

  let formattedDate = "";

  if (jobPostedDate) {
    const dateObject = new Date(jobPostedDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    formattedDate = dateObject.toLocaleDateString("en-US", options);
  }
  const jobRouteUrl = `/easyjob/${job.company_name}/${job.job_id}`;
  const handleApplyEasyJobs = () => {
    window.open(jobRouteUrl, "_blank");
  };
  return (
    <div
      className="bg-white  rounded-3xl transition-all duration-600 hover:-translate-y-1 border hover:border-blue-600 border-1.5 text-gray-600 cursor-pointer p-3 md:p-7 mt-5"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        boxShadow:
          "0 -2px 6px 0 rgba(5, 21, 46, 0.02), 0 6px 12px 0 rgba(21, 60, 245, 0.05), 0 2px 6px 0 rgba(5, 21, 46, 0.02)",
      }}
      onClick={handleApplyEasyJobs}
    >
      <div className="md:flex">
        {/* 1st Column - Company Logo */}
        <div
          className="flex w-20 h-20  md:max-w-[104px] md:w-[104px] max-h-[104px] md:h-[104px] mr-5 rounded-3xl overflow-hidden justify-center items-center "
          style={{
            boxShadow:
              "0 2px 6px 0 rgba(5, 21, 46, 0.06), 0 4px 8px 0 rgba(21, 60, 245, 0.04)",
          }}
        >
          <img
            src={companyLogo}
            alt="Company Logo"
            className="w-full h-full object-contain rounded-3xl border p-3 "
          />
        </div>

        {/* 2nd Column - Company Name, About, and View Button */}
        <div className="w-3/4 ml-1 mt-3 md:mt-0">
          <h3
            className={`font-semibold  line-clamp-1 overflow-hidden whitespace-normal
           text-xl text-[#153cf5] capitalize`}
          >
            {companyName}
          </h3>
          <h3
            className={`font-semibold ${
              hovered ? "text-[#153cf5]" : "text-black "
            } line-clamp-1 overflow-hidden whitespace-normal
           md:text-3xl text-xl mt-3 md:mt-0`}
          >
            {jobTitle}
          </h3>
          <div className="text-gray-600 mt-2 md:flex justify-between ">
            <div className="md:flex gap-1 py-3">
              <div className="p-1 rounded bg-[#f6f7fa] text-sm md:text-lg w-full text-gray-600  whitespace-nowrap">
                {jobLocation
                  ? jobLocation.split(/[/,]/)[0].toUpperCase()
                  : "REMOTE"}
              </div>

              <div className="flex mt-4 md:ml-2 md:mt-0 rounded-[18px]  justify-center items-center gap-1 w-full whitespace-nowrap">
                <img
                  src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec9969f9359c294993bc42e_salary-icon.svg"
                  alt="Company Logo"
                  className="md:w-6 md:h-6 w-4 h-4 object-contain"
                />
                <p className="text-sm md:text-lg text-gray-600 w-full">
                  {jobSalary ? jobSalary.split(/[/]/)[0] : jobSalary}
                </p>
              </div>
            </div>

            <div className="flex-none font-semibold md:ml-0 text-lg text-gray-600 md:py-4">
              {formattedDate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
