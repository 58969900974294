import React, { useState, useEffect } from "react";
import { Select, Input, Radio, Checkbox } from "antd";
import CountrySelect from "./CountrySelect";
import CompanySize from "./CompanySizes";
import axios from "axios";
const { Option } = Select;
function Main_Component({
  setFormFields,
  formFields,
  selectedMethods,
  setSelectedMethods,
}) {
  const [remoteIntrested, setRemoteIntrested] = useState(false);

  return (
    <div className="space-y-12 py-8 md:p-6 bg-white">
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Where are you in job search? <span className="text-red-500">*</span>
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Your current company will never see that you are looking for a job,
            no matter what you choose.
          </p>
        </div>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-4">
            <div className="mt-2">
              <Select
                id="where_are_you_in_job_search"
                name="where_are_you_in_job_search"
                autoComplete="where_are_you_in_job_search"
                className="w-full"
                defaultValue=""
                onChange={(value) => {
                  setFormFields({
                    ...formFields,
                    where_are_you_in_job_search: value,
                  });
                }}
                value={formFields.where_are_you_in_job_search}
              >
                <Option value="" disabled>
                  Select Preference
                </Option>
                <Option value="Actively looking">
                  <div className="flex items-center pl-1">
                    <div className="relative h-2 w-2">
                      <div className="absolute h-2 w-2 rounded-full bg-green-400 opacity-50 animate-pulse" />
                      <div className="absolute h-2 w-2 rounded-full bg-green-400 animate-ping" />
                    </div>
                    <span className="ml-2">Actively looking</span>
                  </div>
                </Option>
                <Option value="Passively looking">
                  <div className="flex items-center pl-1">
                    <div className="relative h-2 w-2">
                      <div className="absolute h-2 w-2 rounded-full bg-yellow-400 opacity-50 animate-pulse" />
                      <div className="absolute h-2 w-2 rounded-full bg-yellow-400 animate-ping" />
                    </div>
                    <span className="ml-2">Passively looking</span>
                  </div>
                </Option>
              </Select>
            </div>
            <p className="mt-3 text-sm leading-6 text-gray-600">
              Your job profile is visible to startups.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            US work authorization 🇺🇸 <span className="text-red-500">*</span>
          </h2>
        </div>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-4">
            <label
              htmlFor="sponcership"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Do you or will you require sponsorship for a US employment visa
              (e.g. H‑1B)?
            </label>
            <div className="mt-2">
              <Radio.Group
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    require_sponsorship_for_us: e.target.value,
                  });
                }}
                value={formFields.require_sponsorship_for_us}
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="sponcership"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Are you legally authorized to work in the United States?
            </label>
            <div className="mt-2">
              <Radio.Group
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    legally_authorized_to_work_in_us: e.target.value,
                  });
                }}
                value={formFields.legally_authorized_to_work_in_us}
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            What type of job are you interested in?{" "}
            <span className="text-red-500">*</span>
          </h2>
        </div>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-4">
            <div className="mt-2">
              <Select
                id="job_type_interested"
                name="job_type_interested"
                autoComplete="job_type_interested"
                className="w-full"
                value={formFields.job_type_interested}
                mode="multiple"
                onChange={(value) => {
                  setFormFields({
                    ...formFields,
                    job_type_interested: value,
                  });
                }}
                placeholder="Select job interests"
              >
                <Option value="Contractor">Contractor</Option>
                <Option value="Intern">Intern</Option>
                <Option value="Co-founder">Co-founder</Option>
                <Option value="Full-time Employee">Full-time Employee</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            What locations do you want to work in?{" "}
            <span className="text-red-500">*</span>
          </h2>
        </div>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-4">
            <div className="mt-2">
              <CountrySelect
                setFormFields={setFormFields}
                formFields={formFields}
              >
                {" "}
              </CountrySelect>
            </div>
            <div className="mt-4">
              <Checkbox
                onChange={(e) => {
                  setRemoteIntrested(e.target.checked);
                  setFormFields({
                    ...formFields,
                    open_to_work_remotely: e.target.checked,
                  });
                }}
                checked={formFields.open_to_work_remotely}
              >
                Open to working remotely
              </Checkbox>
            </div>
            <div className="mt-4">
              {formFields.open_to_work_remotely !== false && formFields.open_to_work_remotely !== 0 && (
                <Select
                  id="remoteWork"
                  name="remoteWork"
                  className="w-full"
                  placeholder="Select Remote Work Preference"
                  onChange={(value) => {
                    setFormFields({
                      ...formFields,
                      remote_work_preference: value,
                    });
                  }}
                  value={formFields.remote_work_preference}
                >
                  <Option value="">Select Remote Work Preference</Option>
                  <Option value="Onsite prefered">Onsite prefered</Option>
                  <Option value="Onsite or Remote">Onsite or Remote</Option>
                  <Option value="Remote Prefered">Remote Prefered</Option>
                  <Option value="Remote only">Remote only</Option>
                </Select>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Would you like to work at companies of these sizes?{" "}
            {/* <span className="text-red-500">*</span> */}
          </h2>
        </div>
        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div className="sm:col-span-4">
            <CompanySize
              setFormFields={setFormFields}
              formFields={formFields}
              selectedMethods={selectedMethods}
              setSelectedMethods={setSelectedMethods}
            ></CompanySize>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main_Component;
