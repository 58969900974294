import React from "react";
import DocumentMain from "../component/DocumentMain";
function DocumentContainer() {
  return (
    <div>
      <DocumentMain></DocumentMain>
    </div>
  );
}

export default DocumentContainer;
