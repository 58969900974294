import { Input } from "antd";
import React from "react";
import { handleEnter, Text } from "../helper";


import "./textarea.css";

const { TextArea } = Input;

const TextareaField = (props) => {
  return (
    <div className="textarea-container-main">
      {props.label && (
        <div>
          <Text>{props.label}</Text>
        </div>
      )}
      <TextArea
        {...props}
        autoSize={{ minRows: 3, maxRows: 5 }}
        onPressEnter={handleEnter}
      />
    </div>
  );
};

export default TextareaField;
