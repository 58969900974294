import React from 'react';
import SideBar from '../components/SideBarCustom';

const SideBarContainer = () => {
  return (
    <div>
      <SideBar></SideBar>
    </div>
  );
};

export default SideBarContainer;
