import React, { useState } from "react";

import CompanyCard from "./CompanyCard";
import { Link } from "react-router-dom";
const Feature2 = () => {
  const companyData = [
    {
      company_logo:
        "https://cdn-images.himalayas.app/k0n4ydpkwuo53em3ajcag72t1fnk",
      company_name: "Atlassian",
      about_company: "At Atlassian, we unite to achieve more. Join us.",
      company_link: "/easycompany/Atlassian/1435",
    },
    {
      company_logo:
        "https://cdn-images.himalayas.app/inqpu23lg3f7uknuv1nndpg0d6s9",
      company_name: "Paytm",
      about_company: "Paytm ignited India's digital shift, powering millions.",
      company_link: "/easycompany/Paytm/1429",
    },
    {
      company_logo:
        "https://cdn-images.himalayas.app/c62c54n3q202a0wba1b8gta2aq4p",
      company_name: "GitLab",
      about_company: "GitLab: All-in-one DevOps platform in one app.",
      company_link: "/easycompany/Gitlab/1491",
    },
    {
      company_logo:
        "https://cdn-images.himalayas.app/rdgicukp1npc83ictyc31i8xta9o",
      company_name: "Netflix",
      about_company: "Netflix: 195M+ members, endless content, no ads.",
      company_link: "/easycompany/Netflix/1426",
    },
  ];

  return (
    <div className="mx-auto lg:max-w-7xl">
      <div className="flex flex-col mt-20 mb-20 items-center justify-center text-center md:px-5 px-7">
        <div className="md:mx-20 mx-2 text-black text-xl md:text-4xl font-bold mb-4 leading-8">
          Discover great companies
          <p className="mb-4">
            already hiring in our{" "}
            <span className="text-[#153cf5] underline">job board</span>
          </p>{" "}
        </div>
        {/* Second Row: Description */}
        <div className="md:mx-20 mx-2 text-black text-lg py-3">
          <div>
            Our AI algorithms understand your skills, preferences, and career
            goals to provide
          </div>
          <div>
            you with personalized job recommendations that match your unique
            profile.
          </div>
        </div>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 py-5 md:px-5 px-7">
        {companyData.map((company, index) => (
          <CompanyCard
            key={index}
            companyLogo={company.company_logo}
            companyName={company.company_name}
            aboutCompany={company.about_company}
            companyLink={company.company_link}
          />
        ))}
      </div>
      <div className="flex justify-center items-center py-6 md:px-5 px-7">
        <Link to="/companys" className="w-full md:w-60">
          <button
            className="w-full h-9 md:h-14 mt-10 md:mt-0 md:right-10 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-8 font-bold text-lg rounded"
            style={{
              boxShadow:
                "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
            }}
          >
            VIEW COMPANIES
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Feature2;
