import React from "react";
import { Divider } from "antd";

function TextWithBoldColon({ text }) {
  // Use regular expression to find and format text following colons
  const formattedText = text.split("\n").map((paragraph, index) => {
    const parts = paragraph.split(":");
    if (parts.length === 2) {
      return (
        <p key={index} className="mt-4">
          <span className="font-semibold text-lg">{parts[0]}:</span>
          {parts[1]}
        </p>
      );
    } else {
      return (
        <p key={index} className="mt-4 text-gray-500">
          {paragraph}
        </p>
      );
    }
  });

  return <div>{formattedText}</div>;
}

function QuestionSolution({ selectedQuestionSolution }) {
  return (
    <div>
      <div>
        <p className="text-gray-800 text-2xl font-semibold py-1">
          {selectedQuestionSolution.Question}
        </p>
        {/* <p className="flex justify-end">
          - {selectedQuestionSolution.Type}
        </p> */}
      </div>
      <div class="font-semibold text-gray-400">{selectedQuestionSolution.Type}</div>

      <div className="w-full my-8" style={{ whiteSpace: "pre-line" }}>
        <TextWithBoldColon text={selectedQuestionSolution.solution} />
      </div>
    </div>
  );
}

export default QuestionSolution;
