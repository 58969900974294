import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

const navigation = [
  { name: "Home", href: "/", external: false },
  { name: "Jobs", href: "/jobs", external: false },
  { name: "Companies", href: "/companies", external: false },
  {
    name: "Blogs",
    href: "https://productmindset.substack.com/archive",
    external: true,
  },
  { name: "Pricing", href: "/pricing", external: false },
  {
    name: "Feature Request",
    href: "https://productmindset.kampsite.co/suggestions",
    external: true,
  },
];

export default function CustomNavbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useUser();
  const location = useLocation();

  return (
    <div className="bg-white ">
      <header
        className="absolute inset-x-0 top-0 z-50"
        style={{
          boxShadow: "0 2px 30px 0 rgba(12, 53, 115, 0.1)",
        }}
      >
        <nav
          className="flex items-center justify-between p-5  lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1 items-center gap-2">
            <Link to="/" className="-m-1.5 ">
              {/* <span className="sr-only">Your Company</span> */}
              <img
                className="h-14 w-auto"
                src="https://thinkproduct.org/wp-content/uploads/2023/08/3.png"
                alt=""
              />
            </Link>
            <h1 className="text-2xl bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent font-bold ">
              Product Mindset
            </h1>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.external ? "" : item.href}
                className={`text-lg  leading-6  ${
                  location.pathname === item.href
                    ? "text-[#153cf5] underline font-bold"
                    : "text-gray-900 font-semibold"
                }`}
                onClick={() => {
                  if (item.external) {
                    window.open(item.href, "_blank");
                  }
                }}
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-6">
            <Link
              to="/postjob"
              className="rounded-md bg-[#153cf5] hover:bg-[#153bf10] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
            >
              POST A JOB{" "}
              <PlusIcon
                className="h-4 w-4 inline-block mb-0.5 ml-2 text-white font-semibold"
                aria-hidden="true"
              />
            </Link>
            <Link
              to={user.isSignedIn ? "/user/home" : "/sign-in"}
              className="rounded-md text-lg font-semibold leading-6 text-gray-900 mt-2 border-gray-600"
            >
              {user.isSignedIn ? "User Page →" : "Sign in →"}
            </Link>
          </div>
        </nav>
        {/* <hr class="mx-8 border-b-1 border-gray-300 " /> */}

        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                {/* <span className="sr-only">Your Company</span> */}
                <img
                  className="h-14 w-auto"
                  src="https://thinkproduct.org/wp-content/uploads/2023/08/3.png"
                  alt=""
                />
              </Link>
              <h1 className="text-2xl bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent font-bold ">
                Product Mindset
              </h1>

              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.external ? "" : item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={() => {
                        if (item.external) {
                          window.open(item.href, "_blank");
                        }
                        setMobileMenuOpen(false);
                      }}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>

                <div className="py-6">
                  <Link
                    to="/postjob"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Post A Job
                  </Link>
                  <Link
                    to={user.isSignedIn ? "/user/home" : "/sign-in"}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {user.isSignedIn ? "User Page →" : "Sign in →"}
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
