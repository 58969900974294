import React from "react";
import { Link } from "react-router-dom";
function CompanyCard({ companyLogo, companyName, aboutCompany, companyLink }) {
  return (
    <Link to={companyLink}>
      <div
        className="bg-white  p-6 shadow-md transition-all duration-300 hover:shadow-lg hover:-translate-y-1 border mb-4 lg:px-5 cursor-pointer rounded-3xl"
        style={{
          boxShadow:
            "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
        }}
      >
        <div className="mb-4 py-4">
          <div className="w-24 h-24 rounded-lg" >
            <img
              src={companyLogo}
              alt="Logo"
              className="w-20 h-20 mr-4 object-contain rounded-2xl shadow flex-shrink-0 p-2"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
            />
          </div>

          <div className="flex flex-col">
            {/* Second Row */}
            <h2 className="text-3xl font-semibold mb-2 mt-4">{companyName}</h2>
            {/* Third Row */}
            <p className="text-gray-600 text-xl">{aboutCompany}</p>
          </div>
        </div>
        <Link to={companyLink}>
          <div className="md:flex justify-left text-[#153cf5] text-lg font-semibold">
            View Company
          </div>
        </Link>
        {/* Fourth Row */}
      </div>
    </Link>
  );
}

export default CompanyCard;
