import React from "react";
import NavBar from "../../navBar/containers/NavBar";
import CompanyHero from "../components/CompanyHero";
import Combiner from "./Combiner";
function Company() {
  return (
    <div className="mt-20 ">
      <NavBar></NavBar>
      <div className="bg-[#f2f5ff] animate-fade-in">
        <CompanyHero></CompanyHero>
      </div>

      <div className="mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-8">
        <Combiner></Combiner>
      </div>
    </div>
  );
}

export default Company;
