import React, { useState, useEffect } from "react";
import NavBar from "../../navBar/containers/NavBar";
import ViewCompanyCard from "./ViewCompanyCard";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Spin, Result } from "antd";
export default function ViewCompany() {
  const dispatch = useDispatch();
  const { company_name, company_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState({});

  useEffect(() => {
    axios
      .get(
        `https://jobs-backend-web-v2.vercel.app/api/v1/getComapnyInfo?company_id=${company_id}`
      )
      .then((response) => {
        setLoading(false);
        setCompanyData(response.data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div>
        <NavBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (!loading && Object.keys(companyData).length === 0) {
    return (
      <>
        <NavBar />
        <Result
          style={{ marginTop: "10%" }}
          status="404"
          title="Page Not Found"
          subTitle="Sorry, the requested company page does not exist."
        />
      </>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="mt-20">
        <ViewCompanyCard companyData={companyData} />
      </div>
    </div>
  );
}
