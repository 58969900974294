import React from "react";
import PostJobMain from "../components/PostJobMain";
import NavBar from "../../navBar/containers/NavBar";
function PostJobContainer() {
  return (
    <div>
      <NavBar></NavBar>
      <div className="mt-20 pt-1.5 ">
        <PostJobMain></PostJobMain>
      </div>
    </div>
  );
}

export default PostJobContainer;
