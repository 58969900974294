import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Route, Switch, Link, useLocation, useHistory } from "react-router-dom";
import {
  Bars3Icon,
  BellIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  XMarkIcon,
  UserCircleIcon,
  BriefcaseIcon,
  FolderIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  LightBulbIcon,
  NewspaperIcon
} from "@heroicons/react/24/outline";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { UserButton } from "@clerk/clerk-react";

import MainDashBoardContainer from "../../MainDashBoard/container/MainDashBordContainer";
import DashBoardContainer from "../../DashBoard/containers/DashBoardContainer";
import UserJobs from "../../userjobs/containers/UserJobs";
import ProfileContainer from "../../Profile/container/ProfileContainer";
import ResumeBuilderContainer from "../../Resume/container/ResumeBuilderContainer";
import CoverLetterBuilder from "../../CoverLetter/container/MainCoverLetterContainer"
import DocumentContainer from "../../Documents/container/DocumentContainer";
import MainContainer from "../../Report/container/MainContainer";
import InterviewMainContainer from "../../Interview/container/InterviewMainContainer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBarCustom() {
  const history = useHistory();
  const [width, setWidth] = useState(20);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  const navigation = [
    {
      name: "Dashboard",
      href: "/user/home",
      icon: HomeIcon,
      current: currentPath === "/user/home",
    },
    // {
    //   name: "Dashboard2",
    //   href: "/user/home2",
    //   icon: HomeIcon,
    //   current: currentPath === "/user/home2",
    // },
    {
      name: "Jobs",
      href: "/user/jobs",
      icon: BriefcaseIcon,
      current: currentPath === "/user/jobs",
    },
    {
      name: "Profile",
      href: "/user/profile",
      icon: UserCircleIcon,
      current: currentPath === "/user/profile",
    },
    {
      name: "Resume",
      href: "/user/resume",
      icon: FolderIcon,
      current: currentPath === "/user/resume",
    },
    // {
    //   name: "CoverLetter",
    //   href: "/user/coverletter",
    //   icon: NewspaperIcon,
    //   current: currentPath === "/user/coverletter",
    // },
    {
      name: "Documents",
      href: "/user/documents",
      icon: DocumentDuplicateIcon,
      current: currentPath === "/user/documents",
    },
    {
      name: "Reports",
      href: "/user/report",
      icon: ChartPieIcon,
      current: currentPath === "/user/report",
    },
    {
      name: "Interview",
      href: "/user/interview",
      icon: LightBulbIcon,
      current: currentPath === "/user/interview",
    },
  ];
  const navigation_mobile = [
    {
      name: "Dashboard",
      href: "/user/home",
      icon: HomeIcon,
      current: currentPath === "/user/home",
    },
    // {
    //   name: "Dashboard2",
    //   href: "/user/home2",
    //   icon: HomeIcon,
    //   current: currentPath === "/user/home2",
    // },
    {
      name: "Jobs",
      href: "/user/jobs",
      icon: BriefcaseIcon,
      current: currentPath === "/user/jobs",
    },
    {
      name: "Profile",
      href: "/user/profile",
      icon: UserCircleIcon,
      current: currentPath === "/user/profile",
    },
    {
      name: "Documents",
      href: "/user/documents",
      icon: DocumentDuplicateIcon,
      current: currentPath === "/user/documents",
    },
    {
      name: "Reports",
      href: "/user/report",
      icon: ChartPieIcon,
      current: currentPath === "/user/report",
    },
    {
      name: "Interview",
      href: "/user/interview",
      icon: LightBulbIcon,
      current: currentPath === "/user/interview",
    },
  ];

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5 ">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <Link to='/'>
                      <div className="flex h-16 shrink-0 items-center">
                        <img
                          className="h-12 w-auto text-black"
                          src="https://thinkproduct.org/wp-content/uploads/2023/08/3.png"
                          alt="Product Mindset"
                        />
                        <h1 className="ml-2 bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent">
                          Product Mindset
                        </h1>
                      </div>
                    </Link>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation_mobile.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? "text-[#763ff9]"
                                      : "text-gray-900",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-[#763ff9]"
                                        : "text-gray-900",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-${width} lg:flex-colg transition-all duration-300 ease-in-out`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5  border-r border-gray-200 ">
            <div
              className="flex h-16 shrink-0 relative items-center pl-3 border-b"
              // style={{ background: "#00006d" }}
            >
              <div
                className="flex h-16 shrink-0 items-center pl-2"
                // style={{ background: "#00006d" }}
              >
                {width === 20 && (
                  <Link to="/">
                    <img
                      className="h-12 w-auto text-black -ml-0.5"
                      src="https://thinkproduct.org/wp-content/uploads/2023/08/3.png"
                      alt="Product Mindset"
                    />
                  </Link>
                )}
              </div>
              <h1
                className="ml-2 text-black text-xl font-bold"
                style={{ opacity: width === 72 ? 1 : 0 }}
              >
                {width === 72 && (
                  <Link to="/">
                    <div className="flex justify-left items-center gap-1">
                      <div>
                        <img
                          className="h-12 w-full text-black "
                          src="https://thinkproduct.org/wp-content/uploads/2023/08/3.png"
                          alt="Product Mindset"
                        />
                      </div>
                      <p className="bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent">
                        Product Mindset
                      </p>
                    </div>
                  </Link>
                )}
              </h1>
              <div className="flex justify-center items-center absolute -right-3 top-0 h-full flex items-center text-white ">
                {width === 20 ? (
                  <ChevronRightIcon
                    className="h-7 w-7 rounded-full bg-white text-[#0B42D5] font-semibold border shadow p-0.5"
                    onClick={() => {
                      setWidth(72);
                    }}
                  ></ChevronRightIcon>
                ) : (
                  <ChevronLeftIcon
                    className="h-7 w-7 rounded-full bg-white text-[#0B42D5] font-semibold border shadow p-0.5"
                    onClick={() => {
                      setWidth(20);
                    }}
                  ></ChevronLeftIcon>
                )}
              </div>
            </div>

            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1 p-3">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current ? "text-[#763ff9]" : "text-gray-600 ",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-[#763ff9]"
                                : "text-gray-600 ",
                              "ml-3 h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {width === 72 && (
                            <span
                              className={classNames(
                                item.current
                                  ? "text-[#763ff9]"
                                  : "text-gray-600 ",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            >
                              {item.name}
                            </span>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div
          className={`lg:pl-${width} transition-all duration-300 ease-in-out `}
        >
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 ">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-200 lg:hidden "
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 ">
              <form
                className="relative flex items-center flex-1"
                action="#"
                method="GET"
              >
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <input
                    id="search-field"
                    className="block w-full py-2 pl-10 pr-3 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                    onChange={() => {
                      history.push("/user/jobs");
                    }}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </form>

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  aria-hidden="true"
                />

                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <UserButton afterSignOutUrl="/" />
                  </Menu.Button>
                </Menu>
              </div>
            </div>
          </div>

          <div className="bg-[#f8f9fb]">
            <main className="py-10  mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-8">
              <div className="px-4 sm:px-6 lg:px-8">
                <Switch>
                  <Route path="/user/home">
                    <MainDashBoardContainer />
                  </Route>
                  {/* <Route path="/user/home2">
                    <DashBoardContainer />
                  </Route> */}
                  <Route path="/user/jobs">
                    <UserJobs />
                  </Route>
                  <Route path="/user/profile">
                    <ProfileContainer />
                  </Route>
                  <Route path="/user/resume">
                    <ResumeBuilderContainer />
                  </Route>
                  <Route path="/user/coverletter">
                    <CoverLetterBuilder />
                  </Route>
                  <Route path="/user/documents">
                    <DocumentContainer />
                  </Route>
                  <Route path="/user/report">
                    <MainContainer />
                  </Route>
                  <Route path="/user/interview">
                    <InterviewMainContainer />
                  </Route>
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
