import React from "react";
import { Link } from "react-router-dom";

const DateRange = ({ startYear, endYear }) => {
  const startDate = new Date(startYear);
  const endDate = new Date(endYear);

  // Get year and month names
  const startYearName = startDate.getFullYear();
  const endYearName = endDate.getFullYear();
  const startMonthName = startDate.toLocaleString("default", {
    month: "short",
  });
  const endMonthName = endDate.toLocaleString("default", { month: "short" });

  return (
    <p className="content">
      {`${startMonthName} ${startYearName} - ${endMonthName} ${endYearName}`}
    </p>
  );
};


function Project({ form }) {
  return (
    <div>
      {form.projects.length > 0 && (
        <div>
          <h2
            className="section-title mb-1 border-b-2 border-gray-300 editable"
            style={{ paddingBottom: "10px" }}
            contentEditable
            suppressContentEditableWarning
          >
            Projects
          </h2>
          {form.projects.map((item, index) => (
            <div key={`${item.name}-${index}`} className="mb-1">
              <p className="content i-bold">{item.title}</p>
              <DateRange
                startYear={item.startYear}
                endYear={item.endYear}
                id={`work-experience-start-end-date`}
              />
              <Link
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="content"
              >
                {item.link}
              </Link>
              <p className="content">{item.description}</p>
              <ul className="list-disc ul-padding content">
                {typeof item.keyAchievements === "string" &&
                  item.keyAchievements
                    .split("\n")
                    .map((achievement, subIndex) => (
                      <li
                        key={`${item.name}-${index}-${subIndex}`}
                        className="hover:outline-dashed hover:outline-2 hover:outline-gray-400"
                        style={{ listStyle: "none" }}
                      >
                       ➤ {achievement}
                      </li>
                    ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Project;
