import React, { useState } from "react";

const DateRange = ({ startYear, endYear }) => {
  const startDate = new Date(startYear);
  const endDate = new Date(endYear);

  // Get year and month names
  const startYearName = startDate.getFullYear();
  const endYearName = endDate.getFullYear();
  const startMonthName = startDate.toLocaleString("default", {
    month: "short",
  });
  const endMonthName = endDate.toLocaleString("default", { month: "short" });

  return (
    <p className="content">
      {`${startMonthName} ${startYearName} - ${endMonthName} ${endYearName}`}
    </p>
  );
};


function Education({ form }) {
  return (
    <div>
      {form.education.length > 0 && (
        <div className="mb-1">
          <h2
            className="section-title mb-1 border-b-2 border-gray-300"
            style={{ paddingBottom: "10px" }}
            contentEditable
            suppressContentEditableWarning
          >
            Education
          </h2>
          {form.education.map((item, index) => (
            <div key={index} className="mb-1">
              <p className="content i-bold">{item.degree}</p>
              <p className="content">{item.school}</p>
              <DateRange
                startYear={item.startYear}
                endYear={item.endYear}
                id={`education-start-end-date`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Education;
