import React, { useState } from "react";
import MainCard from "../company_components/MainCard";


export default function ViewCompanyCard({ companyData }) {

  return (
    <>
      <div className="mt-5 mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-8">
        <MainCard companyData={companyData}></MainCard>
      </div>
      
    </>
  );
}

