import "./helper.css";

export const Text = ({ type, fade, children, style, size }) => (
  <span
    className={!fade ? `text  ${type} ${size}` : `text ${type} fade`}
    style={style}
  >
    {children}
  </span>
);

export const CustomGrid = ({ size, children, style }) => (
  <div className={"customGrid s" + size} style={style}>
    {children}
  </div>
);

export const Divider = ({ style }) => (
  <div className={"formDivider"} style={style} />
);

export const handleEnter = (e) => e.target.blur();

export const CustomButton = (props) => (
  <button
    {...props}
    className={"Custombtn " + props.type + " " + props.className}
  >
    {props.children}
  </button>
);

export const CustomModalFooter = () => (
  <div className="action-buttons-container">
    {" "}
    <CustomButton className="secondary"> Cancel</CustomButton>
    <CustomButton className="primary2">Save</CustomButton>
  </div>
);

export const CustomModalFooterHangging = ({
  showPreview,
  togglePreview,
  onSave,
  isLoadingSave,
}) => {
  return (
    <div
      className="fixed bottom-1 pl-5 pr-12 left-0 w-full  bg-white border-t border-gray-300 shadow-md flex justify-end gap-2"
      style={{ zIndex: 1 }}
    >
      <CustomButton
        className="secondary"
        onClick={togglePreview}
        style={{ marginTop: "15px" }}
      >
        {showPreview ? "Edit Info" : "Preview"}
      </CustomButton>
      <CustomButton
        className="primary2"
        onClick={onSave}
        style={{ marginTop: "15px" }}
      >
        {showPreview ? "Download" : isLoadingSave ? "Saving..." : "Save"}
      </CustomButton>
    </div>
  );
};
