import React, { useState } from "react";
import { Checkbox } from "antd";
const notificationMethods = [
  { id: "Early", title: "Early" },
  { id: "Mid-size", title: "Mid-size" },
  { id: "Large", title: "Large" },
];

export default function CompanySize({
  setFormFields,
  formFields,
  selectedMethods,
  setSelectedMethods
}) {
  const handleCheckboxChange = (e) => {
    const selectedMethod = e.target.id;

    if (e.target.checked) {
      setSelectedMethods((prevSelected) => [...prevSelected, selectedMethod]);
    } else {
      setSelectedMethods((prevSelected) =>
        prevSelected.filter((method) => method !== selectedMethod)
      );
    }

   
  };

  return (
    <div>
      {/* <label className="text-base font-semibold text-gray-900">
      Companies
      </label> */}
      <p className="text-sm text-gray-500">
        How do you prefer companies of these sizes?
      </p>
      <fieldset className="mt-4">
        <legend className="sr-only">Notification method</legend>
        <div className="space-y-4 w-full sm:flex sm:items-center sm:space-x-10 sm:space-y-0 x-3">
          {notificationMethods.map((notificationMethod) => (
            <div key={notificationMethod.id} className="flex items-center">
              <Checkbox
                id={notificationMethod.id}
                name="notification-method"
                onChange={handleCheckboxChange}
                checked={selectedMethods.includes(notificationMethod.id)}
              ></Checkbox>
              <label
                htmlFor={notificationMethod.id}
                className="ml-1 block text-sm   text-gray-900"
              >
                {notificationMethod.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
