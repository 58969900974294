import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { SignedOut, SignIn, useUser, SignedIn,SignUp } from "@clerk/clerk-react";

import Home from "../modules/LandingPage/containers/Home";
import JobsListing from "../modules/jobListings/containers/JobsListing";
import ViewJobDetails from "../modules/jobListings/easyjobs/ViewJobDetails";
import Company from "../modules/companyProfiles/container/Company";
import ViewCompany from "../modules/companyProfiles/components/ViewCompany";

import MainContainer from "../modules/pricing/container/MainContainer";
import PostJobContainer from "../modules/postjobs/container/PostJobContainer";

import SideBarContainer from "../modules/protectedContent/sideBar/container/SideBarContainer";
import routingPath from "./routingPath.json";

function App() {
  const { isSignedIn } = useUser();
  return (
    <Router>
      <Switch>
        <Route exact path={routingPath.home} component={Home} />
        <Route path="/sign-in" component={SignInPage} />
        <Route path="/sign-up" component={SignUpPage} />
        <Route path={routingPath.jobs} component={JobsListing} />
        <Route path={routingPath.company} component={Company} />
        <Route path={routingPath.pricing} component={MainContainer} />
        <Route path={routingPath.postjob} component={PostJobContainer} />
        <Route
          path="/easycompany/:company_name/:company_id"
          component={ViewCompany}
        />
        <Route
          path="/easyjob/:company_name/:job_id"
          component={ViewJobDetails}
        />
        <SignedIn>
          <Route path="/user" component={ProtectedPage} />
        </SignedIn>

        <SignedOut>
          <Redirect to={routingPath.home} />
        </SignedOut>
      </Switch>
    </Router>
  );
}

function SignInPage() {
  return (
    <div className="flex justify-center items-center h-screen animate-fade-in">
      <SignIn redirectUrl="/user/home" />
    </div>
  );
}
function SignUpPage() {
  return (
    <div className="flex justify-center items-center h-screen animate-fade-in">
      <SignUp redirectUrl="/user/home" />
    </div>
  );
}

function ProtectedPage() {
  return (
    <div>
      <div>
        <SideBarContainer />
      </div>
    </div>
  );
}

// function ProtectedRoute({ component: Component, ...rest }) {
//   const { isSignedIn } = useUser();

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isSignedIn ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/sign-in" />
//         )
//       }
//     />
//   );
// }

export default App;
