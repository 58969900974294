import React from "react";

function HeaderCard() {
  return (
    <div className="md:grid gap-6 md:grid-cols-2 lg:grid-cols-4 bg-[#f8f9fb] rounded-xl md:block hidden">
      <div className="text-xs rounded-lg flex px-4 gap-6 py-2 md:py-4 font-semibold text-[#627183]">
        TITLE
      </div>
      <div className="text-xs rounded-lg flex px-4 gap-6 py-2 md:py-4 font-semibold text-[#627183]">
        LOCATION
      </div>
      <div className="text-xs rounded-lg flex px-4 gap-6 py-2 md:py-4 font-semibold text-[#627183]">
        COMPANY
      </div>
      <div className="text-xs rounded-lg flex px-4 gap-14 py-2 md:py-4 font-semibold text-[#627183] ">
        <div>STATUS</div>
        <div>APPLY</div>
      </div>
    </div>
  );
}

export default HeaderCard;
