import React, { useState } from "react";

function FileSelector({ selectedFile, setSelectedFile }) {
  // const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    // console.log(file);
  };

  const containerClass = selectedFile
    ? "bg-white border-gray-300"
    : "bg-gray-50 border-gray-300";
  const textClass = selectedFile ? "text-gray-900" : "text-gray-500";

  return (
    <label
      htmlFor="dropzone-file"
      className={`flex flex-col items-center justify-center w-full h-34 border-2 border-dashed rounded-lg cursor-pointer ${containerClass}`}
    >
      <div className="flex flex-col items-center justify-center pt-5 pb-6">
        {selectedFile ? (
          <>
            <svg
              className={`w-8 h-8 mb-4 ${textClass} dark:text-gray-400`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className={`mb-2 text-sm ${textClass} dark:text-gray-400`}>
              {selectedFile.name}
            </p>
          </>
        ) : (
          <>
            <svg
              className={`w-8 h-8 mb-4 ${textClass} dark:text-gray-400`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className={`mb-2 text-sm ${textClass} dark:text-gray-400`}>
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className={`text-xs ${textClass} dark:text-gray-400`}>
              Only PDF format accepted.
            </p>
          </>
        )}
      </div>
      <input
        id="dropzone-file"
        type="file"
        className="hidden"
        accept="application/pdf"
        onChange={handleFileChange}
      />

      {/* <>{ selectedFile.name}</> */}
    </label>
  );
}

export default FileSelector;
