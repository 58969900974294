import React, { useState } from "react";

function HeaderCard({ job }) {
  const [hovered, setHovered] = useState(false);
  const companyLogo = job && job.company_logo;
  const companyName = job && job.company_name;
  const jobTitle = job && job.job_category;
  const jobLocation = job && job.job_location;
  const jobPostedDate = job && job.job_posted_date;
  const jobLabel = job && job.label;
  const jobUrl = job && job.job_url;
  const jobSalary = job && job.salary ? job.salary : "COMPETITIVE";
  let formattedDate = "";

  if (jobPostedDate) {
    const dateObject = new Date(jobPostedDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    formattedDate = dateObject.toLocaleDateString("en-US", options);
  }
  const handleApplyEasyJobs = () => {
    window.open(`${jobUrl}`, "_blank");
  };
  return (
    <>
      <div className="md:flex md:mt-12 md:ml-12">
        {/* 1st Column - Company Logo */}
        <div
          className="flex w-20 h-20  md:max-w-[104px] md:w-[104px] max-h-[104px] md:h-[104px] mr-5 rounded-3xl overflow-hidden justify-center items-center "
          style={{
            boxShadow:
              "0 2px 6px 0 rgba(5, 21, 46, 0.06), 0 4px 8px 0 rgba(21, 60, 245, 0.04)",
          }}
        >
          <img
            src={companyLogo}
            alt="Company Logo"
            className="w-full h-full object-contain rounded-3xl border p-3 "
          />
        </div>

        {/* 2nd Column - Company Name, About, and View Button */}
        <div className="w-3/4 ml-1 mt-3 md:mt-0">
          <div className="md:flex justify-between">
            <h3
              className={`font-semibold  line-clamp-1 overflow-hidden whitespace-normal
       text-xl text-[#153cf5] capitalize`}
            >
              {companyName}
            </h3>
            <div className="flex-none font-semibold text-lg text-gray-600 md:-mr-10 ">
              {formattedDate}
            </div>
          </div>
          <h3
            className={`font-semibold ${
              hovered ? "text-[#153cf5]" : "text-black "
            } line-clamp-1 overflow-hidden whitespace-normal
       md:text-3xl text-xl mt-3 md:mt-0`}
          >
            {jobTitle}
          </h3>
          <div className="text-gray-600 mt-2 md:flex justify-between ">
            <div className="md:flex gap-1 py-3">
              <div className="p-1 rounded bg-[#f6f7fa] text-sm md:text-lg w-full text-gray-600  whitespace-nowrap">
                {jobLabel}
              </div>
            </div>

            <button
              className="w-full md:w-auto h-9 md:h-14 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-4 md:-mr-10 font-bold text-lg rounded mt-5 md:mt-0"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
              onClick={handleApplyEasyJobs}
            >
              APPLY NOW
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:block md:flex md:px-20 md:mt-10 md:gap-8 ">
        <div className="md:w-1/3 mb-4 md:mb-0">
          <div className="flex justify-center items-center gap-1 w-full whitespace-nowrap border-r border-gray-300 pr-4">
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec9969ef56b090e8b4fa23d_job-type-icon.svg"
              alt="Company Logo"
              className="w-6 h-6 object-contain"
            />
            <p className="text-sm md:text-lg text-gray-600">FULL TIME</p>
          </div>
        </div>
        <div className="md:w-1/3 mb-4 md:mb-0">
          <div className="flex justify-center items-center gap-1 w-full whitespace-nowrap border-r border-gray-300 pr-4">
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec9969e06d25c3a7211da73_location-icon.svg"
              alt="Company Logo"
              className="w-6 h-6 object-contain"
            />
            <p className="text-sm md:text-lg text-gray-600 line-clamp-1 overflow-hidden whitespace-normal">
              {jobLocation
                ? jobLocation.split(/[/,]/)[0].toUpperCase()
                : "REMOTE"}
            </p>
          </div>
        </div>
        <div className="md:w-1/3">
          <div className="flex justify-center items-center gap-1 w-full whitespace-nowrap">
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec9969f9359c294993bc42e_salary-icon.svg"
              alt="Company Logo"
              className="w-6 h-6 object-contain"
            />
            <p className="text-sm md:text-lg text-gray-600">
              {jobSalary ? jobSalary.split(/[/-]/)[0] : jobSalary}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderCard;
