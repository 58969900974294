import React from 'react'
import JobSearch from '../components/JobSearch'
function UserJobs() {
  return (
      <div>
          <JobSearch></JobSearch>
    </div>
  )
}

export default UserJobs