import React, { useState, useEffect } from "react";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import { Spin } from "antd";
import HeaderCard from "./HeaderCard";
import Card from "./Card";

function MainCardList() {
  const { user } = useClerk();

  const [loading, setLoading] = useState(true);
  const [recomendedJobs, setRecomendedJobs] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://web-back-dashbaord-apis.vercel.app/api/v1/getUserRecomendedJob?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setRecomendedJobs(response.data);
        setLoading(false); // Data fetched, loading is complete
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Error occurred, loading is complete
      });
  }, []);

  return (
    <div className="bg-white shadow-lg p-3 rounded-xl">
      <div className="text-xl font-semibold py-3">Recomended Jobs</div>
      <HeaderCard></HeaderCard>
      <div className="mt-4 md:mt-0">
        {loading ? (
          <div className="flex justify-center items-center py-10"><Spin size="large" /></div> // Display loading animation
        ) : (
          recomendedJobs.map((job) => <Card key={job.id} job={job} />)
        )}
      </div>
    </div>
  );
}

export default MainCardList;
