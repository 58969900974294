import React from "react";
import CountrySelect from "./CountrySelect";
import StateSelect from "./StateSelect";
import CitySelect from "./CitySelect";
import ExperienceSelect from "./ExperienceSelect";

import { Select, Input } from "antd";
const { TextArea } = Input;
const { Option } = Select;
function BasicInfoForm({
  jobCategoryLoading,
  jobsCategorys,
  setSelectedCountry,
  selectedCountry,
  setSelectedState,
  selectedState,
  setFormFields,
  formFields,
}) {
  return (
    <div>
      <div className="space-y-12 ">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-6">
              <label
                htmlFor="full-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Full Name <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <Input
                  type="text"
                  name="name"
                  id="full-name"
                  autoComplete="given-name"
                  className="w-full "
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      name: e.target.value,
                    });
                  }}
                  
                  value={formFields.name}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Your Primary Role <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <Select
                  id="role"
                  name="role"
                  autoComplete="role"
                  className="w-full"
                  defaultValue=""
                  loading={jobCategoryLoading}
                  onChange={(value) => {
                    setFormFields({
                      ...formFields,
                      role: value,
                    });
                  }}
                  value={formFields.role}
                >
                  <Option value="" disabled>
                    Select Role
                  </Option>
                  {jobsCategorys.map((category, index) => (
                    <Option key={index} value={category.value}>
                      {category.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Year Of Experience <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <ExperienceSelect
                  setFormFields={setFormFields}
                  formFields={formFields}
                ></ExperienceSelect>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Country <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <CountrySelect
                  setSelectedCountry={setSelectedCountry}
                  selectedCountry={selectedCountry}
                  setFormFields={setFormFields}
                  formFields={formFields}
                ></CountrySelect>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                State <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <StateSelect
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  setFormFields={setFormFields}
                  formFields={formFields}
                ></StateSelect>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                City <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <CitySelect
                  country={selectedCountry}
                  state={selectedState}
                  setFormFields={setFormFields}
                  formFields={formFields}
                ></CitySelect>
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                About <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <TextArea
                  id="about"
                  name="about"
                  rows={3}
                  className="w-full rounded-md"
                  defaultValue={""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setFormFields({
                      ...formFields,
                      about: value,
                    });
                  }}
                  value={formFields.about}
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-600">
                Write a few sentences about yourself.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Social Pofiles
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Where can people find you online?
            </p>
          </div>

          <div className="max-w-2xl space-y-10 md:col-span-2">
            <div className="sm:col-span-4">
              <label
                htmlFor="website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Website
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm  focus-within:ring-indigo-600 sm:max-w-md">
                  <Input
                    id="website"
                    name="website"
                    className="w-full "
                    placeholder="www.example.com"
                    addonBefore="http://"
                    onChange={(e) => {
                      setFormFields({
                        ...formFields,
                        website: e.target.value,
                      });
                    }}
                    value={formFields.website}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Linkedln
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm  focus-within:ring-indigo-600 sm:max-w-md">
                  <Input
                    id="Linkedln"
                    name="Linkedln"
                    className="w-full "
                    placeholder="www.example.com"
                    addonBefore="http://"
                    onChange={(e) => {
                      setFormFields({
                        ...formFields,
                        linkedln: e.target.value,
                      });
                    }}
                    value={formFields.linkedln}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Twitter
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm  focus-within:ring-indigo-600 sm:max-w-md">
                  <Input
                    id="website"
                    name="website"
                    className="w-full "
                    placeholder="www.example.com"
                    addonBefore="http://"
                    onChange={(e) => {
                      setFormFields({
                        ...formFields,
                        twitter: e.target.value,
                      });
                    }}
                    value={formFields.twitter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicInfoForm;
