import React from "react";
import SearchButton from "./SearchButton";
import FeaturedJob from "./FeaturedJob";
function RightContent() {
  return (
    <div className="md:mr-10">
      <SearchButton></SearchButton>
      <div className="md:px-2 px-5">
        <FeaturedJob></FeaturedJob>
      </div>
    </div>
  );
}

export default RightContent;
