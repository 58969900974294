import React from "react";
import InterviewMainComponent from "../components/InterviewMainComponent";
function InterviewMainContainer() {
  return (
    <div >
      <InterviewMainComponent></InterviewMainComponent>
    </div>
  );
}

export default InterviewMainContainer;
