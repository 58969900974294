import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import Templeate1 from "../templates/template1/Template1";
import Templeate2 from "../templates/template2/Template2";

import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import { message } from "antd";
import { Spin } from "antd";
import MainForm from "../formcomponents/MainForm";
import {
  Page,
  Document,
  View,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import html2canvas from "html2canvas";

import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/20/solid";

import {
  CustomButton,
  CustomModalFooterHangging,
} from "../../../../helper/helper";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
    // padding: 3,
  },
});

const tabs = [
  { name: "Template1", href: "", icon: ClipboardDocumentCheckIcon },
  { name: "Template2", href: "", icon: ClipboardDocumentListIcon },
];

function ResumeBuilder() {
  const { user } = useClerk();
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Template1");
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  const [form, setForm] = useState({});

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://web-backend-user-resume-data.vercel.app/api/v1/getUserResumeData?username=${user.primaryEmailAddress.emailAddress}`
      )
      .then((response) => {
        setForm(response.data.resume_data);
        setLoading(false);
      })
      .catch((error) => {
        setForm({
          name: "Lenny Rachitsky",
          position: "DeveProduct Lead, Supply Growthloper",
          contactInformation: "+1-555-789-0100",
          email: "beddylea@gmail.com",
          address: "San Francisco, CA",
          profilePicture: "",
          socialMedia: [
            {
              socialMedia: "Github",
              link: "https://github.com/lennysan",
            },
            {
              socialMedia: "LinkedIn",
              link: "linkedin.com/in/lennyrachitsky",
            },
            {
              socialMedia: "Twitter",
              link: "https://twitter.com/lennysan",
            },
          ],
          summary:
            "Airbnb\n7 yrs\nSan Francisco Bay AreaSan Francisco Bay Area\nProduct Lead, Supply GrowthProduct Lead, Supply Growth\nJan 2016 - Mar 2019 · 3 yrs 3 month\nLed the team responsible for driving consumer supply growth. This included:\n\n* Performance marketing\n* Conversion\n* Referrals\n* Awareness and consideration\n* Re-engagement\n* Retention\n* Core host products\n\nPreviously, led three teams driving Instant Book Growth (grew usage from 5% to over 80%), guest booking conversion, and building new tools for hosts (e.g. dashboards, calendar, guest controls, publishing, messaging).\n\nAlso supported strategy of the Anti-Discrimination team, and helped on a few side-projects including work with The Malala Fund.",
          education: [
            {
              school: "BS in Computer Science",
              degree: "UC San Diego",
              startYear: "1991",
              endYear: "2002",
            },
          ],
          workExperience: [
            {
              company: "Airbnb",
              position: "Product Lead, Supply Growth, San Francisco Bay",
              description:
                "Led the team responsible for driving consumer supply growth. This included: Previously, led three teams driving Instant Book Growth (grew usage from 5% to over 80%), guest booking conversion, and building new tools for hosts (e.g. dashboards, calendar, guest controls, publishing, messaging).",
              keyAchievements:
                "Created and maintained 10 web applications for numerous national and foreign clients.\nEnsured that the user interfaces and user experience of the software applications developed by the team met at least 80% of users expectations.\nCreated and analyzed 500 unit test cases.\nDeveloped python scripts to automate image's noise-reduction process which helped improve research analysis time by 40%.\nEstablished and lead a team of 10 people; covering every key role in the early stages.",
              startYear: "2023-02-16",
              endYear: "2023-02-21",
            },
            {
              company: "Localmind",
              position: "Co-Founder, CEO",
              description:
                "Localmind was an early location-based consumer app. We leveraged location data from Foursquare, Facebook, and Gowalla to capture location expertise of locals, enabling Localmind users to get real-time answers to questions about places they are thinking about visiting.",
              keyAchievements:
                "Localmind was an early location-based consumer app. We leveraged location data from Foursquare, Facebook, and Gowalla to capture location expertise of locals, enabling Localmind users to get real-time answers to questions about places they are thinking about visiting.",
              startYear: "Dec 2010",
              endYear: "Apr 2012",
            },
          ],
          projects: [
            {
              title: "Project Name",
              link: "https://www.example.com",
              description:
                "Reilty Group is an industry-leading provider of online gambling software and solutions.",
              keyAchievements:
                "Increased by 35% the reach of users to the platform, over the installation of the web platform in mobile devices.\nDelivered 30 web solutions.\nReplaced 1 full-time web developer under part-time employment achieving a productivity gain of over 60% as measured by working hours.\nWorked with more than 4 different teams at different points of time and provided end-to-end solutions for clients.",
              startYear: "2018",
              endYear: "2022",
            },
          ],
          skills: [
            {
              title: "Product Frameworks",
              skills: ["Product discovery"],
            },
            {
              title: "Technical Skills",
              skills: [
                "Scrum",
                "Python",
                "Start-ups",
                "System Administration",
                "R&D",
                "Perl",
                "Research",
                "Transparency",
                "Cloud Computing",
                "Agile",
                "SaaS",
                "Product Management",
                "Web Applications",
                "Software Engineering",
                "User Interface",
                "Creativity",
              ],
            },
            {
              title: "Soft Skills",
              skills: [
                "Communication",
                "Teamwork",
                "Problem-solving",
                "Time management",
                "Critical thinking",
                "Decision-making",
                "Organizational",
              ],
            },
            {
              title: "Additional Skills",
              skills: ["Public Speaking", "Writing", "Research"],
            },
          ],
          languages: [],
          certifications: [
            "Certified Web Professional-Web Developer",
            "Java Development Certified Professional",
          ],
        });
      });
  }, []);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGenerateAndDownloadPDF = async () => {
    const canvas = await html2canvas(document.querySelector("#pdf-content"), {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");

    const pdfBlob = await pdf(
      <Document>
        <Page>
          <View style={styles.page}>
            <Image src={imgData} />
          </View>
        </Page>
      </Document>
    ).toBlob();
    // setPdfData(URL.createObjectURL(pdfBlob));

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = "output.pdf";
    downloadLink.click();
  };

  const saveResumeData = () => {
    setIsLoadingSave(true);
    const apiUrl =
      "https://web-backend-user-resume-data.vercel.app/api/v1/saveUserResumeData";

    const postData = {
      username: user.primaryEmailAddress.emailAddress,
      resume_data: form,
    };
    axios
      .post(apiUrl, postData)
      .then((response) => {
        message.success(
          `${user.primaryEmailAddress.emailAddress} resume info saved successfully!`
        );
        setIsLoadingSave(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = `Something went wrong Please try again...`;
        message.error(errorMessage);
        setIsLoadingSave(false);
      });
  };

  return (
    <div>
      <div className="flex items-start justify-between ">
        <p className="text-xl md:text-3xl font-bold resume-header mt-5">
          Resume Builder
        </p>

        <div className="flex items-center justify-end gap-x-4 mb-12 lg:mb-12">
          <CustomButton onClick={togglePreview} className="primary">
            {showPreview ? "Edit Info" : "Preview"}
          </CustomButton>
        </div>
      </div>

      {Object.keys(form).length ? (
        <>
          {showPreview ? (
            <>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <button
                        key={tab.name}
                        to={tab.href}
                        className={`${
                          tab.name === selectedTab
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        } group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium`}
                        aria-current={
                          tab.name === selectedTab ? "page" : undefined
                        }
                        onClick={() => handleTabClick(tab.name)}
                      >
                        <tab.icon
                          className={`${
                            tab.name === selectedTab
                              ? "text-indigo-500"
                              : "text-gray-400 group-hover:text-gray-500"
                          } -ml-0.5 mr-2 h-5 w-5`}
                          aria-hidden="true"
                        />
                        <span>{tab.name}</span>
                      </button>
                    ))}
                  </nav>
                </div>
              </div>

              <div className="mt-12">
                <div
                  id="pdf-content"
                  className="bg-white shadow-md rounded-md p-4 mb-20 border mx-auto"
                  ref={componentRef}
                >
                  {selectedTab === "Template1" ? (
                    <Templeate1 form={form} setForm={setForm} />
                  ) : (
                    <Templeate2 form={form} />
                  )}
                </div>
              </div>
            </>
          ) : (
            <MainForm form={form} setForm={setForm} />
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
          }}
        >
          <Spin size="large" />
        </div>
      )}

      <CustomModalFooterHangging
        showPreview={showPreview}
        togglePreview={togglePreview}
        onSave={showPreview ? handlePrint : saveResumeData}
        isLoadingSave={isLoadingSave}
      />
    </div>
  );
}

export default ResumeBuilder;
