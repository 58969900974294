import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function Card({ company }) {
  const companyId = company && company.company_id;
  const companyLogo = company && company.company_logo;
  const companyName =
    company &&
    company.company_name.charAt(0).toUpperCase() +
      company.company_name.slice(1);
  
  const aboutCompany = company && company.about_company.replaceAll("'", "");

  

  const handleApplyEasyJobs = () => {
    window.open(`/easycompany/${companyName}/${companyId}`, "_blank");
  };

  const [hovered, setHovered] = useState(false);
  return (
    <div className="w-full md:w-1/2 p-3">
      <div
        className="bg-white  mt-3 rounded-3xl transition-all duration-600 hover:-translate-y-1 border hover:border-blue-600 border-1.5 text-gray-600 cursor-pointer p-3 md:p-10"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleApplyEasyJobs}
        style={{
        boxShadow:
          "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
      }}
      >
        <div className=" md:flex">
          {/* 1st Column - Company Logo */}
          <div className="flex w-full max-w-[99px] mr-5  rounded-[18px] overflow-hidden justify-center items-center ">
            <img
              src={companyLogo}
              alt="Company Logo"
              className="object-fit rounded-3xl border"
            />
          </div>

          {/* 2nd Column - Company Name, About, and View Button */}
          <div className="w-3/4 ml-4 mt-3 md:mt-0">
            <h3
              className={`font-semibold ${
                hovered ? "text-blue-600" : ""
              } line-clamp-1 overflow-hidden whitespace-normal
           text-xl text-black `}
            >
              {companyName}
            </h3>
            <p className="text-gray-600 line-clamp-5 md:line-clamp-3 overflow-hidden whitespace-normal mt-2">
              {aboutCompany}
            </p>
            <div className="flex gap-1 items-center mt-4">
              <div className="text-blue-600 capitalize underline font-semibold">
                View Company
              </div>
              <svg
                className={`text-blue-600 w-4 h-4 transition-all duration-600 transform rotate-45 mt-1 ${
                  hovered ? "translate-x-1 -translate-y-1" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 10l7-7m0 0l7 7m-7-7v18"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
