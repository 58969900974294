import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Spin } from "antd";
import { setJobWithCategory } from "../../../features/jobs/jobsSlice";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import CustomCard from "./CustomCard";

function JobsCompanyCard() {
  const dispatch = useDispatch();
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const jobWithCategory = useSelector(
    (state) => state.jobs_data_source.jobWithCategory
  );
  const productManagerFilteredJobs = jobWithCategory.length
    ? jobWithCategory.filter((job) => job.job_category === "Product Manager")
    : [];
  const associateFilteredJobs = jobWithCategory.length
    ? jobWithCategory.filter(
        (job) => job.job_category === "Associate Product Manager"
      )
    : [];

  const seniorFilteredJobs = jobWithCategory.length
    ? jobWithCategory.filter((job) => job.job_category === "Sr Product Manager")
    : [];
  const midSeniorFilteredJobs = jobWithCategory.length
    ? jobWithCategory.filter((job) => job.job_category === "Mid Senior Level")
    : [];
  const productLeaderShipFilteredJobs = jobWithCategory.length
    ? jobWithCategory.filter((job) => job.job_category === "Product Leadership")
    : [];

  // console.log(associateFilteredJobs);

  useEffect(() => {
    axios
      .get("https://jobs-backend-web-v2.vercel.app/api/v1/getjobs")
      .then((response) => {
        dispatch(setJobWithCategory(response.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className="mt-10">
      <div>
        <div className="flex justify-between p-2 m-2">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 text-center sm:text-2xl lg:text-left ">
            Associate Product Manager
          </h1>
          <div className="ml-auto mt-2 text-[#153cf5] underline font-semibold">
            <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
              View All Jobs
            </Link>
          </div>
        </div>
        {associateFilteredJobs.length
          ? associateFilteredJobs.map((job, index) => (
              <CustomCard
                key={index}
                job={job}
                category="Associate"
              ></CustomCard>
            ))
          : ""}
        {/* <NewCard key={index} job={job}></NewCard> */}
        {/* <Card key={index} job={job} /> */}
      </div>
      <div className="mt-20">
        <div className="flex justify-between p-2 m-2">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 text-center sm:text-2xl lg:text-left ">
            Product Manager
          </h1>
          <div className="ml-auto mt-2 text-[#153cf5] underline font-semibold">
            <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
              View All Jobs
            </Link>
          </div>
        </div>
        {productManagerFilteredJobs.length
          ? productManagerFilteredJobs.map((job, index) => (
              <CustomCard key={index} job={job} category="Product"></CustomCard>
            ))
          : ""}
      </div>

      <div className="mt-20">
        <div className="flex justify-between p-2 m-2">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 text-center sm:text-2xl lg:text-left ">
            Sr. Product Manager
          </h1>
          <div className="ml-auto mt-2 text-[#153cf5] underline font-semibold">
            <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
              View All Jobs
            </Link>
          </div>
        </div>
        {seniorFilteredJobs.length
          ? seniorFilteredJobs.map((job, index) => (
              <CustomCard key={index} job={job} category="Senior"></CustomCard>
            ))
          : ""}
      </div>
      <div className="mt-20">
        <div className="flex justify-between p-2 m-2">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 text-center sm:text-2xl lg:text-left ">
            Mid Senior Level
          </h1>
          <div className="ml-auto mt-2 text-[#153cf5] underline font-semibold">
            <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
              View All Jobs
            </Link>
          </div>
        </div>
        {midSeniorFilteredJobs.length
          ? midSeniorFilteredJobs.map((job, index) => (
              <CustomCard
                key={index}
                job={job}
                category="Mid Senior"
              ></CustomCard>
            ))
          : ""}
      </div>
      <div className="mt-20">
        <div className="flex justify-between p-2 m-2">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 text-center sm:text-2xl lg:text-left ">
            Product Leadership
          </h1>
          <div className="ml-auto mt-2 text-[#153cf5] underline font-semibold">
            <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
              View All Jobs
            </Link>
          </div>
        </div>
        {productLeaderShipFilteredJobs.length
          ? productLeaderShipFilteredJobs.map((job, index) => (
              <CustomCard
                key={index}
                job={job}
                category="Leadership"
              ></CustomCard>
            ))
          : ""}
      </div>
    </div>
  );
}

export default JobsCompanyCard;
