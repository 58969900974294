import React from "react";
import { Input } from "antd";
const { TextArea } = Input;
function Describe({ formFields, setFormFields }) {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Describe what you are looking for in your next job{" "}
          <span className="text-red-500">*</span>
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Startups tell us this is one of the first things they look at in a
          profile.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-full">
          <div className="mt-2">
            <TextArea
              id="describe"
              name="describe"
              rows={6}
              className="w-full rounded-md"
              defaultValue={""}
              onChange={(e) => {
                const { value } = e.target;
                setFormFields({
                  ...formFields,
                  about_description: value,
                });
              }}
              value={formFields.about_description}
            />
          </div>
          <p className="mt-3 text-sm leading-6 text-gray-600">
            Your job profile is visible to startups.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Describe;
