import React from "react";
import TextArea from "../../../../helper/textArea/textarea";
import { Text, CustomGrid } from "../../../../helper/helper";
function Summary({ form, handleChange }) {
  return (
    <div
      style={{
        background: "white",
        padding: "2%",
        boxShadow:
          "0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08)",
      }}
      className="border"
    >
      <div style={{ marginBottom: "2%" }}>
        <Text type="sm">Summary</Text>
      </div>
      <CustomGrid size={1}>
        <TextArea
          name="summary"
          placeholder="Summary"
          value={form.summary}
          onChange={({ target }) => handleChange(target.name, target.value)}
        ></TextArea>
      </CustomGrid>
    </div>
  );
}

export default Summary;
