import React from "react";
import Card from "./Card";

function CardList({userData}) {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
      <Card
        key={10001}
        image="https://assets.website-files.com/6057ab51530cb39d3fdac75d/605882cb46d7bcb205f20f73_bar-chart.svg"
        label="TOTAL JOBS"
        value={userData?.total_jobs}
        color="#ebe2fe"
        link="/user/jobs"
      />
      <Card
        key={10002}
        image="https://assets.website-files.com/6057ab51530cb39d3fdac75d/6058831d493c9a126c8c8cbc_users.svg"
        label="RECOMENDED"
        value={userData?.total_recomended_jobs}
        color="#d9faf2"
        link="/user/jobs"
      />
      <Card
        key={10003}
        image="https://assets.website-files.com/6057ab51530cb39d3fdac75d/605883bbba1d7412377fefe7_truck.svg"
        label="EASY JOBS"
        value={userData?.easy_jobs}
        color="#fee7e3"
        link="/user/jobs"
      />
      <Card
        key={10004}
        image="https://assets.website-files.com/6057ab51530cb39d3fdac75d/605884b47ee33f1eb5676cce_target.svg"
        label="APPLIED JOBS"
        value={userData?.applied_jobs}
        color="#fff4e0"
        link="/user/report"
      />
    </div>
  );
}

export default CardList;
