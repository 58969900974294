import { createSlice } from "@reduxjs/toolkit";

const dynamicName = "pramod";
const coverLetterCreator = createSlice({
  name: "cover_letter",
  initialState: {
    coverLetterData: {
      recipientName: "John Doe",
      companyName: "ABC Company",
      jobTitle: "Software Engineer",
      openingParagraph:
        "I am writing to express my interest in the Software Engineer position at ABC Company.",
      body: "During my previous role at XYZ Company, I had the opportunity to work on a wide range of software development projects, which helped me develop strong problem-solving and coding skills. I am confident that my experience and passion for coding make me a strong candidate for this position.",
      closingParagraph: `
      
      ${dynamicName} 

 

Phone: 959-994-9455  *  Email: haroldhend@gmail.com *  Dallas, TX 

 

Date : 4/07/23 

 

Jill Cohen 

Human Resources Director 

Smith Industrial 

558 Jonson Way 

Dallas, Texas, 59333 

 

 

Dear Hiring Manager, 

I am writing to express my interest in the Product Manager position at [Bank Name], as advertised on [Job Posting Source]. With a strong background in product management and a passion for innovative financial solutions, I believe I am well-suited to contribute to the continued success of [Bank Name]. 

In my previous roles, I have consistently demonstrated my ability to drive product development from conception to market launch. My experience includes [mention specific experiences, such as launching successful banking apps, optimizing digital banking platforms, or developing fintech solutions]. These accomplishments have not only increased customer satisfaction but have also generated revenue growth for my previous employers. 

I am particularly excited about the opportunity to work at [Bank Name] because of your reputation for innovation and commitment to providing top-notch banking services to your customers. Your mission aligns closely with my own professional values, and I am eager to contribute to your efforts in enhancing the customer experience, streamlining processes, and driving digital transformation. 

Additionally, my strong analytical skills, data-driven decision-making, and ability to collaborate effectively with cross-functional teams make me confident in my capacity to meet the unique challenges faced by [Bank Name] and the banking industry as a whole. 

I look forward to the opportunity to discuss how my background and expertise can contribute to the growth and success of [Bank Name]. Please find my resume attached for your reference. I can be reached via email at [Your Email Address] or by phone at [Your Phone Number] to schedule an interview at your convenience. 

Thank you for considering my application. I am excited about the possibility of joining [Bank Name] and being a part of its mission to provide exceptional banking solutions to customers. 

  

Sincerely, 

[Your Name] 
      `,
      signature: "Sincerely, [Your Name]",
    },
  },
  reducers: {
    setCoverLetterData(state, action) {
      state.coverLetterData = action.payload;
    },
  },
});

export const { setCoverLetterData } = coverLetterCreator.actions;
export default coverLetterCreator.reducer;
