import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyFilter } from "../../../features/jobs/jobsSlice";
const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

export default function CompanyHero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const companyList = useSelector(
    (state) => state.jobs_data_source.companyList
  );

  const jobMatchesSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    const filterData = companyList.filter((company) => {
      return (
        company["company_name"]?.toLowerCase()?.includes(searchTerm) ||
        company["company_size"]?.toLowerCase()?.includes(searchTerm) ||
        company["company_type"]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchTerm) ||
        company["founded_year"]?.toLowerCase()?.includes(searchTerm) ||
        company["primary_industry"]?.toLowerCase()?.includes(searchTerm) ||
        company["ceo"]?.toLowerCase()?.includes(searchTerm) ||
        company["about_company"]?.toLowerCase()?.includes(searchTerm)
      );
    });
    dispatch(setCompanyFilter(filterData));
  };

  return (
    <div>
      <div className="flex justify-center mt-20 pt-8">
        <div className="flex flex-col mt-20 mb-20 items-center justify-center text-center">
          <div className="md:mx-20 mx-2 text-black text-3xl md:text-5xl font-bold mb-4 leading-8">
            <p className="mb-4">
              <span className="text-[#153cf5] underline">Explore</span> Product
              Companies
            </p>{" "}
          </div>
          {/* Second Row: Description */}
          <div className="md:mx-20 mx-2 text-black text-lg mt-5">
            <div>
              Search and explore detailed company profiles to learn more about
              the
            </div>
            <div>
              companies you're interested in, including job listings and other
              relevant information.
            </div>
          </div>

          <div className="flex justify-center mt-5">
            <div className="mb-6 relative mx-5 md:mx-20 mt-10 ">
              <div className="absolute left-4 md:left-9 top-1/2 transform -translate-y-1/2">
                <img
                  src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec6eaba458a8beafe960a21_search-icon.svg"
                  alt="Search Icon"
                  className="h-6 w-6"
                />
              </div>
              <input
                type="text"
                className="w-full md:h-12 md:mx-5 h-12 pl-14 md:pl-14 md:pr-20  md:py-9 shadow rounded  border border-white hover:border-[#153cf5] hover:border-1  text-2xl font-semibold  hover:shadow transition-all duration-600 hover:-translate-y-.1 p-0.5"
                placeholder="Search for companies"
                style={{
                  boxShadow:
                    "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
                }}
                onChange={jobMatchesSearch}
              />

              {/* <button
                className="hidden md:block absolute h-7 md:h-12 px-3 -right-2 top-1/2 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-4  font-bold text-lg rounded"
                // style={{
                //   boxShadow:
                //     "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
                // }}
              >
                SEARCH COMPANY
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
