import React from "react";

function Summary({ form }) {
  return (
    <div>
      {form.summary.length > 0 && (
        <div className="mb-1">
          <h2
            className="section-title  border-b-2 border-gray-300"
            style={{ paddingBottom: "10px" }}
            contentEditable
            suppressContentEditableWarning
          >
            Summary
          </h2>
          <span border-b-2 border-gray-300></span>
          <p className="content" style={{ whiteSpace: "pre-line" }}>
            {form.summary}
          </p>
        </div>
      )}
    </div>
  );
}

export default Summary;
