// this componet is used for user input values

import React from "react";
import BasicInfo from "../input_formfields/BasicInfo";
import SocilMedia from "../input_formfields/SocilMedia";
import Summary from "../input_formfields/Summary";
import Education from "../input_formfields/Education";
import WorkExperience from "../input_formfields/WorkExperience";
import Skill from "../input_formfields/Skill";
import Language from "../input_formfields/Language";
import Certification from "../input_formfields/Certification";
import Project from "../input_formfields/Project";
import { Divider } from "../../../../helper/helper";
const MainForm = ({ form, setForm }) => {
  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };
  return (
    <div>
      {Object.keys(form).length && (
        <>
          <BasicInfo form={form} handleChange={handleChange}></BasicInfo>
          <Divider />
          <SocilMedia form={form} setForm={setForm}></SocilMedia>
          <Divider />
          <Summary form={form} handleChange={handleChange}></Summary>
          <Divider />
          <Education form={form} setForm={setForm}></Education>
          <Divider />
          <WorkExperience form={form} setForm={setForm}></WorkExperience>
          <Divider></Divider>
          <Project form={form} setForm={setForm}></Project>
          <Divider></Divider>
          {Object.keys(form).length && form.skills.map((item, index) => (
            <Skill
              length={form.skills.length}
              index={index}
              title={item.title}
              form={form}
              setForm={setForm}
            ></Skill>
          ))}
          <Divider></Divider>
          <Language form={form} setForm={setForm}></Language>
          <Divider />
          <Certification form={form} setForm={setForm}></Certification>
          <Divider />
        </>
      )}
    </div>
  );
};

export default MainForm;
