import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Result } from "antd";
import Card from "./Card";
import { Link } from "react-router-dom";
function CompanyJobs({ companyData }) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://jobs-backend-web-v2.vercel.app/api/v1/getComapnyJobs?company_name=${companyData.company_name}`
        );
        setJobs(response.data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyData]);

  if (loading) {
    return (
      <div className="flex items-center justify-center ">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center ">
        <Result status="404" title="No Jobs Found" />
      </div>
    );
  }

  return (
    <div className="pb-5">
      <div className="flex justify-between pr-2 pb-2 mt-2 mr-2">
        <h1 className="text-2xl font-semibold tracking-tight text-gray-900 text-center sm:text-2xl lg:text-left capitalize ml-2 md:ml-2 md:mb-8">
          {companyData.company_name} job openings

        </h1>
      </div>
      {jobs.length > 0 ? (
        jobs.map((job, index) => <Card key={index} job={job} />)
      ) : (
        <div className="flex items-center justify-center ">
          <Result status="404" title="No Jobs Found" />
        </div>
      )}
    </div>
  );
}

export default CompanyJobs;
