import React from "react";
import { Link } from "react-router-dom";
function FeaturedJob() {
  return (
    <div className="bg-white rounded-lg p-2 shadow-md  hover:shadow-lg  border ">
      <h2 className="text-2xl font-semibold py-3 px-6  md:px-0 md:ml-6 md:py-4">
        Featured Companies
      </h2>
      <Link to="/easycompany/Atlassian/1435">
        <div className="md:flex justify-center items-center p-3 transition-all duration-300 hover:-translate-y-1 px-6  md:px-0 cursor-pointer">
          <img
            src="https://cdn-images.himalayas.app/k0n4ydpkwuo53em3ajcag72t1fnk"
            alt="Logo"
            className="w-16 h-16 mr-4 object-contain rounded-lg shadow flex-shrink-0"
          />
          <div className="">
            {/* Second Row */}
            <h2 className="text-xl font-semibold">Atlassian</h2>
            {/* Third Row */}
            <p className="text-gray-600 text-xl">Enterprise Software.</p>
          </div>
        </div>
      </Link>

      <Link to="/easycompany/Paytm/1429">
        <div className="md:flex justify-center items-center p-3 md:-ml-20 transition-all duration-300 hover:-translate-y-1 px-6  md:px-0 cursor-pointer">
          <img
            src="https://cdn-images.himalayas.app/inqpu23lg3f7uknuv1nndpg0d6s9"
            alt="Logo"
            className="w-16 h-16 mr-4 object-contain rounded-lg shadow flex-shrink-0"
          />
          <div className="">
            {/* Second Row */}
            <h2 className="text-xl font-semibold">Paytm</h2>
            {/* Third Row */}
            <p className="text-gray-600 text-xl">Payments.</p>
          </div>
        </div>
      </Link>
      <Link to="/easycompany/Gitlab/1491">
        <div className="md:flex justify-center items-center p-3 transition-all duration-300 hover:-translate-y-1 px-6  md:px-0 cursor-pointer">
          <img
            src="https://cdn-images.himalayas.app/c62c54n3q202a0wba1b8gta2aq4p"
            alt="Logo"
            className="w-16 h-16 mr-4 object-contain rounded-lg shadow flex-shrink-0"
          />
          <div className="">
            {/* Second Row */}
            <h2 className="text-xl font-semibold">Gitlab</h2>
            {/* Third Row */}
            <p className="text-gray-600 text-xl">Web Development.</p>
          </div>
        </div>
      </Link>
      <Link to='/easycompany/Netflix/1426'>
      <div className="md:flex justify-center items-center p-3 md:-ml-12 transition-all duration-300 hover:-translate-y-1 px-6  md:px-0 cursor-pointer">
        <img
          src="https://cdn-images.himalayas.app/rdgicukp1npc83ictyc31i8xta9o"
          alt="Logo"
          className="w-16 h-16 mr-4 object-contain rounded-lg shadow flex-shrink-0"
        />
        <div className="">
          {/* Second Row */}
          <h2 className="text-xl font-semibold">Netflix</h2>
          {/* Third Row */}
          <p className="text-gray-600 text-xl">Digital Media.</p>
        </div>
      </div>
      </Link>

      {/* Fourth Row */}
    </div>
  );
}

export default FeaturedJob;
