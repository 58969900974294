import React from "react";
import Education from "../../resume_components/Education";
import "./Template2.css";

const DateRange = ({ startYear, endYear }) => {
  const startDate = new Date(startYear);
  const endDate = new Date(endYear);

  // Get year and month names
  const startYearName = startDate.getFullYear();
  const endYearName = endDate.getFullYear();
  const startMonthName = startDate.toLocaleString("default", {
    month: "short",
  });
  const endMonthName = endDate.toLocaleString("default", { month: "short" });

  return (
    <p className="content">
      {`${startMonthName} ${startYearName} - ${endMonthName} ${endYearName}`}
    </p>
  );
};

function Template2({ form }) {
  return (
    <>
      <div className="container mx-auto px-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-bold">{form.name}</p>

          {form.socialMedia.map((socialMedia, index) => (
            <a
              href={`http://${socialMedia.link}`}
              aria-label={socialMedia.socialMedia}
              key={index}
              title={socialMedia.socialMedia}
              target="_blank"
              rel="noreferrer"
              className="mt-2 text-sky-600"
            >
              {socialMedia.socialMedia === "LinkedIn" && (
                <span>( {socialMedia.link} )</span>
              )}
            </a>
          ))}
        </div>

        <div class="border-t border-black mt-4"></div>

        <p className="flex text-sm items-center gap-2 mt-1">
          <span className="list-disc mr-2">{form.email}</span>❖
          <span className="list-disc mr-2">{form.address}</span>❖
          <span>{form.contactInformation}</span>
        </p>
        <div class="border-t border-black mt-4"></div>
        <p className="text-md mt-3">
          <span>SUMMARY</span>
        </p>
        <div class="border-t border-black mt-3"></div>
        <p className="content" style={{ whiteSpace: "pre-line" }}>
          {form.summary}
        </p>

        <p className="text-md mt-3">
          <span>WORK EXPERIENCE </span>
        </p>
        <div class="border-t border-black mt-3"></div>

        {form.workExperience.length > 0 && (
          <div className="mt-4">
            {form.workExperience.map((item, index) => (
              <div key={`${item.company}-${index}`} className="mb-1">
                <div className="flex gap-3">
                  <p className="content i-bold">{item.company} </p>

                  <DateRange
                    startYear={item.startYear}
                    endYear={item.endYear}
                  />
                </div>

                <p className="content">{item.position}</p>

                <p className="content">{item.description}</p>
                <ul
                  className="list-disc ul-padding content"
                  style={{ listStyleType: "disc" }}
                >
                  {typeof item.keyAchievements === "string" &&
                    item.keyAchievements
                      .split("\n")
                      .map((achievement, subIndex) => (
                        <li
                          key={`${item.company}-${index}-${subIndex}`}
                          style={{ listStyle: "none" }}
                        >
                          ✓ {achievement}
                        </li>
                      ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        <p className="text-md mt-6">
          <span>PROJECTS</span>
        </p>
        <div class="border-t border-black mt-3"></div>

        {form.projects.length > 0 && (
          <div>
            {form.projects.map((item, index) => (
              <div key={`${item.name}-${index}`} className="mb-1">
                <p className="content i-bold">{item.title}</p>
                <DateRange startYear={item.startYear} endYear={item.endYear} />
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="content"
                >
                  {item.link}
                </a>
                <p className="content">{item.description}</p>
                <ul className="list-disc ul-padding content">
                  {typeof item.keyAchievements === "string" &&
                    item.keyAchievements
                      .split("\n")
                      .map((achievement, subIndex) => (
                        <li
                          key={`${item.company}-${index}-${subIndex}`}
                          style={{ listStyle: "none" }}
                        >
                          ✓ {achievement}
                        </li>
                      ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        <p className="text-md mt-6">
          <span>EDUCATION</span>
        </p>
        <div class="border-t border-black mt-3"></div>

        {form.education.length > 0 && (
          <div className="mb-1">
            {form.education.map((item, index) => (
              <div key={index} className="mb-1">
                <p className="content i-bold">{item.degree}</p>
                <p className="content">{item.school}</p>
                <DateRange
                  startYear={item.startYear}
                  endYear={item.endYear}
                  id={`education-start-end-date`}
                />
              </div>
            ))}
          </div>
        )}
        {form.certifications.lenght > 0 && (
          <p className="text-md mt-6">
            <span>CERTIFICATION</span>
          </p>
        )}

        <div class="border-t border-black mt-3"></div>
        <ul className="content mt-1">
          {form.certifications.map((certification, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              ✓ {certification}
            </li>
          ))}
        </ul>
        <p className="text-md mt-6">
          <span>SKILLS</span>
        </p>
        <div class="border-t border-black mt-3"></div>
        {form.skills.map((skill, index) => (
          <div key={`SKILLS-${index}`} className="mb-1 mt-2">
            <div className="flex gap-3 items-center">
              <h2 className="content i-bold ">{skill.title}</h2>
              <span>-</span>
              <div className="flex-grow">
                <p className="content mt-1">{skill.skills.join(", ")}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Template2;
