import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin, Pagination } from "antd"; // Import the Spin and Pagination components from Ant Design

import Card from "./Card";

const CardGrid = ({documents,setDocuments,loading}) => {
  
  // Add a state to track loading
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9; // Number of items per page

  

  // Calculate the range of documents to display on the current page
  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;
  const displayedDocuments = documents.slice(startIdx, endIdx);

  // Handle page changes
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "250px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            {displayedDocuments.map((document) => (
              <Card key={document.id} document={document} />
            ))}
          </div>
          <div className="text-center mt-4">
            <Pagination
              current={currentPage}
              total={documents.length}
              pageSize={pageSize}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardGrid;
