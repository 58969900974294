import React from "react";
import CompanySizeImg from "../../../assets/CompanySize.svg";
import FoundedYearImg from "../../../assets/FoundedYear.svg";
import CompanyJobs from "../companyjobs/CompanyJobs";
import CEO from "../../../assets/ceo.svg";
function MobileCompanyCard({ companyData }) {
  const companyDetails = companyData && companyData.about_description_html;
  const companyPrimaryIndustry = companyData && companyData.primary_industry;
  const companyName =
    companyData &&
    companyData.company_name.charAt(0).toUpperCase() +
      companyData.company_name.slice(1);
  const companySize = companyData && companyData.company_size;
  const foundedYear = companyData && companyData.founded_year;
  const companyCeo = companyData && companyData.ceo;
  return (
    <div className="grid grid-cols-1 gap-4  order-1 lg:order-2">
      <section aria-labelledby="section-2-title">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="p-2">
            <div className="flex items-center gap-4 mt-3 ml-2">
              <div className="w-8 h-8 lg:w-12 lg:h-12">
                <img
                  src={companyData.company_logo}
                  className="w-full h-full rounded-full"
                  alt="Company Logo"
                />
              </div>
              <div className="flex-grow">
                <h2 className="text-2xl font-bold">{companyName}</h2>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ml-3 mt-8">
              <div className="text-gray-100 font-medium rounded-md">
                <span className="text-gray-700">Primary Industry</span>
              </div>
              <div className="bg-white font-medium rounded-md">
                <span className="text-black">{companyPrimaryIndustry}</span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ml-3 mt-3">
              <div className="text-gray-100 font-medium rounded-md">
                <span className="text-gray-700">Company Size</span>
              </div>
              <div className="flex bg-white font-medium rounded-md items-center">
                <img
                  src={CompanySizeImg}
                  className="h-4 mr-2"
                  alt="Company Size"
                />
                <span className="text-black">{companySize}</span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ml-3 mt-3">
              <div className="text-gray-100 font-medium rounded-md">
                <span className="text-gray-700">Founded In</span>
              </div>
              <div className="flex bg-white font-medium rounded-md items-center">
                <img
                  src={FoundedYearImg}
                  className="h-4 mr-2"
                  alt="Company Size"
                />
                <span className="text-black">{foundedYear}</span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ml-3 mt-3">
              <div className="text-gray-100 font-medium rounded-md">
                <span className="text-gray-700">Chief executive officer</span>
              </div>
              <div className="flex bg-white font-medium rounded-md items-center">
                <img src={CEO} className="h-4 mr-2" alt="Company Size" />
                <span className="text-black">{companyCeo}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MobileCompanyCard;
