import React from "react";
import { MdEmail, MdLocationOn, MdPhone } from "react-icons/md";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

const icons = [
  { name: "github", icon: <FaGithub /> },
  { name: "linkedin", icon: <FaLinkedin /> },
  { name: "twitter", icon: <FaTwitter /> },
];

const ContactInfo = ({ teldata, emaildata, addressdata }) => (
  <div className="flex flex-row gap-2 mt-2 mb-1 contact justify-center">
    {teldata && (
      <div className="inline-flex items-center gap-1">
        {/* <MdPhone /> */}
        <span>◒ {teldata}</span>
      </div>
    )}
    {emaildata && (
      <div className="inline-flex items-center gap-1">
        {/* <MdEmail /> */}
        <span>◒ {emaildata}</span>
      </div>
    )}
    {addressdata && (
      <div className="inline-flex items-center gap-1">
        {/* <MdLocationOn /> */}
        <span>◒ {addressdata}</span>
      </div>
    )}
  </div>
);

const BasicInfo = ({ form }) => {
  return (
    <div className="flex flex-col items-center mb-1">
      <h1 className="name text-center text-2xl font-bold">{form.name}</h1>
      <p className="profession text-center mt-1">{form.position}</p>
      <ContactInfo
        mainclass="flex flex-row gap-1 mb-1 contact"
        linkclass="inline-flex items-center gap-1"
        teldata={form.contactInformation}
        emaildata={form.email}
        addressdata={form.address}
      />
      <div className="flex gap-3 mt-2 justify-center">
        {form.socialMedia.map((socialMedia, index) => (
          <a
            href={`http://${socialMedia.link}`}
            aria-label={socialMedia.socialMedia}
            key={index}
            title={socialMedia.socialMedia}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center gap-1 social-media"
          >
            <div className="flex items-center gap-1">
              {/* {icons.map((icon, index) => {
                if (icon.name === socialMedia.socialMedia.toLowerCase()) {
                  return (
                    <span key={index} className="flex-shrink-0 mt-4">
                      {icon.icon}
                    </span>
                  );
                }
                return null;
              })} */}
              ❖ {socialMedia.link}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default BasicInfo;
