import React from "react";
import Pricing from "./Pricing";
function MainPricing() {
  return (
    <div>
      <Pricing></Pricing>
    </div>
  );
}

export default MainPricing;
