import { useState, useEffect } from "react";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setJobsCategorys } from "../../../../features/jobs/jobsSlice";
import BasicInfoForm from "./components/BasicInfoForm";

import { message } from "antd";

export default function BasicInfo({ steps, setSteps }) {
  const dispatch = useDispatch();
  const { user } = useClerk();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://user-jobs.onrender.com/api/v1/getJobsCategory`)
      .then((response) => {
        dispatch(setJobsCategorys(response.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const jobsCategorys = useSelector(
    (state) => state.jobs_data_source.jobsCategorys
  );

  const [formFields, setFormFields] = useState({});

  const handleSubmitButton = async () => {
    const mandatoryFields = [
      "name",
      "role",
      "experience",
      "country",
      "state",
      "city",
      "about",
    ];
    const missingFields = mandatoryFields.filter((field) => !formFields[field]);

    if (missingFields.length > 0) {
      const errorMessage = `Please fill the required fields`;
      message.error(errorMessage);
      return;
    }
    try {
      setIsLoadingSave(true);
      const response = await axios.post(
        "https://web-backend-user-profile-v2.onrender.com/api/v1/saveUserBasicInfo",
        {
          username: user.primaryEmailAddress.emailAddress,
          name: formFields.name,
          role: formFields.role,
          experience: formFields.experience,
          country: formFields.country,
          state: formFields.state,
          city: formFields.city,
          about: formFields.about,
          website: formFields.website,
          linkedln: formFields.linkedln,
          twitter: formFields.twitter,
        }
      );

      setIsLoadingSave(false);
      message.success(`${user.username} basic info saved successfully!`);
      const updatedStep = {
        id: "Step 1",
        name: "Basic Info",
        href: "#",
        status: "complete",
      };

      
      
      setSteps((prevSteps) => {
        // Find the index of the step with the same id as the updatedStep
        const index = prevSteps.findIndex((step) => step.id === updatedStep.id);
      
        // If the step with the same id is found, update it; otherwise, add the updatedStep as a new step
        if (index !== -1) {
          return prevSteps.map((step, idx) => (idx === index ? updatedStep : step));
        } else {
          return [...prevSteps, updatedStep];
        }
      });
    } catch (error) {
      setIsLoadingSave(false);
      message.error("Failed to save user basic info.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://web-backend-user-profile-v2.onrender.com/api/v1/users/basicinfo?username=${user.primaryEmailAddress.emailAddress}`
        );
        setFormFields(response.data);
        // console.log("form data", response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="py-10 p-4 bg-white">
      <BasicInfoForm
        jobCategoryLoading={loading}
        jobsCategorys={jobsCategorys}
        setSelectedCountry={setSelectedCountry}
        selectedCountry={selectedCountry}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        setFormFields={setFormFields}
        formFields={formFields}
      ></BasicInfoForm>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmitButton}
        >
          {isLoadingSave ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}
