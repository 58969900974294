import React, { useState } from "react";
import CompanyModal from "../../DashBoard/modal/CompanyModal";
import JobsModal from "../../DashBoard/modal/JobsModal";


function Card({ job }) {
  const [open, setOpen] = useState(false);
  const [jobModalOpen, setJobModalOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleJobsModalOpen = () => {
    setJobModalOpen(true);
  };
  return (
    <>
      {open && (
        <CompanyModal
          open={open}
          setOpen={setOpen}
          companyName={job?.company_name}
        ></CompanyModal>
      )}

      {jobModalOpen && (
        <JobsModal
          jobId={job?.job_id}
          companyName={job?.company_name}
          open={jobModalOpen}
          setOpen={setJobModalOpen}
        />
      )}

      {/* in mobile screen */}
      <div className="mt-5 bg-gray-50 pb-3 md:hidden block">
        <div className="rounded-md  px-6 py-5 sm:flex sm:items-start sm:justify-between">
          <div className="sm:flex sm:items-start">
            <img
              src={job.company_logo}
              className="w-14 h-14 object-contain"
              alt={job.company_name}
            ></img>
            <div className="mt-3 sm:ml-4 sm:mt-0">
              <div className="text-lg font-medium text-gray-900 capitalize">
                {job.company_name}
              </div>
              <div className="mt-1 text-md text-gray-600 sm:flex sm:items-center">
                <div>Full Time</div>
                <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                  &middot;
                </span>
                <div className="mt-1 sm:mt-0">{job.job_location}</div>
                <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                  &middot;
                </span>
                <div className="flex items-center gap-2 capitalize mt-2">
                  <div className="relative h-2 w-2 ">
                    <div className="absolute h-2 w-2 rounded-full bg-green-400 opacity-50 animate-pulse" />
                    <div className="absolute h-2 w-2 rounded-full bg-green-400 animate-ping" />
                  </div>
                  <div className="mb-0.5">active</div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
            <button
              type="button"
              className="rounded-md bg-[#153cf5] px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleJobsModalOpen}
            >
              View
            </button>
          </div>
        </div>
        {/* <div className="mt-1 rounded-md  px-6 sm:flex sm:items-start sm:justify-between text-md text-gray-900 sm:flex sm:items-center">
          <h4>{job.job_summary}</h4>
        </div> */}
      </div>

      {/* in dekstop screen */}
      <div className="border-b md:border-b md:grid gap-6 md:grid-cols-2 lg:grid-cols-4 hover:shadow md:block hidden">
        <div className="text-sm rounded-lg flex px-4 gap-6 py-2 md:py-4 font-semibold items-center">
          {job?.job_category}
        </div>
        <div className="text-sm rounded-lg flex px-4 gap-6 py-2 md:py-4 font-semibold items-center">
          {job?.job_location}
        </div>
        <div className="text-sm rounded-lg flex  items-center px-4 gap-6 py-2 md:py-4 font-semibold">
          <img
            className="inline-block h-10 w-10 rounded-full object-contain border p-1"
            src={job?.company_logo}
            alt=""
          />
          <p
            className="capitalize"
            // onClick={
            //   handleModalOpen}
          >
            {job?.company_name}
          </p>
        </div>
        <div className="text-sm rounded-lg flex px-4 gap-10 py-2 md:py-4 font-semibold items-center">
          <div className="flex justify-center items-center gap-2 capitalize">
            <div className="relative h-2 w-2 ">
              <div className="absolute h-2 w-2 rounded-full bg-green-400 opacity-50 animate-pulse" />
              <div className="absolute h-2 w-2 rounded-full bg-green-400 animate-ping" />
            </div>
            <div className="mb-0.5">active</div>
          </div>
          <div
            className="underline text-blue-500 cursor-pointer"
            onClick={handleJobsModalOpen}
          >
            View Job
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
