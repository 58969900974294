import React from "react";

function QuestionCard({
  question,
  isSelected,
  setSelectedQuestionIndex,
  index,
}) {
  const cardClassName = `w-full my-2 p-4 bg-white hover:bg-gray-100 ${
    isSelected ? "border-l-4 border-blue-500 bg-gray-100" : "border-l-4 "
  }`;

  return (
    <>
      <div
        className={cardClassName}
        onClick={() => {
          setSelectedQuestionIndex(question.Question_ID);
        }}
      >
        <div className="flex items-center gap-2 ">
          {/* <div className="w-2 h-2 bg-blue-500 rounded-full"></div> */}
          <p className="text-gray-800 text-lg font-bold">{question.Company}</p>

          <span className="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-green-500/20 font-semibold capitalize mx-1 my-3 md:my-0 md:mx-0">
            {question.Type}
          </span>
        </div>

        <p className="text-gray-500 font-semibold text-sm line-clamp-1 overflow-hidden whitespace-normal">
          {question.Question}
        </p>
        <p className="text-gray-500 text-xs line-clamp-1 overflow-hidden whitespace-normal">
          {question.solution}
        </p>
      </div>
      <hr class="border-t border-gray-300 " />
    </>
  );
}

export default QuestionCard;
