import React from "react";
import MainPricing from "../component/MainPricing";
import NavBar from "../../navBar/containers/NavBar";
import FreeJobSection from "../component/FreeJobSection";
function MainContainer() {
  return (
    <div>
      <NavBar></NavBar>
      <div className="mt-20 pt-1.5">
        <MainPricing></MainPricing>
        <FreeJobSection></FreeJobSection>
      </div>
    </div>
  );
}

export default MainContainer;
