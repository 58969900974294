import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Spin, Pagination } from "antd";

import Card from "./Card";
import {
  setCompanyList,
  setCompanyFilter,
} from "../../../features/jobs/jobsSlice";

function CompanyCard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const companyList = useSelector(
    (state) => state.jobs_data_source.companyList
  );
  const filteredCompanyList = useSelector(
    (state) => state.jobs_data_source.filteredCompanyList
  );

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://jobs-backend-web-v2.vercel.app/api/v1/getComapnyList")
      .then((response) => {
        dispatch(setCompanyList(response.data));
        dispatch(setCompanyFilter(response.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [dispatch]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const renderCards = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const visibleJobs = filteredCompanyList.slice(startIndex, endIndex);
  
    const rows = [];
    for (let i = 0; i < visibleJobs.length; i += 2) {
      const firstCard = visibleJobs[i];
      const secondCard = visibleJobs[i + 1];
      rows.push(
        <div className="flex flex-wrap " key={i}>
          {firstCard && <Card company={firstCard} />}
          {secondCard && <Card company={secondCard} />}
        </div>
      );
    }
  
    return rows;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      {renderCards()}
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredCompanyList.length}
        onChange={handlePageChange}
        className="flex justify-center items-center mb-10 mt-10"
      />
    </div>
  );
}

export default CompanyCard;
