import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { Select } from "antd";

const { Option } = Select;

const HeroSection = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const user = useUser();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bg-[#fafbff] py-10 md:flex items-center justify-center text-center">
      <div className="mt-10 mx-3 md:mx-10 mx-auto px-4 sm:px-6  lg:max-w-7xl lg:px-8 md:py-10">
        <div className="md:mx-20 text-black text-3xl md:text-5xl font-bold mb-4 leading-8">
          <p className="mb-4">
            Find the job <span className="text-[#153cf5] underline"> Made</span>{" "}
            for you
          </p>
        </div>

        {/* Second Row: Description */}
        <div className="md:mx-20 text-black text-lg mb-6 mt-10">
          <div>Browse, Discover, and Land Your Dream Product Manager Job,</div>
          <div>
            Our AI-powered job matching system learns about your skills and
            preferences to deliver tailored job suggestions.
          </div>
        </div>
        <div className="md:flex gap-3 justify-center md:mx-20 text-black text-lg mb-6">
          <Link to={user.isSignedIn ? "/user/home" : "/sign-up"}>
            <button
              className="w-full md:w-40 mt-6 h-7 md:h-10  md:right-10 md:mt-10 transform -translate-y-1/2 bg-[#153cf5] text-white md:px-8 text-lg rounded"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
            >
              Sign Up
            </button>
          </Link>

          <Link to={user.isSignedIn ? "/user/home" : "/sign-in"}>
            <button
              className="w-full md:w-40 mt-3 h-7 md:h-10  md:right-10 md:mt-10 transform -translate-y-1/2 bg-gray-300  md:px-8 text-lg rounded"
              style={{
                boxShadow:
                  "0 2px 6px 0 rgba(5, 21, 46, 0.12), 0 14px 14px 0 rgba(21, 60, 245, 0.2)",
              }}
            >
              Sign In
            </button>
          </Link>
        </div>

        {/* Fourth Row: Company Logos */}
        <div className="flex items-center justify-center mt-7">
          <div className="md:flex items-center justify-between gap-7">
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec7175d7e0c401a3e668a1d_facebook-logo.svg"
              alt="facebook"
              className="w-30 h-30 object-contain mt-2"
            />
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec7175d68c9b0a57ed94925_google-logo.svg"
              alt="google"
              className="w-30 h-30 object-contain mt-5"
            />
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/601e13bc333df3521cce5b6a_youtube-logo-jobs-webflow-template.svg"
              alt="youtube"
              className="w-30 h-30 object-contain mt-5"
            />
            <img
              src="https://assets.website-files.com/5ec5d86528da2f24250d634c/601e13bc774d5a00bcbb0baf_linkedin-logo-jobs-webflow-template.svg"
              alt="linkedin"
              className="w-30 h-30 object-contain mt-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
